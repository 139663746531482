import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SimpleMapPopup = ({
  className,
  style,
  text,
}) => {
  return (
    <div
      className={classNames('listings-card', className)}
      style={style}
      data-ref="map-popup"
      data-label={text}
    >
      <section className="lc-top">
        <div className="lc-main p-0">
          <h6 className="m-0 text-center"><a href="#" onClick={evt => evt.preventDefault()}>{text}</a></h6>
        </div>
      </section>
    </div>
  );
};

SimpleMapPopup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
};

export default SimpleMapPopup;

import React, { useEffect } from 'react';
import { format as formatDate } from 'date-fns';
import { Controller } from 'react-hook-form';
import { formatOrdinal, nthOfMonth } from 'utils';
import {
  SelectField,
  NumberField,
} from 'components/formFields';
import WeekdaySelector from '../WeekdaySelector';

export const CUSTOM_FREQ_DAILY = 'daily';
export const CUSTOM_FREQ_WEEKLY = 'weekly';
export const CUSTOM_FREQ_MONTHLY = 'monthly';

export const CUSTOM_MONTHLY_TYPE_DATE = 'date';
export const CUSTOM_MONTHLY_TYPE_SET_POS = 'setPos';

export default function useCustomRecurrenceControls (formMethods, prefix, startDate) {
  const { watch, control, setValue } = formMethods;
  const int = watch(`${prefix}.customInterval`);
  const suffix = int === 1 ? '' : 's';
  const freq = watch(`${prefix}.customFrequency`);

  const [n, isLast] = startDate ? nthOfMonth(startDate) : [];

  // const cleanSelectedDays = days => {
  //   const tmpDays = [...days];
  //   if (startDate) tmpDays.push(startDate.getDay());
  //   return [...new Set(tmpDays)].sort();
  // };
  // TODO - parent component needs to handle combining weekday of start date with customWeeklyDays value

  useEffect(() => {
    if (freq !== CUSTOM_FREQ_WEEKLY) setValue(`${prefix}.customWeeklyDays`, []);
  }, [freq]);

  const row1Controls = (
    <>
      <div className="col-3">
        <NumberField
          name={`${prefix}.customInterval`}
          label="Every"
          required
          min={1}
          formMethods={formMethods}
        />
      </div>
      <div className="col-3">
        <SelectField
          name={`${prefix}.customFrequency`}
          key={suffix}
          choices={[
            [CUSTOM_FREQ_DAILY, `Day${suffix}`],
            [CUSTOM_FREQ_WEEKLY, `Week${suffix}`],
            [CUSTOM_FREQ_MONTHLY, `Month${suffix}`],
          ]}
          required
          formMethods={formMethods}
        />
      </div>
    </>
  );

  let row2Controls;
  if (freq === CUSTOM_FREQ_WEEKLY) {
    row2Controls = (
      <div className="col-5 d-flex" style={{ height: 56 }}>
        <Controller
          name={`${prefix}.customWeeklyDays`}
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => {
            const startDateDay = startDate && startDate.getDay();
            const lockedDays = startDate ? [startDateDay] : [];
            return <WeekdaySelector selectedDays={value} lockedDays={lockedDays} onChange={onChange} />;
          }}
        />
      </div>
    );
  } else if (freq === CUSTOM_FREQ_MONTHLY && startDate) {
    row2Controls = (
      <div className="col-5">
        <SelectField
          name={`${prefix}.customMonthlyType`}
          label="On the"
          choices={[
            [CUSTOM_MONTHLY_TYPE_DATE, formatDate(startDate, 'do', new Date())],
            [CUSTOM_MONTHLY_TYPE_SET_POS, `${isLast ? 'last' : formatOrdinal(n)} ${formatDate(startDate, 'EEEE', new Date())}`],
          ]}
          required
          formMethods={formMethods}
        />
      </div>
    );
  }

  return { row1Controls, row2Controls };
}

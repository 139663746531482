import React, { useState } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import { useUserContext } from 'context';
import LabeledInput from 'components/LabeledInput';
import LoadingBubbles from 'components/LoadingBubbles';

const LoginForm = ({ onSuccess = () => {} }) => {
  const { fetchUserContext } = useUserContext();

  const [errors, setErrors] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const handleFormSubmit = evt => {
    evt.preventDefault();
    const data = new FormData(evt.target);

    setIsFetching(true);
    setErrors({});
    fetch(urls.loginAjax, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
      body: data,
    })
      .then(response => {
        if (!response.ok && response.status !== 400) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setIsFetching(false);
        if (data.key) {
          fetchUserContext();
          onSuccess();
        } else {
          setErrors(camelize(data));
        }
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  // flatten errors into single array
  const errorsArr = Object.entries(errors).reduce((result, [key, val]) => {
    const msgs = val.map(msg => key === 'nonFieldErrors' ? msg : `${key}: ${msg}`);
    return [...result, ...msgs];
  }, []);
  const errorContainerStyles = {
    minHeight: 60,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <LabeledInput
        type="text"
        name="username"
        placeholder="Username or email"
        containerClassName="mb-4"
      />

      <LabeledInput
        type="password"
        name="password"
        placeholder="Password"
        containerClassName="mb-3"
        maskToggle
      />

      <div className="mb-2" style={errorContainerStyles}>
        {errorsArr.map((msg, idx) => (
          <div key={idx} className="small text-danger text-center">{msg}</div>
        ))}
      </div>

      <div className="mb-3">
        <button type="submit" className="btn-z primary lg w-100 justify-content-center">
          {isFetching ? <LoadingBubbles /> : 'Log in'}
        </button>
      </div>

      <div className="small text-center mb-5">
        <a href={urls.passwordReset} className="text-decoration-none">Forgot password?</a>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default LoginForm;

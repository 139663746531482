import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const ICON_TYPE_RADIO = 'radio';
const ICON_TYPE_CHECK = 'check';

const RadioList = ({ className, listId, iconType = ICON_TYPE_RADIO, options, value, onChange }) => (
  <ul className={`radio-list ${className || ''}`}>
    {options.map(([optVal, optLabel]) => {
      const isSelected = optVal === value;
      const handleClick = () => onChange(optVal);
      const classes = classNames('radio-list-item', isSelected && 'selected');
      let icon;
      if (iconType === ICON_TYPE_CHECK) {
        icon = isSelected ? <Icon i={['far', 'check']} /> : <i />;
      } else {
        icon = <Icon i={['faz', isSelected ? 'dot-circle' : 'circle']} style={{ fontSize: 16 }} />;
      }

      return (
        <li
          key={optVal}
          className={classes}
          onClick={handleClick}
          data-list-id={listId}
          data-option-value={optVal}
          data-option-label={optLabel}
        >
          {icon} <span>{optLabel}</span>
        </li>
      );
    })}
  </ul>
);

RadioList.propTypes = {
  className: PropTypes.string,
  listId: PropTypes.string,
  iconType: PropTypes.oneOf([ICON_TYPE_RADIO, ICON_TYPE_CHECK]),
  // each item in the options array is a 2-item array containing [value, label]
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
};

export default RadioList;

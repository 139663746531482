import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CircleButton = ({ className, style, onClick, children }) => (
  <div
    className={classNames('btn-circle', !onClick && 'disabled', className)}
    style={style}
    onClick={onClick || (() => {})}
  >
    {children}
  </div>
);

CircleButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default CircleButton;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  QUERY_PARAM_FMT,
  DISPLAY_FMT,
  DATE_RANGE_CHOICES,
  CHART_METRIC_CHOICES,
  CHART_UNIT_CHOICES,
} from './constants';

const Controls = ({
  dateRange,
  chartMetric,
  chartUnit,
  customRangeStart,
  customRangeEnd,
  startDate,
  endDate,
  onDateRangeChange = () => {},
  onCustomRangeStartChange = () => {},
  onCustomRangeEndChange = () => {},
  onMetricChange = () => {},
  onUnitChange = () => {},
}) => {
  const { lastSeven, lastThirty, lastNinety, lastYear, allTime, customRange } = DATE_RANGE_CHOICES;
  const { widgetImpressions, eventImpressions } = CHART_METRIC_CHOICES;
  const { day, month } = CHART_UNIT_CHOICES;
  const dateRangeText = startDate && endDate ? `${format(startDate, DISPLAY_FMT)} – ${format(endDate, DISPLAY_FMT)}` : '';

  return (
    <header className="controls-container d-xl-flex flex-wrap justify-content-between align-items-center mb-3">
      <div className="date-controls d-flex flex-wrap align-items-center">
        <select
          name="date-range"
          className="form-select form-select-sm me-2 mb-2 mb-xl-0"
          value={dateRange}
          onChange={evt => onDateRangeChange(evt.target.value)}
        >
          <option value={lastSeven}>Last 7 Days</option>
          <option value={lastThirty}>Last 30 Days</option>
          <option value={lastNinety}>Last 90 Days</option>
          <option value={lastYear}>Last Year</option>
          <option value={allTime}>All Time</option>
          <option value={customRange}>Custom Dates</option>
        </select>

        <div className="d-flex align-items-center mb-2 mb-xl-0">
          {dateRange === customRange ? (
            <>
              <input
                name="customRangeStart"
                value={customRangeStart ? format(customRangeStart, QUERY_PARAM_FMT) : ''}
                type="date"
                className="form-control form-control-sm"
                placeholder="YYYY-MM-DD"
                onChange={evt => onCustomRangeStartChange(evt.target.value)}
              />
              <span className="mx-1">–</span>
              <input
                name="customRangeEnd"
                value={customRangeEnd ? format(customRangeEnd, QUERY_PARAM_FMT) : ''}
                type="date"
                className="form-control form-control-sm"
                placeholder="YYYY-MM-DD"
                onChange={evt => onCustomRangeEndChange(evt.target.value)}
              />
            </>
          ) : (
            <div className="text-muted">{dateRangeText}</div>
          )}
        </div>
      </div>
      <div className="chart-controls d-flex flex-wrap align-items-center justify-content-xl-end">
        <div className="d-flex align-items-center mb-2 mb-md-0">
          <small className="me-2">Show:</small>
          <ul className="chart-metric pagination pagination-sm mb-0 me-3">
            <li className={classNames('page-item', chartMetric === widgetImpressions && 'active')}>
              <a
                className="page-link"
                href="#"
                onClick={evt => { evt.preventDefault(); onMetricChange(widgetImpressions); }}
              >
                Widget Impr.
              </a>
            </li>
            <li className={classNames('page-item', chartMetric === eventImpressions && 'active')}>
              <a
                className="page-link"
                href="#"
                onClick={evt => { evt.preventDefault(); onMetricChange(eventImpressions); }}
              >
                Event Impr.
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-center mb-2 mb-md-0">
          <small className="me-2">By:</small>
          <ul className="chart-unit pagination pagination-sm mb-0">
            <li className={classNames('page-item', chartUnit === day && 'active')}>
              <a className="page-link" href="#" onClick={evt => { evt.preventDefault(); onUnitChange(day); }}>Day</a>
            </li>
            <li className={classNames('page-item', chartUnit === month && 'active')}>
              <a className="page-link" href="#" onClick={evt => { evt.preventDefault(); onUnitChange(month); }}>Month</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

Controls.propTypes = {
  dateRange: PropTypes.oneOf(Object.values(DATE_RANGE_CHOICES)),
  chartMetric: PropTypes.oneOf(Object.values(CHART_METRIC_CHOICES)),
  chartUnit: PropTypes.oneOf(Object.values(CHART_UNIT_CHOICES)),
  customRangeStart: PropTypes.instanceOf(Date),
  customRangeEnd: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onDateRangeChange: PropTypes.func,
  onCustomRangeStartChange: PropTypes.func,
  onCustomRangeEndChange: PropTypes.func,
  onMetricChange: PropTypes.func,
  onUnitChange: PropTypes.func,
};

export default Controls;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defaultLocations, urls } from 'app-constants';
import colors from 'themeColors';
import { useHistory, usePrevious } from 'hooks';
import { useFilterContext, useUserContext } from 'context';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import RadioList from 'components/RadioList';
import AuthRequiredLink from 'components/AuthRequiredLink';

const defaultLocationsSorted = [...defaultLocations].sort((a, b) => a.name.localeCompare(b.name));
const defaultLocationsById = defaultLocationsSorted.reduce((result, loc) => ({ ...result, [loc.id]: loc }), {});
const defaultLocationOptions = defaultLocationsSorted.map(({ id, name }) => [id, name]);

const LocationSelect = ({ className, maxHeight, buttonStyle = { width: 250 } }) => {
  const [locationsById, setLocationsById] = useState({
    current: {
      id: 'current',
      name: 'Current Location',
    },
    ...defaultLocationsById,
  });

  // User addresss book locations
  const { addresses: userLocations = [], isFetching: userDataFetching } = useUserContext();
  const userLocationsById = userLocations.reduce((result, loc) => ({ ...result, [loc.id]: loc }), {});
  const userLocationOptions = userLocations.map(({ id, name }) => [id, name]);

  useEffect(() => {
    setLocationsById(oldState => ({ ...oldState, ...userLocationsById }));
  }, [JSON.stringify(userLocationsById)]);

  const [geoLocError, setGeoLocError] = useState(false);

  const { location: { queryParams }, updateQueryParams } = useHistory();

  const prevParams = usePrevious(queryParams);
  const prevLocationsById = usePrevious(locationsById);
  useEffect(() => {
    if (queryParams && (!prevParams || (JSON.stringify(locationsById) !== JSON.stringify(prevLocationsById)))) {
      const hasLocParams = ['address_id', 'latitude', 'longitude'].every(val => Object.keys(queryParams).includes(val));
      const savedLocId = window.localStorage.getItem('zeit.searchLocation');
      if (!hasLocParams) {
        updateLocParams(savedLocId || defaultLocations[0].id);
      }
    }
  }, [queryParams, JSON.stringify(locationsById)]);

  const handleLocChange = id => {
    window.localStorage.setItem('zeit.searchLocation', id);
    updateLocParams(id);
  };

  const handleSetCurrentLoc = () => handleLocChange('current');

  const updateLocParams = id => {
    const loc = locationsById[id];
    if (loc) {
      updateQueryParams({
        address_id: id,
        latitude: loc.lat,
        longitude: loc.lon,
      }, { replace: true });
    }
  };

  const doGeoLoc = () => navigator.geolocation.getCurrentPosition(handleGeoLoc, handleGeoLocError, {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 1000 * 60 * 15, // 15 minutes
  });

  const handleGeoLoc = evt => {
    setLocationsById(oldState => ({
      ...oldState,
      current: {
        id: 'current',
        name: 'Current Location',
        lat: evt.coords.latitude,
        lon: evt.coords.longitude,
      },
    }));
  };

  const handleGeoLocError = err => {
    console.error(err);
    setGeoLocError(true);
  };

  const aid = (queryParams || {}).address_id;
  useEffect(() => {
    if (aid === 'current' && !locationsById.current.lat) doGeoLoc();
  }, [aid, locationsById.current]);

  useEffect(() => {
    if (locationsById.current.lat && locationsById.current.lon) {
      updateQueryParams({
        address_id: 'current',
        latitude: locationsById.current.lat,
        longitude: locationsById.current.lon,
      }, { replace: true });
    }
  }, [locationsById.current.lat, locationsById.current.lon]);

  let activeValue;
  if (queryParams) {
    if (locationsById.hasOwnProperty(queryParams.address_id)) {
      activeValue = queryParams.address_id;
    } else if (queryParams.address_id && userDataFetching) {
      activeValue = null;
    }
  }

  const activeOptionLabel = activeValue && locationsById[activeValue].name;

  const { updateFilterContext } = useFilterContext();
  useEffect(() => {
    updateFilterContext({ locationName: activeOptionLabel, locationId: activeValue });
  }, [activeOptionLabel]);

  // clear error message when selected option changes
  useEffect(() => setGeoLocError(false), [activeValue]);

  if (!queryParams) return null;

  return (
    <DropdownButton
      buttonClassName={classNames(className, 'subtle-focus', 'location-button', geoLocError && 'border-danger')}
      buttonStyle={buttonStyle}
      prependIcon="map-marker-alt"
      maxHeight={maxHeight}
      text={activeOptionLabel}
      closeOnChanged={activeValue}
    >
      <div className="px-5 pb-4" style={{ width: 350, color: colors.textPrimary }}>
        <div className="sticky-radio-list-item pt-2" style={{ backgroundColor: 'white' }}>
          <div
            className={classNames('radio-list-item', activeValue === 'current' && 'selected')}
            onClick={handleSetCurrentLoc}
            data-list-id="location-select"
            data-option-value="current"
            data-option-label="Use my current location"
          >
            <Icon i={activeValue === 'current' ? ['far', 'check'] : 'location'} style={{ color: colors.primary }} />
            <span>Use my current location</span>
          </div>
          {geoLocError && (
            <div className="alert alert-danger mb-3" role="alert">
              Error retrieving location. Make sure you have enabled location services in your browser’s settings.
            </div>
          )}
          <hr className="my-2" />
        </div>

        <div className="d-flex mb-2 justify-content-between">
          <strong>My Address Book</strong>
          <AuthRequiredLink url={urls.addAddress} style={{ textDecoration: 'none', fontWeight: 'bold' }} text="+&thinsp;Add" />
        </div>

        {userLocationOptions.length ? (
          <RadioList
            listId="user-location-select"
            iconType="check"
            options={userLocationOptions}
            value={activeValue}
            onChange={handleLocChange}
          />
        ) : (!userDataFetching && (
          <div><em>No saved addresses.</em></div>
        ))}

        <hr className="my-2" />

        <div className="fw-bold mb-2">Other Locations</div>
        <RadioList
          listId="location-select"
          iconType="check"
          options={defaultLocationOptions}
          value={activeValue}
          onChange={handleLocChange}
        />
      </div>
    </DropdownButton>
  );
};

LocationSelect.propTypes = {
  className: PropTypes.string,
  maxHeight: PropTypes.number,
  buttonStyle: PropTypes.object,
};

export default LocationSelect;

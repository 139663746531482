import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ClearableTag = ({ className, label, onRemove = () => {} }) => {
  return (
    <div className={classNames('clearable-tag', className)}>
      <div className="text">{label}</div>
      <div className="close" onClick={onRemove}>&times;</div>
    </div>
  );
};

ClearableTag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onRemove: PropTypes.func,
};

export default ClearableTag;

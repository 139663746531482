import { useEffect, useRef } from 'react';

export default function useDisableScroll (isDisabled, selector = 'body') {
  const el = document.querySelector(selector);
  const origOverflowVal = useRef(el.style.overflow);

  useEffect(() => {
    el.style.overflow = isDisabled ? 'hidden' : origOverflowVal.current;
  }, [isDisabled]);
}

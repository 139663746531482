import React from 'react';
import PropTypes from 'prop-types';
import { AppleIcon, GoogleIcon, TwitterIcon, FacebookIcon } from './serviceIcons';
import SocialLoginButton from './SocialLoginButton';

const AppleButton = ({ text = 'Continue with Apple', ...rest }) => (
  <SocialLoginButton
    icon={<AppleIcon size={32} />}
    provider="apple"
    {...rest}
  >
    {text}
  </SocialLoginButton>
);

AppleButton.propTypes = {
  text: PropTypes.string,
  ...SocialLoginButton.propTypes,
};

const GoogleButton = ({ text = 'Continue with Google', ...rest }) => (
  <SocialLoginButton
    icon={<GoogleIcon size={32} />}
    provider="google"
    {...rest}
  >
    {text}
  </SocialLoginButton>
);

GoogleButton.propTypes = {
  text: PropTypes.string,
  ...SocialLoginButton.propTypes,
};

const TwitterButton = ({ text = 'Continue with Twitter', ...rest }) => (
  <SocialLoginButton
    icon={<TwitterIcon size={32} />}
    provider="twitter"
    {...rest}
  >
    {text}
  </SocialLoginButton>
);

TwitterButton.propTypes = {
  text: PropTypes.string,
  ...SocialLoginButton.propTypes,
};

const FacebookButton = ({ text = 'Continue with Facebook', ...rest }) => (
  <SocialLoginButton
    icon={<FacebookIcon size={32} />}
    provider="facebook"
    {...rest}
  >
    {text}
  </SocialLoginButton>
);

FacebookButton.propTypes = {
  text: PropTypes.string,
  ...SocialLoginButton.propTypes,
};

export { AppleButton, GoogleButton, TwitterButton, FacebookButton };

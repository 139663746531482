import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useToggle } from 'hooks';
import Icon from 'components/Icon';

const LabeledInput = ({
  labelText,
  containerClassName,
  hasError = false,
  containerStyle = {},
  maskToggle = false,
  ...rest
}) => {
  labelText = labelText || rest.placeholder;
  if (rest.type !== 'password') maskToggle = false;

  const [labelVisible, setLabelVisible] = useState(!!rest.value);

  const handleChange = evt => {
    rest.onChange && rest.onChange(evt);

    if (evt.target.value && !labelVisible) {
      setLabelVisible(true);
    } else if (!evt.target.value && labelVisible) {
      setLabelVisible(false);
    }
  };

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const [reveal, toggleReveal] = useToggle(false);
  const handleRevealClick = evt => {
    evt.preventDefault();
    // Prevent taking away input focus
    evt.stopPropagation();
    toggleReveal();
  };

  let inputType = rest.type || 'text';
  if (rest.type === 'password' && reveal) {
    inputType = 'text';
  }

  return (
    <div className={classNames(containerClassName, hasError && 'error')} style={{ position: 'relative', ...containerStyle }}>
      <input
        className="z-input lg outline d-block w-100"
        {...rest}
        type={inputType}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {labelVisible && (
        <label
          className={classNames('outline-label', isFocused && 'focused')}
          htmlFor={rest.name}
        >
          {labelText}
        </label>
      )}

      {maskToggle && (
        <a
          href="#toggle visibility"
          onPointerDown={handleRevealClick}
          className="text-secondary"
          style={{
            position: 'absolute',
            height: '100%',
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: 0,
            right: 15,
          }}
        >
          <Icon
            key={reveal}
            i={reveal ? 'eye-slash' : 'eye'}
          />
        </a>
      )}
    </div>
  );
};

LabeledInput.propTypes = {
  labelText: PropTypes.string,
  containerClassName: PropTypes.string,
  hasError: PropTypes.bool,
  containerStyle: PropTypes.object,
  maskToggle: PropTypes.bool, // For password inputs, display a show/hide toggle button
};

export default LabeledInput;

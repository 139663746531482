import { useRef, useLayoutEffect } from 'react';

export default function useScrollPosition (onScroll, deps = [], wait = 200) {
  const throttleTimeout = useRef(null);

  const cb = () => {
    onScroll({ x: window.scrollX, y: window.scrollY });
    throttleTimeout.current = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout.current === null) {
        throttleTimeout.current = setTimeout(cb, wait);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { useBootstrapBreakpoint, useGetAPI, useHistory } from 'hooks';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import LoadingOverlay from 'components/LoadingOverlay';
import EditorEventCard from 'components/EditorEventCard';
import SavedItemsEmptyMessage from 'components/SavedItemsEmptyMessage';

const ORDER_CREATED = '-created';
const ORDER_DATE = 'date';
const DEFAULT_ORDER = ORDER_CREATED;

const EditorEventList = ({ emptyImgUrl }) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();
  const { ordering = DEFAULT_ORDER } = queryParams || {};

  // Call API
  let path;
  if (typeof queryParams !== 'undefined') path = urls.apiEditorEventList;
  const apiParams = { ordering };
  const { data: eventData, isFetching, error } = useGetAPI(path, { queryObject: apiParams, fullPath: true });

  // Data cached in local state so we can update the UI immediately when an item is deleted.
  const [cachedEventData, setCachedEventData] = useState();
  useEffect(() => setCachedEventData(eventData), [eventData]);

  const orderingMenuRef = useRef();
  const handleOrderChange = (evt, ordering) => {
    evt.preventDefault();
    updateQueryParams({ ordering });
    orderingMenuRef.current.close();
  };

  // Reset scroll position when fetching new data
  useEffect(() => window.scrollTo(0, 0), [isFetching]);

  const isMobile = useBootstrapBreakpoint('down-md');

  const countLabel = !cachedEventData ? 'My' : `${cachedEventData.length} Upcoming`;

  const orderingButtonLabel = isMobile ? null : (
    <>
      <strong className="text-light-alt">Sort by: </strong>
      <strong>
        {ordering === ORDER_CREATED && 'Last added'}
        {ordering === ORDER_DATE && 'Date'}
      </strong>
    </>
  );

  return (
    <div className="listings-container">
      <LoadingOverlay show={isFetching} align="top" className="bg-lt" />
      {cachedEventData && cachedEventData.length === 0 ? (
        <SavedItemsEmptyMessage
          imgUrl={emptyImgUrl}
          primaryText="Broaden your reach by showcasing your venue’s events on Zeitcaster!"
          secondaryText="No upcoming events."
          buttonText="Add event"
          buttonUrl={urls.addEvent}
        />
      ) : (
        <>
          <header>
            {!isMobile && <h2 className="lh-base m-0 fw-black">{countLabel} Event{(cachedEventData || []).length !== 1 && 's'}</h2>}
            <div className={classNames('d-flex', 'mb-1', 'align-items-baseline', isMobile ? 'justify-content-between' : 'justify-content-end')}>
              {isMobile && (
                <h4 className="lh-base m-0 fw-black">
                  {countLabel} Event{countLabel !== 1 && 's'}
                </h4>
              )}
              <DropdownButton
                ref={orderingMenuRef}
                text={orderingButtonLabel}
                appendIcon={isMobile ? <Icon i={['faz', 'sort']} /> : undefined}
                buttonClassName={classNames('clear', 'subtle-focus', 'borderless', isMobile && 'circle')}
                menuAnchor="right"
              >
                <div className="link-list py-3" style={{ width: 160 }}>
                  <a
                    href="#added"
                    className={classNames('link-list-item', 'px-3', ordering === ORDER_CREATED && 'color-primary')}
                    onClick={evt => handleOrderChange(evt, ORDER_CREATED)}
                  >
                    <Icon i={['far', 'check']} style={{ visibility: ordering === ORDER_CREATED ? 'visible' : 'hidden' }} />
                    <span>Last added</span>
                  </a>

                  <a
                    href="#date"
                    className={classNames('link-list-item', 'px-3', ordering === ORDER_DATE && 'color-primary')}
                    onClick={evt => handleOrderChange(evt, ORDER_DATE)}
                  >
                    <Icon i={['far', 'check']} style={{ visibility: ordering === ORDER_DATE ? 'visible' : 'hidden' }} />
                    <span>Date</span>
                  </a>
                </div>
              </DropdownButton>
            </div>

            {!!error && (
              <div className="alert alert-danger mb-3" role="alert">
                Failed to fetch results. Please try your request again.
              </div>
            )}
          </header>

          {(cachedEventData || []).map(event => (
            <div key={event.id} className="mb-3">
              <EditorEventCard eventData={event} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

EditorEventList.propTypes = {
  emptyImgUrl: PropTypes.string,
};

export default EditorEventList;

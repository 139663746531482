import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { urls } from 'app-constants';
// import { useUserContext } from 'context';
import { getCsrfToken } from 'utils';
// import { DISTANCE_UNITS_LABELS } from 'components/filters';
import TruncateText from 'components/TruncateText';
import CardDeletionMenu from 'components/CardDeletionMenu';
import Icon from 'components/Icon';

const AddressBookCard = ({
  className,
  useCompactStyle,
  id,
  created,
  updated,
  metaLabel = 'updated',
  onDeleteRequest = () => {},
  onDeleteSuccess = () => {},
  onDeleteFailure = () => {},
  addressName,
  lat,
  lon,
  city,
  street,
  state,
  shortAddress,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  // const { distanceUnits } = useUserContext();
  // const distanceUnitsLabel = DISTANCE_UNITS_LABELS[distanceUnits];

  const editUrl = `${urls.editAddressBase}${id}/`;
  const searchUrl = `${urls.events}?address_id=${id}&latitude=${lat}&longitude=${lon}`;
  const handleCardClick = () => window.location.href = searchUrl;
  const handleEditClick = () => window.location.href = editUrl;
  const handleTitleClick = evt => evt.stopPropagation(); // Don't break cmd+click

  const dateCreated = parseISO(created);
  const dateUpdated = parseISO(updated);

  const handleDeleteClick = () => {
    setIsDeleting(true);

    fetch(`${urls.savedAddressBase}${id}/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'X-CSRFToken': getCsrfToken() },
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        onDeleteSuccess();
      })
      .catch(err => {
        console.error(err);
        setIsDeleting(false);
        onDeleteFailure(err);
      });
  };

  return (
    <div
      className={classNames('listings-card', 'saved-search-card', className)}
      style={{ opacity: isDeleting ? 0.5 : 1 }}
      onClick={handleCardClick}
    >
      <section className="lc-top">
        <div className={classNames('lc-main p-0')}>
          <div>
            <div className="d-flex align-items-start">
              <div className="d-flex align-items-center mb-2">
                <h5 className="saved-search-card-title m-0">
                  <a href={searchUrl} onClick={handleTitleClick}>
                    <TruncateText text={addressName} numChars={24} />
                  </a>
                </h5>
              </div>
            </div>
            <div className="mb-2 lh-sm d-flex align-items-center">
              <div className="me-1 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                <Icon i="map-marker-alt" size="sm" />
              </div>
              {shortAddress}
            </div>
          </div>
        </div>

        <div className="lc-right">
          <div onClick={e => e.stopPropagation()}>
            <CardDeletionMenu
              onDeleteClick={handleDeleteClick}
              extraItems={[
                { label: 'View', onClick: handleCardClick },
                { label: 'Edit', onClick: handleEditClick },
              ]}
              deleteLinkAttrs={{
                'data-address-name': `${id} - ${addressName}`,
                'data-action': 'delete',
              }}
            />
          </div>
        </div>
      </section>

      {!useCompactStyle && (
        <div className="saved-search-card-meta">
          {metaLabel === 'created' && `Created ${formatDistanceToNow(dateCreated)} ago`}
          {metaLabel === 'updated' && `Edited  ${formatDistanceToNow(dateUpdated)} ago`}
        </div>
      )}
    </div>
  );
};

AddressBookCard.propTypes = {
  className: PropTypes.string,
  useCompactStyle: PropTypes.bool,
  id: PropTypes.number,
  addressName: PropTypes.string,
  lat: PropTypes.number,
  lon: PropTypes.number,
  streetNumber: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  shortAddress: PropTypes.string,
  created: PropTypes.string,
  updated: PropTypes.string,
  metaLabel: PropTypes.oneOf(['updated', 'created']),
  onDeleteRequest: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default AddressBookCard;

import { useState, useEffect } from 'react';
import queryString from 'query-string';
import history from 'historyInit';

export default function useHistory () {
  const [location, setLocation] = useState({});

  const handleHistoryChange = location => {
    setLocation({
      ...location,
      queryParams: queryString.parse(location.search, {
        arrayFormat: 'bracket',
        parseNumbers: true,
        parseBooleans: true,
      }),
    });
  };

  const updateQueryParams = (newParams, { clear = false, replace = false } = {}) => {
    // "clear" removes any existing params and uses only the passed newParams
    const currentParams = clear ? {} : (location.queryParams || {});

    // If a null value is passed for a param, remove it
    const params = Object.entries({ ...currentParams, ...newParams }).reduce((result, [key, val]) => {
      if (newParams[key] !== null) {
        result[key] = val;
      }
      return result;
    }, {});

    const query = queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipNull: true,
      skipEmptyString: true,
    });
    const url = `${location.pathname || ''}?${query}`;
    replace ? history.replace(url) : history.push(url);
  };

  useEffect(() => {
    // Handle initial page load
    handleHistoryChange(history.location);
    // Listen for further events
    const unlisten = history.listen(({ location, action }) => handleHistoryChange(location));
    return unlisten;
  }, []);

  return { history, location, updateQueryParams };
}

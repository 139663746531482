import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  AddressField,
  ButtonChoiceField,
  CheckboxField,
  CheckboxMultiField,
  DateField,
  EventDatesField,
  LiveSearchSelectField,
  NumberField,
  OpenTimesField,
  RadioField,
  RichTextField,
  SelectField,
  TextField,
  TimeField,
  URLField,
} from 'components/formFields';
import HelpPopup from 'components/HelpPopup';

const FormComponentDemo = ({ mapboxToken }) => { // eslint-disable-line
  const [formResult, setFormResult] = useState(null);

  const defaultValues = {
    name: '',
    url: '',
    price_information: '',
    event_description: '',
    type: '',
    use_poi_times: false,
    price: '',
    help: '',
    number: null,
    event_type: null,
    start_date: null,
    start_time: '',
    event_dates: [],
    open_times: [],
    poi: '',
    age_group: [],
    event_venue_type: '',
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
    lat: '',
    lon: '',
  };

  const dummyValues = {
    name: 'Laborum pariatur laboris',
    url: 'https://example.com',
    price_information: 'Ut officia pariatur esse sit nulla irure dolore commodo sunt aliqua amet in in ut pariatur.',
    event_description: '<p>Do dolor nisi dolore laboris non non laborum minim eiusmod in anim id aute ullamco elit et. Esse proident elit aliquip ut aliqua sit sint exercitation quis ea. Irure dolor labore nostrud occaecat et eiusmod dolore est reprehenderit id reprehenderit consectetur deserunt ad. Labore aliquip incididunt dolor pariatur veniam nulla minim ex commodo irure amet commodo esse ut reprehenderit aliquip in.</p><p>Lorem ipsum consequat eiusmod occaecat sit magna esse dolore elit in. Lorem ipsum consequat est occaecat incididunt magna pariatur mollit dolor veniam voluptate.</p>',
    type: 'in_person',
    use_poi_times: false,
    price: 'low',
    number: 3,
    event_type: 45,
    start_date: '03/15/2023',
    start_time: '12:05 PM',
    poi: 543,
    age_group: ['baby', 'toddler'],
    event_venue_type: 'in_person',
  };

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues,
  });
  const onSubmit = data => {
    console.log(data);  // eslint-disable-line
    setFormResult(Object.entries(data));
    formMethods.reset(defaultValues);
  };

  const multiCheckboxHorizontal = formMethods.watch('multi_checkbox_horizontal');
  const radioHorizontal = formMethods.watch('radio_horizontal');

  const handleResetClick = () => {
    setFormResult(null);
    formMethods.reset(defaultValues);
  };

  const handleFillClick = () => formMethods.reset(dummyValues, { keepDefaultValues: true });

  // Custom validation example: callback should return true if valid, error message string if not.
  const validateEventName = value => value !== 'forbidden name' || 'Invalid event name.';

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="row mb-4">
          <div className="col-6">
            <h6>Text Field</h6>
            <TextField
              name="name"
              label="Event Name"
              required
              maxLength={50}
              helpText="Enter some text here. Not too long though!"
              validate={validateEventName}
            />
          </div>
          <div className="col-6">
            <h6>URL Field</h6>
            <URLField
              name="url"
              label="Event URL"
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <h6>Multiline Text Field</h6>
            <TextField
              name="price_information"
              label="Additional pricing information"
              multiline
              maxLength={500}
            />
          </div>

          <div className="col">
            <h6>Rich Text Field</h6>
            <RichTextField
              name="event_description"
              label="Event description"
              required
              maxLength={2000}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <h6>Button Choice Field</h6>
            <ButtonChoiceField
              name="type"
              choices={[
                ['in_person', 'In Person'],
                ['online', 'Online'],
                ['hybrid', 'Hybrid'],
              ]}
              required
            />
          </div>

          <div className="col">
            <h6>Checkbox Field</h6>
            <CheckboxField
              name="use_poi_times"
              label="Same as venue’s hours of operation"
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-2">
            <h6>Number Field</h6>
            <NumberField
              name="number"
              label="Number"
              required
              min={1}
              max={10}
            />
          </div>

          <div className="col offset-4">
            <h6>Help Button</h6>
            <div className="d-flex align-items-center">
              <div className="me-2">
                Standalone
              </div>
              <div className="me-4">
                <HelpPopup>Ex mollit est anim irure in magna dolore <strong>reprehenderit</strong> id in ut.</HelpPopup>
              </div>
              <TextField
                name="help"
                label="Within form input"
                helpPopupContent={<span>Ex mollit est anim irure in magna dolore <strong>reprehenderit</strong> id in ut.</span>}
              />
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <h6>Select Field</h6>
          <div className="col-6">
            <SelectField
              name="price"
              label="Price"
              choices={[
                ['free', 'Free'],
                ['low', '$ (Under $25)'],
                ['medium', '$$ ($25–$75)'],
                ['high', '$$$ (Over $75)'],
              ]}
            />
          </div>
          <div className="col-6">
            <SelectField
              name="event_type"
              label="Event Category"
              required
              choices={[
                [41, 'Art, Dance & Culture'],
                [61, 'Children'],
                [43, 'Classes, Workshops & Talks'],
                [44, 'Comedy'],
                [45, 'Community'],
                [47, 'Fairs & Festivals'],
                [50, 'Film'],
                [49, 'Food & Farmers Markets'],
                [62, 'Games & Hobbies'],
                [51, 'Health & Fitness'],
                [54, 'Literary & Book Clubs'],
                [55, 'Music'],
                [56, 'Nature & Outdoors'],
                [57, 'Religion & Spirituality'],
                [58, 'Seniors'],
                [63, 'Sports'],
                [59, 'Support Groups'],
                [60, 'Theater'],
              ]}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-6">
            <h6>Date Field</h6>
            <DateField
              name="start_date"
              label="Start Date"
              required
            />
          </div>

          <div className="col-6">
            <h6>Time Field</h6>
            <TimeField
              name="start_time"
              label="Start Time"
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-6">
            <h6>POI Select Field</h6>
            <LiveSearchSelectField
              name="poi"
              objectType="poi"
              label="Venue Name"
              required
            />
          </div>
        </div>

        <div className="mb-4">
          <h6>Event Dates</h6>
          <EventDatesField name="event_dates" />
        </div>

        <div className="mb-4">
          <h6>Address/Geocoder</h6>
          <AddressField mapboxToken={mapboxToken} debugInfo="venue: Test Venue" />
        </div>

        <div className="row mb-4">
          <div className="col-8">
            <h6>Open Times</h6>
            <OpenTimesField name="open_times" />
          </div>
        </div>

        <div className="row mb-4">
          <h6>Multi Checkbox Field</h6>
          <div className="mb-3">
            <CheckboxField
              name="multi_checkbox_horizontal"
              label="Horizontal layout"
            />
          </div>

          <div className="mb-2"><strong>Age Group</strong></div>
          <CheckboxMultiField
            horizontal={multiCheckboxHorizontal}
            name="age_group"
            required
            helpText="Check all that apply."
            choices={[
              ['all_ages', 'All Ages'],
              ['baby', 'Baby 0–1'],
              ['toddler', 'Toddler 2–4'],
              ['child', 'Child 5–9'],
              ['preteen', 'Preteen 10–12'],
              ['teen', 'Teen 13–17'],
              ['adult', 'Adult 18+'],
              ['senior', 'Senior'],
            ]}
          />
        </div>

        <div className="row mb-4">
          <h6>Radio Field</h6>
          <div className="mb-3">
            <CheckboxField
              name="radio_horizontal"
              label="Horizontal layout"
            />
          </div>

          <div className="mb-2"><strong>Event Type</strong></div>
          <RadioField
            horizontal={radioHorizontal}
            name="event_venue_type"
            required
            choices={[
              ['online', 'Online'],
              ['in_person', 'In Persion'],
              ['hybrid', 'Hybrid'],
            ]}
          />
        </div>

        <hr />

        <div>
          <input type="submit" value="Submit" className="btn-z primary" />
          <button type="button" className="btn-z outline ms-2" onClick={handleResetClick}>Reset</button>
          <button type="button" className="btn-z outline ms-2" onClick={handleFillClick}>Fill</button>
        </div>

        {formResult && (
          <>
            <hr />
            <h4>Form Result</h4>
            <table className="table table-bordered m-0">
              <tbody>
                {formResult.map(([key, val]) => (
                  <tr key={key}>
                    <th>{key}</th>
                    <td>{String(val)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </form>
    </FormProvider>
  );
};

export default FormComponentDemo;

import React from 'react';
import PropTypes from 'prop-types';

const SelectHandle = ({
  style = {},
  onClick,
}) => (
  <div
    className="z-form-select-handle"
    style={{ width: 18, height: 13, ...style }}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={7}
      width={12}
    >
      <path d="M6.72 6.78a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22L.15 1.64a.5.5 0 0 1 0-.71L.86.22a.49.49 0 0 1 .7 0L6 4.67 10.44.22a.5.5 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71L6.72 6.78Z" />
    </svg>
  </div>
);

SelectHandle.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default SelectHandle;

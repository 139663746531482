import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PillButton from 'components/PillButton';
import ErrorBoundary from 'components/ErrorBoundary';

const ErrorDemo = ({ buttonText = 'Error Demo' }) => {
  const [text, setText] = useState(buttonText);
  return (
    <ErrorBoundary fallback={<span className="text-danger">Oops! An error occurred.</span>}>
      <PillButton
        text={text}
        className="btn-z danger"
        onClick={() => setText({})} // eslint-disable-line
      />
    </ErrorBoundary>
  );
};

ErrorDemo.propTypes = {
  buttonText: PropTypes.string,
};

export default ErrorDemo;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const ICON_HEIGHT = 47;
export const ICON_WIDTH = 37;

const MapMarkerIcon = forwardRef(({
  style = {},
  active = false,
  strokeColor,
  fillColor,
  dotColor,
  onClick,
}, ref) => {
  strokeColor = strokeColor || (active ? '#ffffff' : '#afafaf');
  fillColor = fillColor || (active ? '#000000' : '#ffffff');
  dotColor = dotColor || (active ? '#ffffff' : '#000000');

  const scale = active ? 1.25 : 1;

  const iconStyle = {
    cursor: onClick ? 'pointer' : 'auto',
    transform: `scale(${scale})`,
    // transition: 'all .2s ease-in-out',
    ...style,
  };

  return (
    <svg
      ref={ref}
      preserveAspectRatio="xMidYMid meet"
      viewBox={`0 0 ${ICON_WIDTH} ${ICON_HEIGHT}`}
      width={ICON_WIDTH}
      height={ICON_HEIGHT}
      style={iconStyle}
      onClick={onClick || (() => {})}
    >
      <path
        d="M5.63 29.216L18.5 44.394l12.984-15.315-.004-.003.458-.599A16.807 16.807 0 006.608 6.51a16.77 16.77 0 00-1.543 21.974q.28.369.565.732z"
        style={{ fill: fillColor }}
      />
      <path
        d="M4.524 28.996h0A17.313 17.313 0 011 18.502V18.5a17.5 17.5 0 1135 0v.002a17.305 17.305 0 01-3.522 10.488l-.1.132-.001.004-.028.036a68.184 68.184 0 01-.324.422l-.07.09-.024.03h0L18.5 45.545 5.087 29.727q-.284-.363-.563-.73z"
        fill="none"
        style={{ stroke: strokeColor, strokeWidth: 2 }}
      />
      <circle cx="18.5" cy="18.5" r="7.288" style={{ fill: dotColor }} />
    </svg>
  );
});

MapMarkerIcon.displayName = 'MapMarkerIcon';

MapMarkerIcon.propTypes = {
  style: PropTypes.object,
  active: PropTypes.bool,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  dotColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default MapMarkerIcon;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isValid, parseISO, formatDuration, intervalToDuration, differenceInHours, addHours } from 'date-fns';
import Icon from 'components/Icon';

const LivestreamButton = ({ url, start, end, showCountdown, countdownMaxHours = 24 }) => {
  const now = new Date();
  if (!isValid(start)) start = parseISO(start);
  if (end && !isValid(end)) end = parseISO(end);

  // If no end date specified, default to 1 hoour duration
  const isStreaming = start < now && now < (end || addHours(start, 1));

  const [countdownText, setCountdownText] = useState(null);
  useEffect(() => {
    setCountdownText(getCountdownText());
    const intervalId = setInterval(() => setCountdownText(getCountdownText()), 30 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const getCountdownText = () => {
    const deltaHours = differenceInHours(start, now);
    const timeUntil = intervalToDuration({
      start: now,
      end: start,
    });
    if (deltaHours > countdownMaxHours || start < now) {
      return null;
    }
    const format = ['years', 'months', 'days', 'hours'];
    if (timeUntil.hours <= 4) format.push('minutes');
    return `Starting in ${formatDuration(timeUntil, { format })}`;
  };

  return (
    <>
      {showCountdown && <div className="small text-muted mb-1 me-2">{countdownText}</div>}
      <a className={classNames('btn-z', 'd-flex', isStreaming ? 'danger' : 'primary')} href={url} rel="nofollow">
        <Icon i="signal-stream" size="sm" />
        <span>{isStreaming ? 'Currently Live!' : 'Watch Live Stream'}</span>
      </a>
    </>
  );
};

LivestreamButton.propTypes = {
  url: PropTypes.string,
  start: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  end: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  showCountdown: PropTypes.bool,
  countdownMaxHours: PropTypes.number,
};

export default LivestreamButton;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import PillButton from 'components/PillButton';
import Modal from 'components/Modal';
import { useToggle } from 'hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const ShareModal = ({
  className,
  buttonText = 'Share',
  urlEmail,
  urlFacebook,
  urlTwitter,
  url,
}) => {
  const iconEmail = <Icon i="envelope" className="text-dark p-1" />;
  const iconFacebook = <Icon i={['fab', 'facebook-square']} className="text-dark p-1" />;
  const iconTwitter = <Icon i={['fab', 'twitter-square']} className="text-dark p-1" />;
  const headerText = 'Share this event';
  const [modalIsOpen, toggleModal] = useToggle(false);
  const [copied, toggleCopied] = useToggle(false);

  const handleRequestClose = () => toggleModal(false);

  // Reset "copied" state when modal is closed.
  useEffect(() => {
    if (!modalIsOpen) {
      toggleCopied(false);
    }
  }, [modalIsOpen]);

  return (
    <>
      <PillButton className={className} prependIcon={['faz', 'share']} text={buttonText} onClick={toggleModal} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleRequestClose}
        contentClassName="share-modal-content p-3"
        ariaHideApp={false}
      >
        <div className="row">
          <div className="offset-md-4 offset-3 col-6 col-md-4 mt-3 text-center">
            <div className="h5">{headerText}</div>
          </div>
        </div>
        <div className="d-flex justify-content-evenly mt-5">
          <div>
            <a
              className="border gray50 p-3 rounded-circle"
              href={urlEmail}
              rel="noreferrer"
              target="_blank"
              data-share-method="email"
              data-share-url={url}
            >
              {iconEmail}
            </a>
            <div className="ms-2 mt-4">Email</div>
          </div>
          <div>
            <a
              className="border gray50 p-3 rounded-circle"
              href={urlFacebook}
              rel="noreferrer"
              target="_blank"
              data-share-method="facebook"
              data-share-url={url}
            >
              {iconFacebook}
            </a>
            <div className="mt-4">Facebook</div>
          </div>
          <div>
            <a
              className="border gray50 rounded-circle p-3"
              href={urlTwitter}
              rel="noreferrer"
              target="_blank"
              data-share-method="twitter"
              data-share-url={url}
            >
              {iconTwitter}
            </a>
            <div className="mt-4 ms-1">Twitter</div>
          </div>
        </div>
        <div>
          <input
            aria-label={url}
            aria-describedby="share-modal"
            className="bg-light border-light form-control p-2 mt-5 mb-3"
            placeholder={url}
            type="text"
            value={url}
            readOnly
          />
          <CopyToClipboard
            onCopy={toggleCopied}
            text={url}
          >
            <div className="d-flex">
              <button
                className="btn-z border secondary text-dark share-modal-copy-button"
                type="button"
                data-share-method="copy-link"
                data-share-url={url}
              >
                {copied ? <span className="text-success">Link Copied!</span> : <span>Copy Link</span>}
              </button>
            </div>
          </CopyToClipboard>
        </div>
      </Modal>
    </>
  );
};

export default ShareModal;

ShareModal.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string,
  urlFacebook: PropTypes.string,
  urlTwitter: PropTypes.string,
  urlEmail: PropTypes.string,
  url: PropTypes.string,
};

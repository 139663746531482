import { useEffect } from 'react';

export default function useClickOutside (elementRef, callback, handleWindowBlur = false) {
  useEffect(() => {
    const handleClickOutside = evt => {
      if (elementRef.current && !elementRef.current.contains(evt.target)) {
        callback(evt);
      }
    };

    if (elementRef.current) {
      document.addEventListener('click', handleClickOutside);
      if (handleWindowBlur) {
        window.addEventListener('blur', callback);
      }
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('blur', callback);
    };
  }, [elementRef, callback, handleWindowBlur]);
}

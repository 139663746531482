import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import colors from 'themeColors';
import { formatDateRange } from 'utils';
import { isSameDay } from 'date-fns';
import { DateRange } from 'react-date-range';
import AnimateHeight from 'react-animate-height';
import DropdownButton, { MENU_ANCHOR_OPTS } from 'components/DropdownButton';
import RadioList from 'components/RadioList';

const LIMIT_FUTURE = 'future';
const LIMIT_PAST = 'past';

const DateRangePicker = ({
  presetOptions = {},
  defaultOption,
  useMobileStyle = false,
  dropdownClassName,
  buttonClassName,
  limitDates = LIMIT_FUTURE,
  menuAnchor,
  prependIcon,
  appendIcon,
  value,
  onChange,
  onMetadataChange = () => {},
}) => {
  const menuRef = useRef();

  if (typeof dropdownClassName === 'undefined') {
    dropdownClassName = 'me-2';
  }

  presetOptions = {
    ...presetOptions,
    custom: { label: 'Custom', dateRange: [] },
  };

  const options = Object.entries(presetOptions).map(([key, val]) => ([key, val.label]));
  const defaultDateRange = presetOptions[defaultOption].dateRange;

  const [activeOptionValue, setActiveOptionValue] = useState(null);

  useEffect(() => {
    if (value === null) {
      setActiveOptionValue(defaultOption);
    } else {
      const [start, end] = value;
      const matchedOption = Object.entries(presetOptions).find(([_key, val]) => {
        const [optStart, optEnd] = val.dateRange;
        return isSameDay(start, optStart) && isSameDay(end, optEnd);
      });
      setActiveOptionValue(matchedOption ? matchedOption[0] : 'custom');
    }
  }, [value]);

  const handleOptionChange = val => {
    if (val === 'custom') {
      setActiveOptionValue('custom');
    } else {
      onChange(val === defaultOption ? [null, null, defaultOption] : [...presetOptions[val].dateRange, val]);
      if (menuRef.current) menuRef.current.close();
    }
  };

  // Custom range
  const [customRangeDates, setCustomRangeDates] = useState(defaultDateRange);
  const handleCustomRangeChange = ranges => {
    const { startDate, endDate } = ranges.selection;
    setCustomRangeDates([startDate, endDate]);
    if (useMobileStyle) onChange([startDate, endDate, 'custom']);
  };

  useEffect(() => setCustomRangeDates(value || defaultDateRange), [value]);

  const handleMenuClose = () => {
    if (activeOptionValue === 'custom' && customRangeDates && customRangeDates.length === 2) {
      onChange([...customRangeDates, 'custom'])
    }
  };
  const handleApplyClick = () => menuRef.current.close();

  let activeOptionLabel = activeOptionValue && presetOptions[activeOptionValue].label;
  if (value && activeOptionValue === 'custom') {
    const [start, end] = value;
    activeOptionLabel = formatDateRange(start, end);
  }

  useEffect(() => {
    if (activeOptionLabel !== 'Custom') {
      let dateLabel = activeOptionLabel;
      if (dateLabel && activeOptionValue !== 'custom') dateLabel = dateLabel.toLowerCase();
      onMetadataChange({
        dateLabel,
        dateSlug: activeOptionValue,
        dateRange: value && activeOptionValue === 'custom' ? value : null,
      });
    }
  }, [activeOptionLabel]);

  if (!activeOptionValue) return null;

  // custom range picker active selection
  const [startDate, endDate] = customRangeDates || value || defaultDateRange;
  const customRanges = [{ startDate, endDate, key: 'selection' }];

  const optionList = (
    <RadioList
      listId="filter-date"
      className={useMobileStyle ? 'invert stretch' : ''}
      options={options}
      value={activeOptionValue}
      onChange={handleOptionChange}
    />
  );

  let limitProps = {};
  if (limitDates === LIMIT_FUTURE) {
    limitProps = { minDate: new Date() };
  } else if (limitDates === LIMIT_PAST) {
    limitProps = { maxDate: new Date() };
  }

  const datePicker = (
    <DateRange
      {...limitProps}
      months={2}
      direction={useMobileStyle ? 'vertical' : 'horizontal'}
      showDateDisplay={false}
      rangeColors={[colors.primary]}
      ranges={customRanges}
      onChange={handleCustomRangeChange}
    />
  );

  return useMobileStyle ? (
    <>
      {optionList}
      <AnimateHeight height={activeOptionValue === 'custom' ? 'auto' : 0} duration={500} style={{ textAlign: 'center' }}>
        {datePicker}
      </AnimateHeight>
    </>
  ) : (
    <DropdownButton
      ref={menuRef}
      className={dropdownClassName}
      menuAnchor={menuAnchor}
      buttonClassName={buttonClassName}
      text={activeOptionLabel}
      prependIcon={prependIcon}
      appendIcon={appendIcon}
      isHighlighted={activeOptionValue !== defaultOption}
      closeTimeout={activeOptionValue === 'custom' ? 0 : 200}
      onClose={handleMenuClose}
    >
      <div className="px-5 py-4 d-flex">
        <div style={{ width: 154 }}>
          {optionList}
        </div>
        {activeOptionValue === 'custom' && (
          <div className="border-start ps-4">
            {datePicker}
            <div className="d-flex justify-content-end">
              <button type="button" className="btn-z primary" onClick={handleApplyClick}>Apply</button>
            </div>
          </div>
        )}
      </div>
    </DropdownButton>
  );
};

DateRangePicker.propTypes = {
  presetOptions: PropTypes.objectOf(PropTypes.shape({
    dateRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    label: PropTypes.string,
  })),
  defaultOption: PropTypes.string,
  useMobileStyle: PropTypes.bool,
  dropdownClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  limitDates: PropTypes.oneOf([LIMIT_FUTURE, LIMIT_PAST]),
  menuAnchor: PropTypes.oneOf(MENU_ANCHOR_OPTS),
  prependIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  appendIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChange: PropTypes.func,
  onMetadataChange: PropTypes.func,
};

export default DateRangePicker;

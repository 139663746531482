import React from 'react';
import PropTypes from 'prop-types';
import { isValid, parseISO } from 'date-fns';
import camelize from 'camelize';
import BookmarkToggleButton from 'components/BookmarkToggleButton';
import LivestreamButton from 'components/LivestreamButton';
import ShareModal from 'components/ShareModal';

const EventMobileBottomBar = ({
  eventData,
  urlEmail,
  urlFacebook,
  urlTwitter,
  startDate,
  endDate,
  url,
}) => {
  const { onlineUrl } = camelize(eventData);
  if (!isValid(startDate)) startDate = parseISO(startDate);
  if (endDate && !isValid(endDate)) endDate = parseISO(endDate);

  return (
    <footer className="event-mobile-bottom-bar">
      <div>
        {onlineUrl && (
          <LivestreamButton
            url={onlineUrl}
            start={startDate}
            endDate={endDate}
            showCountdown
          />
        )}
      </div>

      <div>
        <ShareModal
          className="ms-3"
          buttonText=""
          urlEmail={urlEmail}
          urlFacebook={urlFacebook}
          urlTwitter={urlTwitter}
          url={url}
        />

        <BookmarkToggleButton
          className="ms-3"
          objectType="event"
          objectId={eventData.id}
          buttonText=""
        />
      </div>
    </footer>
  );
};

EventMobileBottomBar.propTypes = {
  eventData: PropTypes.object.isRequired,
  urlFacebook: PropTypes.string,
  urlTwitter: PropTypes.string,
  urlEmail: PropTypes.string,
  url: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
};

export default EventMobileBottomBar;

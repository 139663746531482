import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { format, formatISO, isSameDay, parseISO } from 'date-fns';
import { urls, defaultLocations, distanceOptions, defaultDistance } from 'app-constants';
import { milesToMeters, kilometersToMeters } from 'utils';
import { useGetAPI, useHistory, useBootstrapBreakpoint, useSavedSearchMatch } from 'hooks';
import { useFilterContext, useUserContext } from 'context';
import { DISTANCE_UNITS_MI, DISTANCE_UNITS_KM, VALID_FILTER_PARAMS } from 'components/filters';
import CurrentLocIcon from 'components/CurrentLocIcon';
import ErrorBoundary from 'components/ErrorBoundary';
import EventCard from 'components/EventCard';
import PoiCard from 'components/PoiCard';
import EventMapPopup from 'components/EventMapPopup';
import PoiMapPopup from 'components/PoiMapPopup';
import OrderingMenu, { ORDER_DEFAULT, ORDER_DATE } from 'components/OrderingMenu';
import ListingsMap from 'components/ListingsMap';
import LoadingOverlay from 'components/LoadingOverlay';
import SimpleMapPopup from 'components/SimpleMapPopup';
import Pagination from 'components/Pagination';
import SearchAd from 'components/SearchAd';
import AdSenseCard from 'components/AdSenseCard';

const VALID_API_PARAMS = [
  ...VALID_FILTER_PARAMS, 'address_id', 'latitude', 'longitude', 'ordering',
];

const VALID_WIDGET_PARAMS = [
  'event_types', 'is_online', 'is_in_person', 'age_groups', 'prices',
];

const CONVERT_FUNCS = {
  [DISTANCE_UNITS_MI]: milesToMeters,
  [DISTANCE_UNITS_KM]: kilometersToMeters,
};

const getStartTime = event => {
  try {
    const [startTimestamp] = event.nextEventOccurrence.timestamps[0];
    return parseISO(startTimestamp);
  } catch (e) {
    return null;
  }
};

const ListView = ({
  objectType = 'event',
  isAuthenticated,
  distanceUnits = DISTANCE_UNITS_MI,
  mapboxToken,
  pageSize = 20,
  widgetId,
  widgetTitle,
  widgetParams,
  showAds = false,
  showAdSenseAds = false,
  adSenseClient,
  adSenseLayoutKey,
  adSenseSlot,
}) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();
  const isMobile = useBootstrapBreakpoint('down-md');
  const isWidget = !!widgetId;

  // Configuration values based on whether we're displaying events or POIs
  const { Card, objectTypeLabel, apiPath, MapPopup } = {
    event: {
      Card: EventCard,
      MapPopup: EventMapPopup,
      objectTypeLabel: 'event',
      apiPath: 'events',
    },
    poi: {
      Card: PoiCard,
      MapPopup: PoiMapPopup,
      objectTypeLabel: 'venue',
      apiPath: 'poi',
    },
  }[objectType];

  const filterContext = useFilterContext();

  const currentPage = (queryParams && queryParams.page) || 1;

  // Parse filter query parameters
  const convert = CONVERT_FUNCS[distanceUnits];
  let filterParams;

  if (isWidget) {
    filterParams = {
      ...widgetParams,
      ...Object.entries(queryParams || {}).reduce((result, [key, value]) => {
        if (VALID_WIDGET_PARAMS.includes(key)) {
          result[key] = value;
        }
        return result;
      }, {}),
      ordering: filterContext.ordering,
      widget: widgetId,
    };
  } else {
    filterParams = queryParams && Object.entries({ ...queryParams }).reduce((result, [key, value]) => {
      if (key === 'radius') {
        result[key] = convert(value);
      } else if (VALID_API_PARAMS.includes(key)) {
        result[key] = value;
      }
      return result;
    }, {});
  }

  // Fill in missing params with defaults
  const nowRef = useRef(new Date());
  if (filterParams) {
    if (!filterParams.hasOwnProperty('radius')) {
      filterParams.radius = convert(defaultDistance[objectType] || distanceOptions[0]);
    }
    if (['address_id', 'latitude', 'longitude'].every(prop => !filterParams.hasOwnProperty(prop))) {
      const defaultLoc = defaultLocations[0];
      filterParams.address_id = defaultLoc.id;
      filterParams.latitude = defaultLoc.lat;
      filterParams.longitude = defaultLoc.lon;
    }
    if (['date_start', 'date_end'].every(prop => !filterParams.hasOwnProperty(prop))) {
      const now = nowRef.current;
      filterParams.date_start = formatISO(now);
      filterParams.date_end = format(now, 'yyyy-MM-dd');
    }
  }

  const waitForGeoLoc = filterParams && filterParams.address_id === 'current' && !filterParams.latitude;

  // ads
  const [adData, setAdData] = useState(null);
  const fetchAd = () => {
    setAdData(null);
    fetch(urls.searchAd)
      .then(response => {
        if (!response.ok && response.status !== 404) throw new Error(response.statusText);
        return response.json();
      })
      .then(data => {
        if (data.detail === 'Not found.') {
          setAdData(null);
        } else {
          setAdData(camelize(data));
        }
      })
      .catch(err => console.error(err));
  };

  // Call API
  let path;
  if (typeof queryParams !== 'undefined' && (isWidget || queryParams.address_id) && !waitForGeoLoc) path = apiPath;
  const apiParams = {
    ...filterParams,
    page: currentPage,
    page_size: pageSize,
  };
  const apiOpts = { queryObject: apiParams };
  if (showAds) {
    apiOpts.onSuccess = fetchAd;
  }
  const { data: itemData, isFetching, error, pagination } = useGetAPI(path, apiOpts);

  // Pagination
  const handlePaginationChange = page => updateQueryParams({ page });
  let firstItemIndex;
  let lastItemIndex;
  if (itemData && pagination) {
    firstItemIndex = (pagination.curPage - 1) * pageSize + 1;
    lastItemIndex = firstItemIndex + itemData.length - 1;
  }

  // Reset scroll position when fetching new data
  useEffect(() => {
    if (isFetching) window.scrollTo(0, 0);
  }, [isFetching]);

  // Fetch bookmarked events
  const { savedEventIds } = useUserContext();

  // Determine if current filter conditionas match any of the user's saved searches
  const savedSearchName = useSavedSearchMatch();

  // Construct human-readable description of active filters
  const { venueTypeLabel, dateLabel, radiusLabel, locationName, categoryNames, ageGroupNames, priceNames } = filterContext;
  let descText = isWidget ? widgetTitle : '…';
  if (!isWidget && !isFetching && typeof pagination.totalItems !== 'undefined') {
    const freeOnly = !!priceNames && priceNames.length === 1 && priceNames[0] === 'free';
    descText = pagination.totalItems.toString();
    if (freeOnly) descText += ' free';
    if (venueTypeLabel) descText += ' ' + venueTypeLabel;
    descText += ' ' + objectTypeLabel;
    if (pagination.totalItems !== 1) descText += 's';
    if (dateLabel) {
      let prefix = '';
      if (dateLabel.startsWith('next')) prefix = 'in the ';
      if (dateLabel.includes('–')) prefix = 'from ';
      if (dateLabel.match(/^\w{3} \d{1,2}(, \d{4})?$/)) prefix = 'on ';
      descText += ' ' + prefix + dateLabel;
    }
    if (queryParams && queryParams.keyword) {
      descText += ` for “${queryParams.keyword}”`;
    }
    if (radiusLabel && locationName) {
      const loc = locationName === 'Current Location' ? 'my current location' : locationName;
      descText += ` within ${radiusLabel} of ${loc}`;
    }
    if (categoryNames && categoryNames.length > 0) descText += ` like ${categoryNames.join(', ')}`;
    if (ageGroupNames && ageGroupNames.length > 0) descText += ` for ${ageGroupNames.join(', ')}`;
    if (priceNames && priceNames.length > 0 && !freeOnly) descText += ` priced ${priceNames.join(', ')}`;
  }

  const handleSetFilter = params => updateQueryParams({ ...queryParams, ...params, page: null });

  const { ordering } = filterContext;
  const showDateDividers = objectType === 'event' && [ORDER_DEFAULT, ORDER_DATE].includes(ordering);

  // Focus map marker when hovering on card
  const [focusedMarkerId, setFocusedMarkerId] = useState(null);

  let mapViewport;
  if (filterParams && ['latitude', 'longitude'].every(prop => filterParams.hasOwnProperty(prop))) {
    const { latitude, longitude } = filterParams;
    mapViewport = { latitude, longitude, zoom: 14 };
  }

  const mapMarkerData = (itemData || []).map(item => ({
    id: item.id,
    label: `${item.id} - ${item.name}`,
    latitude: item.pointOfInterest ? item.pointOfInterest.lat : item.lat,
    longitude: item.pointOfInterest ? item.pointOfInterest.lon : item.lon,
    popupContent: <MapPopup {...item} searchParams={queryParams} />,
  }));

  if (filterParams && filterParams.address_id === 'current' && filterParams.latitude && filterParams.longitude) {
    mapMarkerData.push({
      id: 'currentLocation',
      label: 'Current Location',
      latitude: filterParams.latitude,
      longitude: filterParams.longitude,
      iconComponent: CurrentLocIcon,
      iconProps: { size: 20 },
      popupContent: <SimpleMapPopup text="Current Location" />,
      popupWidth: 160,
    });
  }

  // TODO - temporarily disables map interface for mobile breakpoints
  const hideMap = useBootstrapBreakpoint('down-lg');

  const adIndex = useMemo(() => {
    if (adData && itemData) {
      return (adData.firstForSession && itemData.length > 1) ? 1 : Math.floor(Math.random() * itemData.length);
    } else {
      return null;
    }
  }, [JSON.stringify(adData)]);

  const adSenseAdIndex = useMemo(() => showAdSenseAds && itemData && Math.floor(Math.random() * (itemData.length - 2) + 2), [showAdSenseAds, JSON.stringify(itemData)]);

  const noResultsMessage = isWidget && !isFetching && !(itemData || []).length && (
    <div className="text-tertiary mb-3"><em>No events.</em></div>
  );

  return (
    <>
      <div className="listings-container">
        <LoadingOverlay show={isFetching} align="top" className="bg-lt" />
        <header>
          {!!error && (
            <div className="alert alert-danger mb-3" role="alert">
              Failed to fetch listings. Please try your request again.
            </div>
          )}
          {savedSearchName && <div className="text-tertiary mb-3">This is your saved search: <strong>{savedSearchName}</strong></div>}
          <div className="d-flex mb-4 align-items-baseline">
            <h6 className="lh-base m-0 fw-bold" style={{ flex: 1 }}>{descText}</h6>
            {(!isMobile || isWidget) && <OrderingMenu objectType={objectType} menuAnchor="right" />}
          </div>

          {noResultsMessage}
        </header>


        {(itemData || []).map((item, idx) => {
          const ad = idx === adIndex && (
            <div className="mb-3">
              <SearchAd key={adData.id} {...adData} useCompactStyle={isMobile} />
            </div>
          );

          const adSenseAd = idx === adSenseAdIndex && (
            <div className="mb-3">
              <AdSenseCard
                client={adSenseClient}
                layoutKey={adSenseLayoutKey}
                slot={adSenseSlot}
              />
            </div>
          );

          let divider;
          if (showDateDividers) {
            const startTime = getStartTime(item);
            const prevStartTime = getStartTime(itemData[idx - 1]) || new Date();
            if (startTime && prevStartTime && !isSameDay(startTime, prevStartTime)) {
              const dateText = format(startTime, 'EEEE, MMMM d');
              divider = <div className="listings-divider mb-3"><div>{dateText}</div></div>;
            }
          }

          return (
            <Fragment key={item.id}>
              <div key={item.id} className="mb-3">
                {divider}
                <Card
                  {...item}
                  useCompactStyle={isMobile}
                  isBookmarked={savedEventIds && savedEventIds.includes(item.id)}
                  searchParams={queryParams}
                  onSetFilter={handleSetFilter}
                  onMouseEnter={() => setFocusedMarkerId(item.id)}
                  onMouseLeave={() => setFocusedMarkerId(null)}
                />
              </div>
              {ad}
              {adSenseAd}
            </Fragment>
          );
        })}

        {itemData && itemData.length > 0 && (
          <div className="py-5">
            <Pagination curPage={currentPage} numPages={pagination.totalPages} onChange={handlePaginationChange} />
            <div className="mt-3 text-center text-primary">
              {firstItemIndex}–{lastItemIndex} of {pagination.totalItems} {objectTypeLabel}{pagination.totalItems !== 1 && 's'}
            </div>
          </div>
        )}
      </div>
      <div className="listings-map-container">
        <ErrorBoundary>
          {mapViewport && !hideMap && (
            <ListingsMap
              mapboxToken={mapboxToken}
              defaultViewport={mapViewport}
              markers={mapMarkerData}
              focusedMarkerId={focusedMarkerId}
            />
          )}
        </ErrorBoundary>
      </div>
    </>
  );
};

ListView.propTypes = {
  objectType: PropTypes.oneOf(['event', 'poi']),
  isAuthenticated: PropTypes.bool,
  distanceUnits: PropTypes.oneOf([DISTANCE_UNITS_MI, DISTANCE_UNITS_KM]),
  mapboxToken: PropTypes.string,
  pageSize: PropTypes.number,
  widgetId: PropTypes.string,
  widgetTitle: PropTypes.string,
  widgetParams: PropTypes.object,
  showAds: PropTypes.bool,
  showAdSenseAds: PropTypes.bool,
  adSenseClient: PropTypes.string,
  adSenseLayoutKey: PropTypes.string,
  adSenseSlot: PropTypes.number,
};

export default ListView;

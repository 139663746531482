import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ i, className, style = {}, attrs = {}, ...rest }) => (
  <i className={className} style={{ fontSize: 20, lineHeight: 1, ...style }} {...attrs}>
    <FontAwesomeIcon icon={i} {...rest} />
  </i>
);

Icon.propTypes = {
  i: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  attrs: PropTypes.object,
};

export default Icon;

import React from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from 'context';

const AuthRequiredLink = ({ className, style, text, url, defaultToRegister = true }) => {
  const { id: userId, setAuthRequested } = useUserContext();

  const handleClick = evt => {
    if (!userId) {
      evt.preventDefault();
      setAuthRequested({
        register: defaultToRegister,
        redirect: url,
      });
    }
  };

  return <a className={className} style={style} href={url} onClick={handleClick}>{text}</a>;
};

AuthRequiredLink.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string,
  url: PropTypes.string,
  defaultToRegister: PropTypes.bool,
};

export default AuthRequiredLink;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ageGroups } from 'app-constants';
import { hasSameContents } from 'utils';
import { useFilterContext } from 'context';
import Checkbox from 'components/Checkbox';
import CheckboxList from 'components/CheckboxList';
import DropdownButton from 'components/DropdownButton';

const AGE_GROUP_OPTIONS = ageGroups;

const AgeGroupFilter = ({ useMobileStyle = false, value, onChange }) => {
  value = value || [];

  const menuRef = useRef();
  const [internalValue, setInternalValue] = useState(value);

  const valueAsString = value.reduce((result, item) => `${result}${item}`, '');
  useEffect(() => {
    if (!hasSameContents(value, internalValue)) {
      setInternalValue(value);
    }
  }, [valueAsString]);

  const handleMenuClose = () => onChange(internalValue);
  const handleClearClick = () => setInternalValue([]);
  const handleApplyClick = () => menuRef.current.close();
  const handleSelectAllChange = checked => setInternalValue(checked ? AGE_GROUP_OPTIONS.map(([val]) => val) : []);
  const handleSelectAllChangeMobile = val => onChange(val.length === 1 ? AGE_GROUP_OPTIONS.map(([val]) => val) : []);

  let activeOptionLabel;
  switch (value.length) {
    case 0:
      activeOptionLabel = 'Any Age';
      break;
    case 1:
      activeOptionLabel = AGE_GROUP_OPTIONS.find(([val]) => val === value[0])[1];
      break;
    default:
      activeOptionLabel = `${value.length} Age Groups`;
  }

  const { updateFilterContext } = useFilterContext();
  const activeAgeGroups = ageGroups.filter(([val]) => value.includes(val));
  const activeAgeGroupIds = activeAgeGroups.map(opt => opt[0]);
  const activeAgeGroupNames = activeAgeGroups.map(opt => opt[2].toLowerCase());
  useEffect(() => {
    updateFilterContext({ ageGroupNames: activeAgeGroupNames, ageGroups: activeAgeGroupIds });
  }, [JSON.stringify(activeAgeGroupIds)]);

  return useMobileStyle ? (
    <>
      <CheckboxList
        listId="filter-age-group"
        className="invert stretch"
        options={AGE_GROUP_OPTIONS}
        value={value}
        onChange={onChange}
      />
      <CheckboxList
        className="invert stretch"
        options={[['selectAll', 'Select All']]}
        value={value.length === AGE_GROUP_OPTIONS.length ? ['selectAll'] : []}
        onChange={handleSelectAllChangeMobile}
      />
    </>
  ) : (
    <DropdownButton
      ref={menuRef}
      className="me-2"
      text={activeOptionLabel}
      isHighlighted={value.length > 0}
      closeTimeout={0}
      onClose={handleMenuClose}
    >
      <div className="px-5 py-4" style={{ width: 450 }}>
        <CheckboxList
          listId="filter-age-group"
          options={AGE_GROUP_OPTIONS}
          value={internalValue}
          onChange={setInternalValue}
        />
      </div>
      <div className="px-5 py-4 border-top d-flex align-items-center justify-content-between">
        <div>
          <Checkbox
            labelText="Select All"
            checked={internalValue.length === AGE_GROUP_OPTIONS.length}
            onChange={handleSelectAllChange}
          />
        </div>
        <div>
          <button type="button" className="btn-z subtle me-2" onClick={handleClearClick}>Clear</button>
          <button type="button" className="btn-z primary" onClick={handleApplyClick}>Apply</button>
        </div>
      </div>
    </DropdownButton>
  );
};

AgeGroupFilter.propTypes = {
  useMobileStyle: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.oneOf(AGE_GROUP_OPTIONS.map(([val]) => val))),
  onChange: PropTypes.func,
};

export default AgeGroupFilter;

import {
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
} from 'date-fns';
import { urls } from 'app-constants';

export function getTimezoneAbbr () {
  // return an abbreviated representation of the user's local timezone
  const fmt = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'short',
  });
  const now = new Date();
  const parts = fmt.formatToParts(now);
  return (parts.find(p => p.type === 'timeZoneName') || {}).value;
}

export function formatDateRange (start, end) {
  const now = new Date();
  let text = '';

  if (isSameDay(start, end)) {
    const fmt = isSameYear(now, start) ? 'MMM d' : 'MMM d, yyyy';
    text = format(start, fmt);
  } else if (isSameMonth(start, end)) {
    text = `${format(start, 'MMM d')} – ${format(end, 'd')}`;
    if (!isSameYear(now, start)) text += `, ${format(start, 'yyyy')}`;
  } else if (isSameYear(start, end)) {
    text = `${format(start, 'MMM d')} – ${format(end, 'MMM d')}`;
    if (!isSameYear(now, start)) text += `, ${format(start, 'yyyy')}`;
  } else {
    const fmt = 'MMM d, yyyy';
    text = `${format(start, fmt)} – ${format(end, fmt)}`;
  }

  return text;
}

export function formatDuration (totalSeconds) {
  if (isNaN(parseInt(totalSeconds, 10))) {
    return '';
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const seconds = Math.floor(totalSeconds) % 60;
  return `${hours ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function round (value, precision = 0) {
  const base = 10 ** precision;
  return (Math.round(value * base) / base).toFixed(precision);
}

export function milesToMeters (value, precision = 0) {
  return round(value * 1609.34, precision);
}

export function kilometersToMeters (value, precision = 0) {
  return round(value * 1000, precision);
}

export function metersToMiles (value, precision = 0) {
  return round(value / 1609.34, precision);
}

export function metersToKilometers (value, precision = 0) {
  return round(value / 1000, precision);
}

// Check if two arrays have the same contents, ignoring order
export function hasSameContents (arr1, arr2) {
  return arr1.every(item => arr2.includes(item)) && arr2.every(item => arr1.includes(item));
}

export function supportsTouch () {
  return ('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch);
}

export function hasOwnProperty (obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function getCsrfToken () {
  const cookies = document.cookie.split('; ').reduce((result, item) => {
    const [_name, val] = item.split('=');
    result[_name] = val;
    return result;
  }, {});

  return cookies.csrftoken;
}

export function trackEvent (event, params = {}) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event, ...params });
}

export function formatOrdinal (n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function nthOfMonth (date) {
  const days = eachDayOfInterval({
    start: startOfMonth(date),
    end: endOfMonth(date),
  }).filter(d => d.getDay() === date.getDay());

  const idx = days.findIndex(d => isSameDay(d, date)) + 1;
  const isLast = idx === days.length;
  return [idx, isLast];
}

export const messages = {
  info: 'INFO',
  success: 'SUCCESS',
  warning: 'WARNING',
  error: 'ERROR',

  add: function (
    level = this.INFO,
    msg,
    onSuccess = () => {},
    onError = err => console.error(err),
  ) {
    return fetch(urls.postMessage, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ level, msg }),
    })
      .then(onSuccess)
      .catch(onError);
  },
};

export function logObj (obj) {
  console.log(JSON.stringify(obj, null, 2));  // eslint-disable-line
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { addDays, format, formatISO } from 'date-fns';
import { urls } from 'app-constants';
import { useGetAPI } from 'hooks';
import EventCard from 'components/EventCard';
import LoadingOverlay from 'components/LoadingOverlay';

/**
 * Renders "related" events for display on event/POI detail pages.
 */
const RelatedEventList = ({
  filterParams = {},
  fallbackFilterParams,
  titleContent,
  displayCount = 3,
  excludeEventIds = [],
}) => {
  // Call API
  const [apiParams, setApiParams] = useState({ ...filterParams });
  const { data: eventData, isFetching, error } = useGetAPI('events/', { queryObject: apiParams });

  useEffect(() => {
    if (!isFetching && eventData && !eventData.length && !error && fallbackFilterParams) {
      setApiParams({ ...fallbackFilterParams });
    }
  }, [eventData, isFetching, error, fallbackFilterParams]);

  const allEvents = (eventData || []).filter(event => !excludeEventIds.includes(event.id));
  const visibleEvents = allEvents.slice(0, displayCount);

  const includeShowMoreButton = allEvents.length > displayCount && !isFetching;
  /* eslint-disable camelcase */
  const date_start = formatISO(new Date());
  const date_end = format(addDays(new Date(), 29), 'yyyy-MM-dd');
  /* eslint-enable */
  const qs = queryString.stringify({ ...apiParams, date_start, date_end }, {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  });
  const moreUrl = `${urls.events}?${qs}`;

  return (
    <div className="row gx-lg-4 position-relative overflow-hidden" style={{ minHeight: 200 }}>
      <LoadingOverlay show={isFetching} align="top" className="bg-lt" />

      {!!error && (
        <div className="alert alert-danger mb-3" role="alert">
          Failed to fetch event listings. Please try your request again.
        </div>
      )}

      {titleContent && visibleEvents.length > 0 && <div dangerouslySetInnerHTML={{ __html: titleContent }} />}

      {visibleEvents.map(event => (
        <div key={event.id} className="col-md-4 col-sm-fullwidth mb-3">
          <EventCard className="border" useCompactStyle {...event} />
        </div>
      ))}

      {includeShowMoreButton && (
        <div className="mt-3 d-flex justify-content-sm-center">
          <a className="btn-z subtle-nobg sm-lg" href={moreUrl}>Explore More</a>
        </div>
      )}
    </div>
  );
};

RelatedEventList.propTypes = {
  /** Query parameters passed through to API call */
  filterParams: PropTypes.object,
  /** Fallback query parameters passed through to API call, if initial query returns no results */
  fallbackFilterParams: PropTypes.object,
  /** Optional HTML to display before event listings */
  titleContent: PropTypes.string,
  /** Number of events to display */
  displayCount: PropTypes.number,
  /** IDs of events to exclude from display */
  excludeEventIds: PropTypes.arrayOf(PropTypes.number),
};

export default RelatedEventList;

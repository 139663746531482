import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-map-gl';
import { useClickOutside } from 'hooks';
import MapMarkerIcon, { ICON_HEIGHT, ICON_WIDTH } from 'components/MapMarkerIcon';

const POPUP_WIDTH = 330;

const MapMarker = ({
  latitude,
  longitude,
  label,
  active = false,
  iconComponent: IconComponent,
  iconProps = {},
  showPopup = false,
  popupWidth,
  popupContent,
  mapContainerEl,
  onClick,
  onRequestClose,
}) => {
  const popupContainerRef = useRef();
  const iconRef = useRef();
  const [popupPosition, setPopupPosition] = useState(null);
  useClickOutside(popupContainerRef, () => {
    onRequestClose();
  });

  popupWidth = popupWidth || POPUP_WIDTH;
  const iconWidth = iconProps.size || ICON_WIDTH;
  const popupVerticalOffset = (iconProps.size ? iconProps.size / 2 : ICON_HEIGHT) + 15;
  let popup;
  if (active && popupContent && popupPosition) {
    const pos = popupPosition;
    const popupContainerStyle = {
      position: 'absolute',
      width: popupWidth,
    };

    if (pos.vertical === 'bottom') {
      popupContainerStyle.top = 15;
    } else {
      popupContainerStyle.bottom = popupVerticalOffset;
    }

    if (pos.horizontal === 'left') {
      popupContainerStyle.right = iconWidth / 2 * -1;
    } else if (pos.horizontal === 'right') {
      popupContainerStyle.left = iconWidth / 2 * -1;
    } else {
      popupContainerStyle.left = popupWidth / 2 * -1;
    }

    popup = (
      <div ref={popupContainerRef} style={popupContainerStyle}>
        {popupContent}
      </div>
    );
  }

  useEffect(() => {
    if (showPopup) {
      const containerRect = mapContainerEl.getBoundingClientRect();
      const iconRect = iconRef.current.getBoundingClientRect();

      const popupHeightReq = 200; // estimated maximum height of popup element
      const topClearance = iconRect.top - containerRect.top - 20;
      const leftClearance = iconRect.left - containerRect.left - 20;
      const rightClearance = containerRect.right - iconRect.right - 20;

      const pos = {
        horizontal: leftClearance < popupWidth / 2 ? 'right' : rightClearance < popupWidth / 2 ? 'left' : 'center',
        vertical: topClearance < popupHeightReq ? 'bottom' : 'top',
      };
      setPopupPosition(pos);
    } else {
      setPopupPosition(null);
    }
  }, [showPopup]);

  // styles ensure the "tip" of the marker graphic is centered on the location
  const containerStyle = {
    height: 1,
    position: 'absolute',
    width: 1,
  };

  const markerIcon = IconComponent ? (
    <IconComponent
      ref={iconRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        transform: 'translate(-50%, -50%)',
      }}
      active={active}
      onClick={onClick}
      {...iconProps}
    />
  ) : (
    <MapMarkerIcon
      ref={iconRef}
      style={{
        position: 'absolute',
        bottom: 0,
        left: -18,
      }}
      active={active}
      onClick={onClick}
      {...iconProps}
    />
  );

  return (
    <Marker latitude={latitude} longitude={longitude}>
      <div
        style={containerStyle}
        data-ref="map-marker"
        data-label={label}
      >
        {markerIcon}
        {popup}
      </div>
    </Marker>
  );
};

MapMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  label: PropTypes.string,
  active: PropTypes.bool,
  iconComponent: PropTypes.elementType,
  iconProps: PropTypes.object,
  showPopup: PropTypes.bool,
  popupWidth: PropTypes.number,
  popupContent: PropTypes.node,
  mapContainerEl: PropTypes.instanceOf(Element),
  onClick: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default MapMarker;

import React, { isValidElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const PillButton = ({
  text,
  className,
  style,
  attrs = {},
  isActive = false,
  isHighlighted = false,
  isDisabled = false,
  prependIcon,
  appendIcon,
  href,
  blank = false,
  onClick = () => {},
}) => {
  const classes = classNames({
    'btn-z': true,
    'icon-only': !text,
    active: isActive,
    highlighted: isHighlighted,
    disabled: isDisabled,
    [className]: !!className,
  });

  const handleClick = evt => {
    evt.preventDefault();
    if (!isDisabled) onClick();
  };

  // if icon props already contain instances of Icon,
  // pass through as-is.
  if (prependIcon && !isValidElement(prependIcon)) {
    prependIcon = <Icon i={prependIcon} size="sm" />;
  }
  if (appendIcon && !isValidElement(appendIcon)) {
    appendIcon = <Icon i={appendIcon} size="sm" />;
  }

  const content = (
    <>
      {prependIcon}
      {text && <span style={{ flex: 1, textAlign: 'left' }}>{text}</span>}
      {appendIcon}
    </>
  );

  const props = {
    className: classes,
    style,
    ...attrs,
  };

  if (href && blank) {
    props.target = '_blank';
    props.rel = 'noopener noreferrer';
  }

  return href ? (
    <a href={href} {...props}>
      {content}
    </a>
  ) : (
    <button type="button" onClick={handleClick} {...props}>
      {content}
    </button>
  );
};

PillButton.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  attrs: PropTypes.object,
  isActive: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isDisabled: PropTypes.bool,
  prependIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  appendIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  href: PropTypes.string,
  blank: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PillButton;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'components/Modal';

const CalendarEmbedCode = ({ embedUrl, isOpen, onRequestClose }) => {
  const [copied, toggleCopied] = useToggle(false);

  useEffect(() => {
    if (!isOpen) {
      toggleCopied(false);
    }
  }, [isOpen]);

  const textContent = `<iframe src="${embedUrl}" frameborder="0" style="width:100%; height:720px">`;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentClassName="p-3"
      interiorClassName="z-modal-interior wide"
      ariaHideApp={false}
    >
      <h4 className="mb-3">Embed Calendar</h4>

      <div style={{ position: 'relative' }}>
        <div className="mb-2">
          <textarea
            className="form-control"
            style={{ fontSize: 13 }}
            rows={6}
            value={textContent}
            readOnly
          />
        </div>
        <div className="text-hint mb-2">

        </div>

        <div className="d-flex justify-content-end">
          <CopyToClipboard
            onCopy={toggleCopied}
            text={textContent}
          >
            <button className="btn-z border secondary text-dark" type="button">
              {copied ? <span className="text-success">Text Copied!</span> : <span>Copy Text</span>}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarEmbedCode;

CalendarEmbedCode.propTypes = {
  embedUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'hooks';
import ReactCrop, { centerCrop, makeAspectCrop, convertToPercentCrop } from 'react-image-crop';

const Cropper = ({
  src,
  initialCrop,
  aspectRatio,
  onChange,
}) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState();

  const setDefaultCrop = image => {
    const { width, height } = image;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspectRatio,
        width,
        height,
      ),
      width,
      height,
    );

    setCrop(crop);

    const percentCrop = convertToPercentCrop(crop, width, height);
    onChange(percentCrop);
  };

  const handleImageLoaded = evt => {
    const image = evt.currentTarget;
    setImage(image);
    if (initialCrop) {
      setCrop(initialCrop);
    } else {
      setDefaultCrop(image);
    }
  };

  const handleCropChange = (crop, percentCrop) => {
    setCrop(crop);
    onChange(percentCrop);
  };

  const handleCropComplete = crop => {
    // Reset to the default crop if no selection was made
    if (crop.width === 0 || crop.height === 0) {
      setDefaultCrop(image);
    }
  };

  const { width: containerWidth, ref: containerRef } = useResizeDetector({ handleHeight: false });
  if (!containerWidth) return <div ref={containerRef} />;

  let width = 'unset';
  if (image && image.height > image.width) {
    // For vertical images, limit the container width
    const nativeRatio = image.width / image.height;
    width = Math.floor(containerWidth * nativeRatio);
  } else if (image && image.width < containerWidth) {
    // Also for images with a native width smaller than the container
    width = image.width;
  }

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <ReactCrop
        crop={crop}
        aspect={aspectRatio}
        keepSelection
        onChange={handleCropChange}
        onComplete={handleCropComplete}
        style={{ width, display: 'block', margin: '0 auto' }}
      >
        <img src={src} onLoad={handleImageLoaded} crossOrigin="anonymous" />
      </ReactCrop>
    </div>
  );
};

Cropper.propTypes = {
  src: PropTypes.string,
  initialCrop: PropTypes.shape({
    unit: PropTypes.oneOf(['px', '%']),
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  aspectRatio: PropTypes.number,
  onChange: PropTypes.func,
};

export default Cropper;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'hooks';
import LoadingBubbles from 'components/LoadingBubbles';
import LocatorMap from './LocatorMap';

/**
 * Renders a static map image that adapts its size dynamically based
 * on the parent container width and the provided aspectRatio value.
 */
const ResponsiveLocatorMap = ({ aspectRatio = 1, ...rest }) => {
  let { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 500,
    refreshOptions: {
      leading: true,
      trailing: true,
    },
  });
  width = width && Math.round(width);

  // aspectRatio prop can be a function, allowing it to change
  // based on container width.
  if (typeof aspectRatio !== 'number') {
    aspectRatio = aspectRatio(width);
  }
  const height = width && Math.round(width / aspectRatio);

  const [mapImageLoaded, setMapImageLoaded] = useState(false);
  const handleMapImageLoad = () => {
    setTimeout(() => setMapImageLoaded(true), 300);
  };
  useEffect(() => setMapImageLoaded(false), [width, height, JSON.stringify(rest)]);

  const mapImageStyle = {
    transition: 'opacity .2s linear',
    opacity: mapImageLoaded ? 1 : 0,
  };

  const loadingBubblesStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div ref={ref} className="locator-map-container" style={{ height }}>
      {width && (
        <>
          <LocatorMap
            {...rest}
            width={width}
            height={height}
            style={mapImageStyle}
            onLoad={handleMapImageLoad}
          />
          {!mapImageLoaded && <LoadingBubbles style={loadingBubblesStyle} />}
        </>
      )}
    </div>
  );
};

ResponsiveLocatorMap.propTypes = {
  aspectRatio: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.func,
  ]),
  ...LocatorMap.propTypes,
};

export default ResponsiveLocatorMap;

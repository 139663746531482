import React from 'react';
import CircleButton from 'components/CircleButton';
import Icon from 'components/Icon';

const CircleButtonNext = props => (
  <CircleButton {...props}>
    <Icon i="chevron-right" style={{ fontSize: 14 }} />
  </CircleButton>
);

CircleButtonNext.propTypes = CircleButton.propTypes;

export default CircleButtonNext;

import React from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from 'context';

const LoginLink = ({ className, text = 'log in', redirectUrl }) => {
  const { setAuthRequested } = useUserContext();
  const handleClick = evt => {
    evt.preventDefault();
    setAuthRequested({ redirect: redirectUrl });
  };

  return <a href="#login" className={className} onClick={handleClick}>{text}</a>;
};

LoginLink.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  redirectUrl: PropTypes.string,
};

export default LoginLink;

import React from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

export default function useErrorOrHelpText (name, helpText, formMethods) {
  const { formState: { errors } } = formMethods || useFormContext();

  let error;
  try {
    // Allows access to errors on fields using `useFieldArray`, where the
    // name property is a string with dots separating indeces.
    error = name.split('.').reduce((obj, idx) => obj[idx], errors);
  } catch (e) {
    // noop
  }

  const errorOrHelpText = (
    <div className={classNames('z-form-hint-text', error && 'text-danger')}>
      {error ? error.message : helpText}
    </div>
  );

  return { errorOrHelpText, hasError: !!error };
}

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'themeColors';

/**
 * Renders a static map image using Mapbox's static API
 * https://docs.mapbox.com/playground/static/
 */
const LocatorMap = ({
  style = {},
  lat,
  lon,
  zoom = 14,
  width = 500,
  height = 500,
  mapStyle = 'light-v10',
  markerColor = colors.primary,
  mapboxToken,
  onLoad = () => {},
}) => {
  markerColor = markerColor.replace('#', '');
  const hiDpi = window.devicePixelRatio > 1;
  const url = 'https://api.mapbox.com/styles/v1/mapbox/' +
              `${mapStyle}/static/pin-l+${markerColor}(${lon},${lat})/${lon},${lat},${zoom}` +
              `/${width}x${height}${hiDpi ? '@2x' : ''}?access_token=${mapboxToken}`;
  return <img src={url} style={{ display: 'block', maxWidth: '100%', ...style }} onLoad={onLoad} />;
};

LocatorMap.propTypes = {
  style: PropTypes.object,
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  mapStyle: PropTypes.string,
  markerColor: PropTypes.string,
  mapboxToken: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default LocatorMap;

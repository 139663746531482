import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { format, parse, isBefore } from 'date-fns';

const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const PoiOpenHours = ({ poiData }) => {
  const { openTimes } = camelize(poiData);
  const now = new Date();

  return openTimes.map(({ dayOfWeek, closed, timeStart, timeEnd }, idx) => {
    const [timeStartAsDate, timeEndAsDate] = [timeStart, timeEnd].map(t => t && parse(t, 'HH:mm:ss', now));
    const openTimesString = timeStartAsDate && timeEndAsDate && `${format(timeStartAsDate, 'p')} – ${format(timeEndAsDate, 'p')}`.replace(/:00/g, '');

    const day = DAYS_OF_WEEK[dayOfWeek];
    const isToday = day === format(now, 'E');
    const isOpenNow = isToday && isBefore(timeStartAsDate, now) && isBefore(now, timeEndAsDate);
    const isSecondaryRange = !!openTimes[idx - 1] && openTimes[idx - 1].dayOfWeek === dayOfWeek;

    return (
      <div key={idx} className={classNames('poi-open-hours-row', isToday && 'current-day', isSecondaryRange && 'mt-n2')}>
        <div className="dow">{isSecondaryRange || day}</div>
        <div className="time">
          {closed ? 'CLOSED' : openTimesString}
        </div>
        {isOpenNow && <div className="text-success">Open Now!</div>}
      </div>
    );
  });
};

PoiOpenHours.propTypes = {
  poiData: PropTypes.shape({
    open_times: PropTypes.arrayOf(PropTypes.shape({
      day_of_week: PropTypes.number,
      closed: PropTypes.bool,
      /** Time formatted as HH:MM:SS (24-hour clock) */
      time_start: PropTypes.string,
      /** Time formatted as HH:MM:SS (24-hour clock) */
      time_end: PropTypes.string,
    })),
  }),
  /** IANA Time Zone ID */
  timeZone: PropTypes.string,
};

export default PoiOpenHours;

import React from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
// import Icon from 'components/Icon';
import StickyHeader from 'components/StickyHeader';
import BookmarkToggleButton from 'components/BookmarkToggleButton';

const PoiStickyHeader = ({ poiData }) => {
  const { id, name } = camelize(poiData);

  return (
    <StickyHeader>
      <div className="container h-100 d-flex align-items-center justify-content-between">
        <h6 className="fw-black m-0">{name}</h6>
        <div className="d-flex align-items-center">
          <BookmarkToggleButton
            className="ms-3"
            objectType="poi"
            objectId={id}
          />
        </div>
      </div>
    </StickyHeader>
  );
};

PoiStickyHeader.propTypes = {
  poiData: PropTypes.object.isRequired,
};

export default PoiStickyHeader;

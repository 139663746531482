import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import useErrorOrHelpText from './useErrorOrHelpText';

const ButtonChoiceField = ({
  name,
  choices = [],
  helpText,
  required = false,
  disabled = false,
  validate,
  registerOpts = {},
  formMethods,
}) => {
  const { register, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const val = watch(name) || '';

  const buttons = choices.map(choice => {
    const inputProps = register(name, {
      required: { value: required, message: 'This field is required.' },
      disabled,
      validate,
      ...registerOpts,
    });

    const [value, label] = Array.isArray(choice) ? choice : [choice, choice];

    const labelClasses = classNames({
      'z-form-choice-button': true,
      selected: val === value,
      'z-form-disabled': !!disabled,
      invalid: hasError,
    });

    return (
      <label key={value} className={labelClasses}>
        <input type="radio" value={value} {...inputProps} />
        {label}
      </label>
    );
  });

  return (
    <div>
      <div className="d-flex">{buttons}</div>
      <div className="z-form-hint-container" style={{ padding: 0 }}>
        {errorOrHelpText}
      </div>
    </div>
  );
};

ButtonChoiceField.propTypes = {
  name: PropTypes.string.isRequired,
  choices: PropTypes.array,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default ButtonChoiceField;

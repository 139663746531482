import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  isBefore,
  isValid,
  parse as parseDate,
  startOfDay,
} from 'date-fns';
import PillButton from 'components/PillButton';
import {
  TIME_FORMAT,
  TimeField,
} from 'components/formFields';
import useErrorOrHelpText from '../useErrorOrHelpText';
import WeekdaySelector from '../WeekdaySelector';

export const rowDefaults = {
  days: [],
  startTime: '',
  endTime: '',
};

const OpenTimesRow = ({ prefix, formMethods, onChange, onDelete }) => {
  const { watch, trigger, control } = formMethods;

  const today = useMemo(() => startOfDay(new Date()));

  const startTimeString = watch(`${prefix}.startTime`);
  const endTimeString = watch(`${prefix}.endTime`);

  const startTimeVal = useMemo(() => parseDate(startTimeString, TIME_FORMAT, today), [startTimeString]);
  const endTimeVal = useMemo(() => parseDate(endTimeString, TIME_FORMAT, today), [endTimeString]);

  const daysVal = watch(`${prefix}.days`);

  const validateEndTime = useCallback(value => {
    const valueAsDate = parseDate(value, TIME_FORMAT, today);

    if (isValid(startTimeVal) && isValid(valueAsDate) && isBefore(valueAsDate, startTimeVal)) {
      return 'Close time must be after open time.';
    }
    return true;
  }, [startTimeVal]);

  useEffect(() => {
    if (isValid(startTimeVal) && isValid(endTimeVal)) {
      trigger(`${prefix}.endTime`);
    }
  }, [startTimeVal]);

  useEffect(() => {
    trigger(`${prefix}.days`);
  }, [startTimeVal, endTimeVal]);

  useEffect(() => {
    if (daysVal && daysVal.length) {
      onChange();
    }
  }, [startTimeVal, endTimeVal, daysVal]);

  const validateDays = value => {
    if (isValid(startTimeVal) && isValid(endTimeVal) && value.length === 0) {
      return 'Select one or more days.';
    }
    return true;
  };

  const { errorOrHelpText: daysError } = useErrorOrHelpText(`${prefix}.days`, null, formMethods);

  return (
    <div className="row mb-3">
      <div className="col-12 col-sm-5">
        <div className="d-flex" style={{ height: 56, maxWidth: 270 }}>
          <Controller
            name={`${prefix}.days`}
            control={control}
            rules={{ validate: validateDays }}
            render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
              const handleChange = value => { onChange(value); onBlur(); };
              return <WeekdaySelector selectedDays={value} onChange={handleChange} />;
            }}
          />
        </div>
        <div className="z-form-hint-container" style={{ padding: 0 }}>
          {daysError}
        </div>
      </div>
      <div className="col-5 col-sm-3">
        <TimeField
          name={`${prefix}.startTime`}
          label="Open Time"
          required={!!daysVal.length}
          formMethods={formMethods}
        />
      </div>
      <div className="col-5 col-sm-3">
        <TimeField
          name={`${prefix}.endTime`}
          label="Close Time"
          validate={validateEndTime}
          required={!!daysVal.length}
          formMethods={formMethods}
        />
      </div>
      <div className="col-2 col-sm-1 d-flex justify-content-end">
        {!!onDelete && (
          <div className="d-flex align-items-center" style={{ height: 56 }}>
            <PillButton
              attrs={{ title: 'Remove' }}
              prependIcon={['far', 'minus']}
              className="danger sm"
              style={{ marginBottom: 4 }}
              onClick={onDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

OpenTimesRow.propTypes = {
  prefix: PropTypes.string,
  formMethods: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default OpenTimesRow;

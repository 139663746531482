import React, { useEffect } from 'react';
import { format as formatDate, parse as parseDate, isValid as isValidDate } from 'date-fns';
import { formatOrdinal, nthOfMonth } from 'utils';
import { DATE_FORMAT } from 'components/formFields';
import EndOption, { END_NONE } from './EndOption';
import useCustomRecurrenceControls, { CUSTOM_FREQ_WEEKLY, CUSTOM_MONTHLY_TYPE_DATE } from './useCustomRecurrenceControls';

export const FREQ_ONCE = 'once';
export const FREQ_DAILY = 'daily';
export const FREQ_WEEKLY = 'weekly';
export const FREQ_MONTHLY_DATE = 'monthlyByDate';
export const FREQ_MONTHLY_SET_POS = 'monthlyBySetPos';
export const FREQ_YEARLY = 'yearly';
export const FREQ_CUSTOM = 'custom';

export const endOptionDefaults = {
  endType: END_NONE,
  endDate: '',
  endCount: 1,
};

export const customDefaults = {
  customInterval: 1,
  customFrequency: CUSTOM_FREQ_WEEKLY,
  customMonthlyType: CUSTOM_MONTHLY_TYPE_DATE,
  customWeeklyDays: [],
};

export default function useFrequencyOptions (formMethods, prefix) {
  const { watch, setValue } = formMethods;
  const dateString = watch(`${prefix}.date`);
  const dateVal = parseDate(dateString, DATE_FORMAT, new Date());

  const activeOption = watch(`${prefix}.frequency`);

  // useEffect(() => {
  //   if (!dateString) setValue(`${prefix}.frequency`, FREQ_ONCE);
  // }, [dateString]);

  useEffect(() => {
    let fieldsToReset;
    if (activeOption === FREQ_ONCE) {
      fieldsToReset = { ...endOptionDefaults, ...customDefaults };
    } else if (activeOption !== FREQ_CUSTOM) {
      fieldsToReset = customDefaults;
    }

    if (fieldsToReset) {
      Object.entries(fieldsToReset).forEach(([key, val]) => {
        setValue(`${prefix}.${key}`, val);
      });
    }
  }, [activeOption]);

  const labels = {
    [FREQ_ONCE]: 'Once',
    [FREQ_DAILY]: 'Daily',
    [FREQ_WEEKLY]: 'Weekly',
    [FREQ_MONTHLY_DATE]: 'Monthly (by date)',
    [FREQ_MONTHLY_SET_POS]: 'Monthly (by day)',
    [FREQ_YEARLY]: 'Yearly',
    [FREQ_CUSTOM]: 'Custom',
  };

  if (isValidDate(dateVal)) {
    labels[FREQ_WEEKLY] += ' on ' + formatDate(dateVal, 'EEEE', new Date());
    labels[FREQ_MONTHLY_DATE] = 'Monthly on the ' + formatDate(dateVal, 'do', new Date());
    const [n, isLast] = nthOfMonth(dateVal);
    labels[FREQ_MONTHLY_SET_POS] = 'Monthly on the ' + `${isLast ? 'last' : formatOrdinal(n)} ${formatDate(dateVal, 'EEEE', new Date())}`;
    labels[FREQ_YEARLY] += ' on ' + formatDate(dateVal, 'MMMM do', new Date());
  }

  const options = [
    FREQ_ONCE,
    FREQ_DAILY,
    FREQ_WEEKLY,
    FREQ_MONTHLY_DATE,
    FREQ_MONTHLY_SET_POS,
    FREQ_YEARLY,
    FREQ_CUSTOM,
  ].map(val => [val, labels[val]]);

  const startDate = isValidDate(dateVal) ? dateVal : null;
  const { row1Controls, row2Controls } = useCustomRecurrenceControls(formMethods, prefix, startDate);

  let controls;
  let extraControls;

  if (activeOption !== FREQ_ONCE) {
    if (activeOption === FREQ_CUSTOM) {
      controls = row1Controls;
      extraControls = (
        <div className="row mb-3">
          {row2Controls}
          <EndOption formMethods={formMethods} prefix={prefix} startDate={startDate} />
        </div>
      );
    } else {
      controls = <EndOption formMethods={formMethods} prefix={prefix} startDate={startDate} />;
    }
  }

  return { options, controls, extraControls };
}

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBootstrapBreakpoint, useClickOutside, useToggle } from 'hooks';

const HelpPopup = ({ children }) => {
  const containerRef = useRef();
  const popupWrapRef = useRef();
  const popupContentRef = useRef();
  const [isActive, toggleIsActive] = useToggle();

  const handleClickOutside = () => toggleIsActive(false);
  useClickOutside(containerRef, handleClickOutside);

  const isMobile = useBootstrapBreakpoint('down-sm');
  const [leftOffset, setLeftOffset] = useState(null);
  useEffect(() => {
    if (isActive) {
      const { left: wrapperLeft } = popupWrapRef.current.getBoundingClientRect();
      const { left: contentLeft } = popupContentRef.current.getBoundingClientRect();
      if (isMobile || contentLeft < 0) {
        setLeftOffset(-(wrapperLeft - 10));
      } else {
        setLeftOffset(null);
      }
    } else {
      setTimeout(() => setLeftOffset(null), 300);
    }
  }, [isActive]);
  const popupStyle = leftOffset ? {
    right: 'unset',
    left: leftOffset,
  } : undefined;

  return (
    <div
      ref={containerRef}
      className={classNames('help-popup', isActive && 'active')}
    >
      <button
        type="button"
        className="help-popup-button"
        onClick={toggleIsActive}
      >
        <span>?</span>
      </button>
      <div ref={popupWrapRef} className="help-popup-content-wrap">
        <div ref={popupContentRef} className="help-popup-content" style={popupStyle}>{children}</div>
      </div>
    </div>
  );
};

HelpPopup.propTypes = {
  useInputStyle: PropTypes.bool,
  children: PropTypes.node,
};

export default HelpPopup;

import { useUserContext } from 'context';

export default function useAuthRequired (callback = () => {}) {
  const { id: userId, setAuthRequested } = useUserContext();
  if (userId) {
    return callback;
  } else {
    return () => setAuthRequested({ register: true });
  }
}

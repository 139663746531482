import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import queryString from 'query-string';
import { useFormContext } from 'react-hook-form';
import { urls } from 'app-constants';
import { usePrevious } from 'hooks';
import LoadingOverlay from 'components/LoadingOverlay';
import SimplePointMap from 'components/SimplePointMap';
import { TextField, CountryField, StateField, NumberField } from 'components/formFields';

const AddressField = ({
  className = '',
  mapboxToken,
  mapStyle = 'mapbox://styles/mapbox/light-v10',
  debugInfo,
  initialCoords = [],
  initialDataLoaded = true,
}) => {
  const { watch, register, getFieldState, resetField, setValue, trigger } = useFormContext();
  const fieldNames = ['street', 'city', 'state', 'postal_code', 'country'];
  const addressVals = watch(fieldNames).reduce((res, val, idx) => camelize({ ...res, [fieldNames[idx]]: val }), {});
  const coordVals = watch(['lon', 'lat']);

  const canGeocode = addressVals => {
    const requiredFieldNames = addressVals.country === 'US' ? ['city', 'state', 'country'] : ['city', 'country'];
    return requiredFieldNames.every(name => !!addressVals[name]) && fieldNames.every(name => !getFieldState(name).invalid);
  };

  const geocoderTimeout = useRef();
  const [isFetching, setIsFetching] = useState(false);
  const [geocodeError, setGeocodeError] = useState(null);
  const [coords, setCoords] = useState([]);
  const [showCoordinates, setShowCoordinates] = useState(false);

  const fetchGeocoderResult = addressVals => {
    const addressString = Object.values(addressVals).filter(v => !!v).join(', ');
    const query = queryString.stringify({ q: addressString, country: addressVals.country });
    const url = `${urls.geocodeAddress}?${query}`;
    fetch(url)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setIsFetching(false);
        setCoords([data.longitude, data.latitude]);
        setTimeout(() => trigger(['lat', 'lon']), 100);
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
        setCoords([]);
        setGeocodeError('Geocoding failed for the provided address.');
        setTimeout(() => trigger(['lat', 'lon']), 100);
      });
  };

  const doGeocode = addressVals => {
    clearTimeout(geocoderTimeout.current);
    if (canGeocode(addressVals)) {
      setGeocodeError(null);
      setIsFetching(true);
      geocoderTimeout.current = setTimeout(() => fetchGeocoderResult(addressVals), 1000);
    } else {
      setIsFetching(false);
    }
  };

  const prevAddressVals = usePrevious(addressVals);
  useEffect(() => {
    if (initialDataLoaded && prevAddressVals) {
      const hasChanged = Object.entries(addressVals).some(([key, val]) => prevAddressVals[key] !== val);
      if (hasChanged) doGeocode(addressVals);
    }
  }, [initialDataLoaded, JSON.stringify(addressVals)]);

  useEffect(() => {
    if (coordVals.length === 2 && coordVals.every(v => !!v)) {
      setCoords(coordVals);
    }
  }, [JSON.stringify(coordVals)]);

  useEffect(() => {
    resetField('state');
  }, [addressVals.country]);

  useEffect(() => {
    const [lon, lat] = coords;
    setValue('lon', lon || '');
    setValue('lat', lat || '');
  }, [coords]);

  const [lon, lat] = coords.length ? coords : initialCoords;

  const handleShowCoordinates = evt => {
    evt.preventDefault();
    setShowCoordinates(true);
  };

  let mapHint;
  if (geocodeError) {
    mapHint = (
      <div className="z-form-hint-text text-danger">{geocodeError}</div>
    );
  } else if (lat && lon) {
    const mailSubject = 'Incorrect Geocoded Address';
    let mailBody = `Submit this message to report an incorrect address geocoding result. Please include any information necessary to help us identify the correct map location.

Geocoder results are included below to assist our team in resolving the error. Please do not edit below this line.

----------
`;
    mailBody += [
      ...Object.entries(addressVals),
      ['latitude', lat],
      ['longitude', lon],
    ].map(([label, value]) => `${label}: ${value}`).join('\n');
    if (debugInfo) {
      mailBody += `\n\n${debugInfo}`;
    }
    mapHint = !showCoordinates && <div className="z-form-hint-text">Incorrect location? <a href="#override" onClick={handleShowCoordinates}>Override coordinates</a> or <a href={`mailto:support@zeitcaster.com?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(mailBody)}`}>contact support</a>.</div>;
  }

  return (
    <div className={className}>
      <div className="row mb-3">
        <TextField name="street" label="Street" />
      </div>

      <div className="row mb-3">
        <div className="col">
          <TextField name="city" label="City" required />
        </div>
        <div className="col">
          {addressVals.country === 'US' ? (
            <StateField name="state" label="State" required />
          ) : (
            <TextField name="state" label="State/Province" />
          )}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <TextField
            name="postal_code"
            label={`${addressVals.country === 'US' ? 'Zip' : 'Postal'} Code`}
          />
        </div>
        <div className="col">
          <CountryField
            name="country"
            label="Country"
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="z-form-address-map" style={{ position: 'relative', height: 200 }}>
            <SimplePointMap
              mapboxToken={mapboxToken}
              mapStyle={mapStyle}
              latitude={lat}
              longitude={lon}
              zoom={14}
              containerStyle={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
              }}
            />
            <LoadingOverlay show={isFetching} align="middle" />
          </div>

          <div className="z-form-hint-container" style={{ padding: 0 }}>
            {mapHint}
          </div>
        </div>
      </div>

      <div className="row mt-3" style={{ display: (showCoordinates || geocodeError) ? 'flex' : 'none' }}>
        <div className="col">
          <NumberField
            name="lat"
            label="Latitude"
            required
          />
        </div>
        <div className="col">
          <NumberField
            name="lon"
            label="Longitude"
            required
          />
        </div>
      </div>
    </div>
  );
};

AddressField.propTypes = {
  className: PropTypes.string,
  mapboxToken: PropTypes.string,
  mapStyle: PropTypes.string,
  debugInfo: PropTypes.string,
  initialCoords: PropTypes.arrayOf(PropTypes.number),
  initialDataLoaded: PropTypes.bool,
};

export default AddressField;

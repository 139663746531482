import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { SelectField } from 'components/formFields';
import PoiEditForm from 'components/PoiEditForm';

const VenueProfile = ({ poiChoices, ...rest }) => {
  const formMethods = useForm({ defaultValues: { activeVenue: poiChoices[0][0] } });
  const activeId = formMethods.watch('activeVenue');

  return (
    <>
      <div className="edit-form-container" style={{ paddingBottom: 0 }}>
        <FormProvider {...formMethods}>
          <SelectField
            name="activeVenue"
            label="Select a venue"
            required
            choices={poiChoices}
          />
        </FormProvider>
        <hr className="my-5" />
      </div>

      <PoiEditForm key={activeId} poiId={activeId} {...rest} />
    </>
  );
};

VenueProfile.propTypes = {
  poiChoices: PropTypes.arrayOf(PropTypes.array),
  regionChoices: PropTypes.arrayOf(PropTypes.array),
  timezoneChoices: PropTypes.arrayOf(PropTypes.array),
  mapboxToken: PropTypes.string,
  poiTypeChoices: PropTypes.arrayOf(PropTypes.array),
};

export default VenueProfile;


import React from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useHistory } from 'hooks';
import Icon from 'components/Icon';

const BackToResults = ({ className, text }) => {
  const { location: { queryParams } } = useHistory();
  const { search, widget } = queryParams || {};
  if (!search && !widget) return null;

  let href = widget ? urlJoin(urls.widgetEmbedBase, widget, '/') : urls.events;
  if (search) href += `?${search}`;

  if (!text) {
    text = `Back to ${widget ? 'Events' : 'Results'}`;
  }

  return (
    <a href={href} className={`reset-link d-flex align-items-center ${className}`}>
      <Icon i={['fal', 'long-arrow-left']} />
      <strong className="ms-2">{text}</strong>
    </a>
  );
};

BackToResults.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default BackToResults;

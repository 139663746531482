import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import ImageFieldWidget from 'components/ImageFieldWidget';

const ImageField = ({
  name,
  cropDataName,
  initialImage,
  initialCropData,
  label,
  helpText,
  required = false,
  validate,
  formMethods,
}) => {
  const { control } = formMethods || useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate,
        required,
      }}
      render={({ field: imageField, fieldState: { error } }) => (
        <Controller
          name={cropDataName}
          control={control}
          render={({ field: cropDataField }) => (
            <>
              <ImageFieldWidget
                label={label}
                hasError={!!error}
                imageUrl={initialImage}
                cropData={initialCropData}
                onChange={({ image, cropData }) => {
                  imageField.onChange(image);
                  cropDataField.onChange(cropData);
                }}
              />
              <div className="z-form-hint-container">
                {error && <div className="z-form-hint-text text-danger">{error.message}</div>}
              </div>
            </>
          )}
        />
      )}
    />
  );
};

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  cropDataName: PropTypes.string.isRequired,
  initialImage: PropTypes.string,
  initialCropData: PropTypes.object,
  label: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  formMethods: PropTypes.object,
};

export default ImageField;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useUserContext } from 'context';
import { useHistory, useWidgetId } from 'hooks';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import LoadingBubbles from 'components/LoadingBubbles';
import TruncateText from 'components/TruncateText';
import LoginView from 'components/LoginView';

const UserMenu = ({ initialIsAuthenticated = false, isSocialAuthEnabled = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(initialIsAuthenticated);
  const {
    authenticated,
    authRequested,
    setAuthRequested,
    isFetching: userDataFetching,
    firstName,
    username,
    isSuperuser,
    isStaff,
    isEditor,
    isZeditor,
    isProducer,
    id: userId,
  } = useUserContext();

  const { history, location } = useHistory();

  useEffect(() => {
    if (location.hash === '#register') {
      if (!isAuthenticated) setAuthRequested({ register: true });
      history.push({ hash: '' });
    }
  }, [location.hash, isAuthenticated]);

  useEffect(() => {
    if (typeof authenticated !== 'undefined') {
      setIsAuthenticated(authenticated);
    }
  }, [authenticated]);

  const labelText = firstName || <TruncateText text={username} numChars={15} />;

  // const loginUrl = `${urls.login}?next=${window.location.pathname}`;
  const logoutUrl = `${urls.logout}?next=${window.location.pathname}`;

  const widgetId = useWidgetId();

  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [isRegisterCTA, setIsRegisterCTA] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const handleLoginClick = evt => {
    evt.preventDefault();
    setLoginModalIsOpen(true);
  };
  const handleLoginModalClose = () => {
    setLoginModalIsOpen(false);
    setRedirectUrl(null);
  };

  useEffect(() => {
    if (authRequested) {
      if (typeof authRequested === 'object') {
        setIsRegisterCTA(authRequested.register || false);
        setRedirectUrl(authRequested.redirect);
      }
      setLoginModalIsOpen(true);
      setAuthRequested(null);
    }
  }, [authRequested]);

  useEffect(() => {
    if (isAuthenticated && redirectUrl) {
      window.location.href = redirectUrl;
    }
    if (isAuthenticated && loginModalIsOpen) {
      setLoginModalIsOpen(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!loginModalIsOpen) {
      setIsRegisterCTA(false);
    }
  }, [loginModalIsOpen]);

  const links = !userId ? [] : [
    {
      url: urls.account,
      label: 'Account Details',
      icon: 'circle-user',
      access: true,
    },
    {
      url: urls.savedAddresses,
      label: 'Address Book',
      icon: ['far', 'address-book'],
      access: true,
    },
    // {
    //   url: urls.widgets,
    //   label: 'Widgets',
    //   icon: 'cog',
    //   access: isSuperuser || isProducer,
    // },
    // {
    //   url: urls.addWidget,
    //   label: 'Add Widget',
    //   icon: 'cog',
    //   access: isSuperuser || isProducer,
    // },
    {
      url: urls.dashboard,
      label: 'Dashboard',
      icon: 'table-columns',
      access: isSuperuser || isEditor,
    },
    {
      url: urls.addEvent,
      label: 'Add Event',
      icon: ['far', 'calendar-circle-plus'],
      access: isSuperuser || isEditor,
    },
    {
      url: urls.addEventLegacy,
      label: 'Add Event - LEGACY',
      icon: ['far', 'calendar-circle-plus'],
      access: isSuperuser || isZeditor,
    },
    {
      url: urls.addPOI,
      label: 'Add POI',
      icon: 'location-plus',
      access: isSuperuser || isZeditor,
    },
    {
      url: urls.addPOILegacy,
      label: 'Add POI - LEGACY',
      icon: 'location-plus',
      access: isSuperuser || isZeditor,
    },
    {
      url: urlJoin(urls.editorProfileBase, userId.toString()),
      label: 'Editor Profile',
      icon: ['far', 'id-card'],
      access: isSuperuser || isZeditor,
    },
    {
      url: urls.editorTimesheets,
      label: 'Editor Timesheets',
      icon: 'business-time',
      access: isSuperuser || isZeditor,
    },
    {
      url: urls.userReport,
      label: 'User Report',
      icon: 'users-between-lines',
      access: isSuperuser,
    },
    {
      url: urls.editorReport,
      label: 'Editor Report',
      icon: 'users-between-lines',
      access: isSuperuser,
    },
    {
      url: urls.poiReportQA,
      label: 'POI QA Report',
      icon: 'location-check',
      access: isSuperuser || isZeditor,
    },
    {
      url: urls.wagtailAdmin,
      label: 'Wagtail CMS',
      icon: 'cog',
      access: isStaff,
      targetBlank: true,
    },
    {
      url: urls.djangoAdmin,
      label: 'Django Admin',
      icon: 'cog',
      access: isStaff,
      targetBlank: true,
    },
    {
      url: urls.lhReport,
      label: 'Lighthouse Report',
      icon: 'lighthouse',
      access: isStaff,
      targetBlank: true,
    },
    {
      url: urls.explorer,
      label: 'SQL Explorer',
      icon: 'database',
      access: isStaff,
      targetBlank: true,
    },
    {
      url: urls.sentry,
      label: 'Sentry',
      icon: 'database',
      access: isStaff,
      targetBlank: true,
    },
    {
      url: urls.awsConsole,
      label: 'AWS Console',
      icon: 'cog',
      access: isStaff,
      targetBlank: true,
    },
    {
      url: logoutUrl,
      label: 'Log Out',
      icon: ['far', 'sign-out'],
      access: true,
    },
  ].filter(item => item.access);

  return (
    <>
      {isAuthenticated ? (
        <DropdownButton
          text={userDataFetching ? <LoadingBubbles /> : labelText}
          menuAnchor="right"
          appendIcon="bars"
        >
          <div className="link-list py-4" style={{ width: 250 }}>
            {links.map(({ url, label, icon, targetBlank }) => {
              const linkAttrs = (widgetId || targetBlank) ? {
                target: '_blank',
                rel: 'noopener noreferrer',
              } : {};

              return (
                <a key={label} href={url} className="link-list-item px-5" {...linkAttrs}>
                  <Icon i={icon} />
                  <span>{label}</span>
                </a>
              );
            })}
          </div>
        </DropdownButton>
      ) : (
        <a className="btn-z sm clear" href="#log_in" onClick={handleLoginClick}>Log In</a>
      )}
      <LoginView
        modal={true}
        isOpen={loginModalIsOpen}
        defaultToRegister={isRegisterCTA}
        showPromoWrapper={isRegisterCTA}
        onRequestClose={handleLoginModalClose}
        isSocialAuthEnabled={isSocialAuthEnabled}
      />
    </>
  );
};

UserMenu.propTypes = {
  initialIsAuthenticated: PropTypes.bool, // Set true if the user is known to be already signed in when the component mounts
  isSocialAuthEnabled: PropTypes.bool,
};

export default UserMenu;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import queryString from 'query-string';
import urlJoin from 'url-join';
import {
  format,
  isValid,
  subDays,
  subYears,
} from 'date-fns';
import { urls } from 'app-constants';
import { hasOwnProperty } from 'utils';
import {
  QUERY_PARAM_FMT,
  DATE_RANGE_CHOICES,
  CHART_METRIC_CHOICES,
  CHART_UNIT_CHOICES,
} from './constants';
import Controls from './Controls';
import Chart from './Chart';

const WidgetMetrics = ({ widgetId }) => {
  const loadingOverlayTimeout = useRef();

  const [excludeHosts, setExcludeHosts] = useState([]);
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);
  const [dateRange, setDateRange] = useState(DATE_RANGE_CHOICES.lastSeven);
  const [customRangeStart, setCustomRangeStart] = useState(null);
  const [customRangeEnd, setCustomRangeEnd] = useState(null);
  const [dataStart, setDataStart] = useState(null);
  const [chartMetric, setChartMetric] = useState(CHART_METRIC_CHOICES.widgetImpressions);
  const [chartUnit, setChartUnit] = useState(CHART_UNIT_CHOICES.day);

  useEffect(() => {
    clearTimeout(loadingOverlayTimeout.current);
    if (isFetching) {
      // Display the loading overlay only after a brief timeout has elapsed
      loadingOverlayTimeout.current = setTimeout(() => setShowLoadingOverlay(true), 300);
    } else {
      setShowLoadingOverlay(false);
    }
  }, [isFetching]);

  useEffect(() => fetchData(), [excludeHosts]);

  useEffect(() => {
    if (!(dateRange === DATE_RANGE_CHOICES.customRange && (!customRangeStart || !customRangeEnd))) {
      fetchData();
    }
  }, [dateRange, customRangeStart, customRangeEnd]);

  useEffect(() => {
    if (data && hasOwnProperty(data, 'by_day')) {
      const allDateStrings = Object.keys(data.by_day);
      allDateStrings.sort();
      setDataStart(allDateStrings.length > 0 ? new Date(`${allDateStrings[0]} 00:00`) : null);
    }
  }, [data]);


  const { lastSeven, lastThirty, lastNinety, lastYear, allTime, customRange } = DATE_RANGE_CHOICES;
  const now = new Date();
  let startDate = null;
  let endDate = now;

  switch (dateRange) {
    case lastSeven:
      startDate = subDays(now, 6);
      break;
    case lastThirty:
      startDate = subDays(now, 29);
      break;
    case lastNinety:
      startDate = subDays(now, 89);
      break;
    case lastYear:
      startDate = subYears(now, 1);
      break;
    case allTime:
      // In this case, set the start date based on the earliest metrics
      // included in the actual data.
      startDate = dataStart;
      break;
    case customRange:
      if (customRangeStart && customRangeEnd) {
        startDate = customRangeStart;
        endDate = customRangeEnd;
      }
      break;
    default:
      break;
  }

  const fetchData = () => {
    const params = {};

    if (dateRange !== DATE_RANGE_CHOICES.allTime) {
      if (startDate) {
        params.start_date = format(startDate, QUERY_PARAM_FMT);
      }
      if (endDate) {
        params.end_date = format(endDate, QUERY_PARAM_FMT);
      }
    }

    if (excludeHosts.length) {
      params.exclude_hosts = excludeHosts.join(',');
    }

    const url = urlJoin(urls.widgetMetricsBase, widgetId.toString(), '?' + queryString.stringify(params));

    setIsFetching(true);
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setData(data);
        setIsFetching(false);
      })
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  const validateDateInput = val => {
    val = val ? new Date(`${val} 00:00`) : null;
    if (val && !isValid(val)) {
      val = null;
    }
    return val;
  };

  const handleCustomRangeStartChange = val => setCustomRangeStart(validateDateInput(val));
  const handleCustomRangeEndChange = val => setCustomRangeEnd(validateDateInput(val));

  const handleRowSelectionChange = host => {
    const newVal = excludeHosts.includes(host) ? excludeHosts.filter(d => d !== host) : [...excludeHosts, host];
    setExcludeHosts(newVal);
  };

  const tableRows = data ? Object.entries(data.by_host).map(([host, stats]) => {
    const { widgetHitCt, eventHitCt } = camelize(stats);
    const enabled = !excludeHosts.includes(host);
    return (
      <tr
        key={host}
        className={!enabled ? 'disabled' : ''}
        onClick={() => handleRowSelectionChange(host)}
      >
        <td>{host || '(unknown)'}</td>
        <td>{widgetHitCt}</td>
        <td>{eventHitCt}</td>
        <td className="text-end">
          <div className="form-check">
            <input
              value={host}
              className="form-check-input"
              type="checkbox"
              checked={enabled}
            />
          </div>
        </td>
      </tr>
    );
  }) : [];

  const { widgetHitCt, eventHitCt } = data ? camelize(data.summary) : {};

  return (
    <div className="widget-metrics-container">
      <div className="row mb-4">
        <div className="mb-3 mb-md-0 col-md-3 col-lg-2 d-flex flex-column">
          <div className="card p-2 text-center d-flex justify-content-center mb-3" style={{ flex: 1 }}>
            <h1 className="widget-hit-ct display-5 m-0">{widgetHitCt}</h1>
            <div className="small text-muted">Widget Impressions</div>
          </div>
          <div className="card p-2 text-center d-flex justify-content-center" style={{ flex: 1 }}>
            <h1 className="event-hit-ct display-5 m-0">{eventHitCt}</h1>
            <div className="small text-muted">Event Impressions</div>
          </div>
        </div>
        <div className="col-md-9 col-lg-10">
          <Controls
            dateRange={dateRange}
            chartMetric={chartMetric}
            chartUnit={chartUnit}
            customRangeStart={customRangeStart}
            customRangeEnd={customRangeEnd}
            startDate={startDate}
            endDate={endDate}
            onDateRangeChange={setDateRange}
            onCustomRangeStartChange={handleCustomRangeStartChange}
            onCustomRangeEndChange={handleCustomRangeEndChange}
            onMetricChange={setChartMetric}
            onUnitChange={setChartUnit}
          />
          <Chart
            startDate={startDate}
            endDate={endDate}
            chartMetric={chartMetric}
            chartUnit={chartUnit}
            data={data}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-container">
            <table className="by-host table table-bordered table-sm m-0">
              <thead>
                <tr>
                  <th>Origin Domain</th>
                  <th>Widget Impr.</th>
                  <th>Event Impr.</th>
                  <th className="text-center" width="70">Graph</th>
                </tr>
              </thead>
              <tbody>
                {tableRows}
              </tbody>
            </table>
          </div>
          <div className="text-end">
            <small className="text-muted">Traffic data is updated every five minutes.</small>
          </div>
        </div>
      </div>
      {showLoadingOverlay && (
        <div className="loading-overlay">
          <div className="loading-indicator" />
        </div>
      )}
    </div>
  );
};

WidgetMetrics.propTypes = {
  widgetId: PropTypes.number.isRequired,
};

export default WidgetMetrics;

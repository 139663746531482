import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'components/LoadingOverlay';

const MetricCards = ({ data, isFetching = false }) => (
  <div className="metric-card-container">
    <LoadingOverlay show={isFetching} className="bg-lt" />
    {(data || []).map(([label, value]) => (
      <div key={label} className="metric-card border">
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
    ))}
  </div>
);

MetricCards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  isFetching: PropTypes.bool,
};

export default MetricCards;

import React from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';

const ShowMoreText = ({ className, truncatedText = '', fullText = '', enableShowLess = false }) => {
  const [showAll, toggleShowAll] = useToggle(false);

  return (
    <>
      <div className={className || 'mb-4'} dangerouslySetInnerHTML={{ __html: showAll ? fullText : truncatedText }} />
      {(!showAll || enableShowLess) && (
        <div>
          <button type="button" className="btn-z subtle-nobg" onClick={() => toggleShowAll()}>
            Show {showAll ? 'Less' : 'More'}
          </button>
        </div>
      )}
    </>
  );
};

ShowMoreText.propTypes = {
  className: PropTypes.string,
  truncatedText: PropTypes.string,
  fullText: PropTypes.string,
  enableShowLess: PropTypes.bool,
};

export default ShowMoreText;

import { parseISO } from 'date-fns';
import { ONLINE, IN_PERSON } from './OnlineFilter';

/* eslint-disable camelcase */
export function parseFilterParams (params) {
  const { date_start, date_end, radius, event_types, is_online, is_in_person, age_groups, prices } = params;

  let dateValue = null;
  if (date_start && date_end) {
    dateValue = [
      parseISO(date_start),
      parseISO(date_end),
    ];
  }

  let onlineValue = null;
  if (is_online === 't' && !is_in_person) {
    onlineValue = ONLINE;
  } else if (is_in_person === 't' && !is_online) {
    onlineValue = IN_PERSON;
  }

  return {
    date: dateValue,
    distance: radius || null,
    category: event_types && event_types.length > 0 ? event_types : null,
    online: onlineValue,
    ageGroup: age_groups && age_groups.length > 0 ? age_groups : null,
    price: prices && prices.length > 0 ? prices : null,
  };
}
/* eslint-enable */

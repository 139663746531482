import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircleButtonNext from 'components/CircleButtonNext';
import CircleButtonPrev from 'components/CircleButtonPrev';

const Pagination = ({ curPage, numPages, delta = 2, onChange }) => {
  const numItems = delta * 2 + 1;
  let left = curPage - delta < 1 ? 1 : curPage - delta;
  let right = curPage + delta;
  if (right < numItems) right = numItems;
  if (right > numPages) right = numPages;

  // If we're at/near the last page, pad out the left side until we reach numItems
  while (left > 1 && right - left + 1 < numItems) left--;

  const pageNums = [];
  for (let i = left; i <= right; i++) {
    pageNums.push(i);
  }
  if (pageNums[0] > 1) pageNums.splice(0, 1, 1, null);
  if (pageNums[pageNums.length - 1] < numPages) pageNums.splice(-1, 1, null, numPages);

  const items = pageNums.map((p, i) => {
    return p === null
      ? <div key={i} className="pagination-separator">…</div>
      : <div key={i} className={classNames('pagination-item', p === curPage && 'active')} onClick={() => onChange(p)}>{p}</div>;
  });

  const handlePrevClick = curPage === 1 ? null : () => onChange(curPage - 1);
  const handleNextClick = curPage === numPages ? null : () => onChange(curPage + 1);

  return (
    <div className="pagination">
      <CircleButtonPrev style={{ margin: '0 4px' }} onClick={handlePrevClick} />
      {items}
      <CircleButtonNext style={{ margin: '0 4px' }} onClick={handleNextClick} />
    </div>
  );
};

Pagination.propTypes = {
  curPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  delta: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;

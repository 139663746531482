import React from 'react';
import PropTypes from 'prop-types';
import DotsNav from 'components/DotsNav';
import CircleButtonNext from 'components/CircleButtonNext';
import CircleButtonPrev from 'components/CircleButtonPrev';

const DotsPagination = ({ className, curPage, numPages, showNextPrevButtons = true, onChange }) => {
  const handlePrevClick = curPage === 1 ? null : () => onChange(curPage - 1);
  const handleNextClick = curPage === numPages ? null : () => onChange(curPage + 1);
  const handleDotClick = idx => onChange(idx + 1);

  return (
    <div className={`d-flex align-items-center justify-content-${showNextPrevButtons ? 'between' : 'center'} ${className || ''}`}>
      {showNextPrevButtons && <CircleButtonPrev onClick={handlePrevClick} />}
      <DotsNav count={numPages} activeIndex={curPage - 1} onClick={handleDotClick} />
      {showNextPrevButtons && <CircleButtonNext onClick={handleNextClick} />}
    </div>
  );
};

DotsPagination.propTypes = {
  className: PropTypes.string,
  curPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  showNextPrevButtons: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DotsPagination;

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faFacebook,
  faFacebookSquare,
  faGoogle,
  faTwitter,
  faTwitterSquare,
  faInstagram,
  faInstagramSquare,
  faWikipediaW,
  faYahoo,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCaretUp,
  faCaretDown,
  faCode as fasCode,
  faCog,
  faComment,
  faCompass,
  faBars,
  faBarcode,
  faBook,
  faBookmark as fasBookmark,
  faBusinessTime,
  faChalkboardTeacher,
  faCheckSquare,
  faChess,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChild,
  faCircleUser,
  faClock,
  faDatabase,
  faDollarSign,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilm,
  faGrinStars,
  faHandHoldingHeart,
  faHandsHeart,
  faLandmark,
  faLighthouse,
  faLaughBeam,
  faLink,
  faLocation,
  faLocationCheck,
  faLocationPlus,
  faLock,
  faMapMarkerAlt,
  faMusic,
  faPaintBrush,
  faPartyHorn,
  faPencilAlt,
  faPhoneAlt,
  faPray,
  faRunning,
  faSignalStream,
  faSearch,
  faSlidersH,
  faSmileBeam,
  faStar,
  faTableColumns,
  faTheaterMasks,
  faTicketSimple,
  faTrash,
  faTree,
  faTrophy,
  faUserMagnifyingGlass,
  faUsersBetweenLines,
  faUsers as fasUsers,
  faUtensils,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCalendar,
  faCalendarCirclePlus,
  faCheck,
  faCircleInfo,
  faGripHorizontal,
  faBookmark as farBookmark,
  faBuilding,
  faEarthAmericas,
  faEllipsisV,
  faHandHoldingHeart as farHandHoldingHeart,
  faIdCard,
  faPlus,
  faSignOut,
  faSquare,
  faTimes,
  faUser,
  faMinus,
  faMobile,
  faAddressBook,
  faDownload,
  faBoxOpen,
  faLocationCrosshairs,
  faMagnifyingGlassLocation,
  faMerge,
  faMoneyBillWave,
  faHandshake,
  faBinoculars,
  faPeopleGroup,
  faUsers as farUsers,
  faHeart,
  faSeedling,
  faScaleBalanced,
  faSync,
  faUserPlus,
  faLightbulbExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faEdit,
  faLongArrowLeft,
  faGopuram,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAttractions,
  faArrowNe,
  faCircle,
  faDotCircle,
  faEvents,
  faFilter,
  faImageUpload,
  faOffice365,
  faOpenStreetMap,
  faOutlook,
  faPrice,
  faSaved,
  faShare,
  faSort,
  faWidgets,
} from 'fontAwesomeCustomIcons';


library.add(
  faApple,
  faFacebook,
  faFacebookSquare,
  faGoogle,
  faTwitter,
  faTwitterSquare,
  faInstagram,
  faInstagramSquare,
  faWikipediaW,
  faYahoo,

  faCaretUp,
  faCaretDown,
  fasCode,
  faCog,
  faComment,
  faCompass,
  faBars,
  faBarcode,
  faBook,
  fasBookmark,
  faBusinessTime,
  faChalkboardTeacher,
  faCheckSquare,
  faChess,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChild,
  faCircleUser,
  faClock,
  faDatabase,
  faDollarSign,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFilm,
  faGrinStars,
  faHandHoldingHeart,
  faHandsHeart,
  faLandmark,
  faLighthouse,
  faLaughBeam,
  faLink,
  faLocation,
  faLocationCheck,
  faLocationPlus,
  faLock,
  faMapMarkerAlt,
  faMusic,
  faPaintBrush,
  faPartyHorn,
  faPencilAlt,
  faPhoneAlt,
  faPray,
  faRunning,
  faSignalStream,
  faSearch,
  faSlidersH,
  faSmileBeam,
  faStar,
  faTableColumns,
  faTheaterMasks,
  faTicketSimple,
  faTree,
  faTrash,
  faTrophy,
  faUserMagnifyingGlass,
  faUsersBetweenLines,
  fasUsers,
  faUtensils,
  faWrench,

  faCalendar,
  faCalendarCirclePlus,
  faCheck,
  faCircleInfo,
  faGripHorizontal,
  farBookmark,
  faBuilding,
  faEarthAmericas,
  faEllipsisV,
  farHandHoldingHeart,
  faIdCard,
  faPlus,
  faSignOut,
  faSquare,
  faTimes,
  faUser,
  faMinus,
  faMobile,
  faAddressBook,
  faDownload,
  faBoxOpen,
  faLocationCrosshairs,
  faMagnifyingGlassLocation,
  faMerge,
  faMoneyBillWave,
  faHandshake,
  faBinoculars,
  faPeopleGroup,
  farUsers,
  faHeart,
  faSeedling,
  faScaleBalanced,
  faSync,
  faUserPlus,
  faLightbulbExclamation,

  faEdit,
  faLongArrowLeft,
  faGopuram,

  faLinkedin,
  faAttractions,
  faArrowNe,
  faCircle,
  faDotCircle,
  faEvents,
  faFilter,
  faImageUpload,
  faOffice365,
  faOpenStreetMap,
  faOutlook,
  faPrice,
  faSaved,
  faShare,
  faSort,
  faWidgets,
);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/Icon';

const Checkbox = ({
  className,
  style = {},
  labelText,
  checked = false,
  onChange = () => {},
}) => (
  <label
    className={classNames('checkbox-label', className)}
    style={style}
    onClick={() => onChange(!checked)}
  >
    <Icon i={checked ? 'check-square' : ['far', 'square']} className={checked ? 'color-primary' : ''} />
    <span>{labelText}</span>
  </label>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  labelText: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;

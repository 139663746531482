import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import { TextField } from 'components/formFields';

const ProducerContactForm = ({ defaultEmail, defaultName }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const [apiValidationErrors, setApiValidationErrors] = useState({});
  const fieldValidator = fieldName => () => !apiValidationErrors[fieldName] || apiValidationErrors[fieldName].join(' ');

  const defaultValues = {
    name: defaultName || '',
    email: defaultEmail || '',
    message: '',
  };

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues,
  });

  const { formState: { errors: formErrors, isValid: formIsValid } } = formMethods;
  const formHasErrors = Object.keys(formErrors).length > 0;

  const clearForm = () => {
    formMethods.reset(defaultValues);
    setTimeout(() => formMethods.clearErrors(), 50);
  };

  useEffect(() => {
    if (Object.keys(apiValidationErrors).length > 0) {
      formMethods.trigger();
    }

    const subscription = formMethods.watch((value, { name, type }) => {
      if (apiValidationErrors[name]) {
        setApiValidationErrors(oldState => {
          const { [name]: _, ...rest } = oldState;
          return rest;
        });
        setTimeout(() => formMethods.trigger(name), 100);
      }
    });

    return () => subscription.unsubscribe();
  }, [JSON.stringify(apiValidationErrors)]);

  const onSubmit = data => {
    setApiValidationErrors({});
    setIsFetching(true);

    fetch(urls.apiProducerContactForm, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        setIsFetching(false);

        if (response.status === 400) {
          // validation errors
          response.json().then(setApiValidationErrors);
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          setDidSubmit(true);
          clearForm();
        }
        return response;
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="container-fluid" style={{ '--bs-gutter-x': 0 }}>
          <div className="row mb-4">
            {didSubmit
              ? <span><strong>Thank you!</strong> Your request has been submitted, and a member of the Zeitcaster team will be in touch with you shortly.</span>
              : <span>Please complete the form below to contact us if you need assistance with your account, or with any other questions or concerns about our service.</span>
            }
          </div>

          <div className="row mb-2">
            <div className="col">
              <TextField
                name="name"
                label="Your Name"
                required
                maxLength={1024}
                validate={fieldValidator('name')}
              />
            </div>
            <div className="col">
              <TextField
                name="email"
                label="Your Email"
                required
                maxLength={1024}
                validate={fieldValidator('email')}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <TextField
                name="message"
                label="Message"
                multiline
                validate={fieldValidator('message')}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center justify-content-end">
                {formHasErrors && <div className="text-sm text-danger me-2">Please correct form errors before submitting.</div>}
                <input type="submit" value="Submit" className="btn-z primary" disabled={isFetching || !formIsValid} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

ProducerContactForm.propTypes = {
  defaultEmail: PropTypes.string,
  defaultName: PropTypes.string,
};

export default ProducerContactForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import CardDeletionMenu from 'components/CardDeletionMenu';
import EventCard from 'components/EventCard';

const SavedEventCard = ({
  eventData,
  onDeleteRequest = () => {},
  onDeleteSuccess = () => {},
  onDeleteFailure = () => {},
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleting(true);

    fetch(`${urls.toggleSavedEventBase}${eventData.id}/`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'X-CSRFToken': getCsrfToken() },
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        if (data.saved) {
          setIsDeleting(false);
          onDeleteFailure();
        } else {
          onDeleteSuccess();
        }
      })
      .catch(err => {
        console.error(err);
        setIsDeleting(false);
        onDeleteFailure(err);
      });
  };

  const renderActionButtons = ({ shareButton, calendarButton }) => (
    <>
      {shareButton}
      {calendarButton}
      <CardDeletionMenu onDeleteClick={handleDeleteClick} />
    </>
  );

  return (
    <EventCard
      {...eventData}
      style={{ opacity: isDeleting ? 0.5 : 1 }}
      smallIcon
      hideShareButton={false}
      hideBookmarkButton
      hideCalendarButton={false}
      hideFooter
      renderActionButtons={renderActionButtons}
    />
  );
};

SavedEventCard.propTypes = {
  eventData: PropTypes.shape(EventCard.propTypes),
  onDeleteRequest: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default SavedEventCard;

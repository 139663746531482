import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import useErrorOrHelpText from './useErrorOrHelpText';

const CheckboxField = ({
  name,
  label,
  helpText,
  required = false,
  disabled = false,
  validate,
  registerOpts = {},
  formMethods,
}) => {
  const { register, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const val = watch(name) || '';

  const inputProps = register(name, {
    required: { value: required, message: 'This field is required.' },
    disabled,
    validate,
    ...registerOpts,
  });

  const labelClasses = classNames({
    'z-form-checkbox': true,
    checked: val,
    'z-form-disabled': !!disabled,
    invalid: hasError,
  });

  return (
    <div>
      <label className={labelClasses}>
        <input type="checkbox" {...inputProps} />
        {label}
      </label>
      <div className="z-form-hint-container" style={{ padding: 0 }}>
        {errorOrHelpText}
      </div>
    </div>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default CheckboxField;

import React from 'react';
import TextField from './TextField';
import { validateUrl } from './validation';

const URLField = props => {
  const { validate = () => true, ...rest } = props;
  const doValidate = value => validate(value) !== true ? validate(value) : validateUrl(value);

  return <TextField {...rest} validate={doValidate} />;
};

URLField.propTypes = TextField.propTypes;

export default URLField;

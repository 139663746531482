import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { useBootstrapBreakpoint, useGetAPI, useHistory } from 'hooks';
import { DISTANCE_UNITS_MI, DISTANCE_UNITS_KM } from 'components/filters';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import LoadingOverlay from 'components/LoadingOverlay';
import AddressBookCard from 'components/AddressBookCard';
import SavedItemsEmptyMessage from 'components/SavedItemsEmptyMessage';

const ORDER_UPDATED = '-updated';
const ORDER_CREATED = '-created';
const DEFAULT_ORDER = ORDER_UPDATED;

const AddressBook = ({ emptyImgUrl, distanceUnits = DISTANCE_UNITS_MI }) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();
  const { ordering = DEFAULT_ORDER } = queryParams || {};

  // Call API
  let path;
  if (typeof queryParams !== 'undefined') path = urls.savedAddressList;
  const apiParams = { ordering };
  const { data: searchData, isFetching, error } = useGetAPI(path, { queryObject: apiParams, fullPath: true });

  // Data cached in local state so we can update the UI immediately when an item is deleted.
  const [cachedSearchData, setCachedSearchData] = useState();
  useEffect(() => setCachedSearchData(searchData), [searchData]);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleDeleteFailure = errorText => setErrorMessage(`Failed to delete item: ${errorText || 'unknown error'}`);
  const handleDeleteSuccess = searchId => setCachedSearchData(oldState => oldState.filter(({ id }) => id !== searchId));

  const handleRenameFailure = errorText => setErrorMessage(`Failed to rename item: ${errorText || 'unknown error'}`);
  const handleRenameSuccess = (searchId, searchName) => setCachedSearchData(oldState => oldState.map(item => {
    return item.id === searchId ? { ...item, searchName } : item;
  }));

  const orderingMenuRef = useRef();
  const handleOrderChange = (evt, ordering) => {
    evt.preventDefault();
    updateQueryParams({ ordering });
    orderingMenuRef.current.close();
  };

  // Reset scroll position when fetching new data
  useEffect(() => window.scrollTo(0, 0), [isFetching]);

  const isMobile = useBootstrapBreakpoint('down-md');

  const countLabel = !cachedSearchData ? 'My' : cachedSearchData.length;

  const orderingButtonLabel = isMobile ? null : (
    <>
      <strong className="text-light-alt">Sort by: </strong>
      <strong>{ordering === ORDER_UPDATED ? 'Last edited' : 'Last added'}</strong>
    </>
  );

  const headingTitle = 'Saved Address';

  return (
    <div className="listings-container">
      <LoadingOverlay show={isFetching} align="top" className="bg-lt" />
      {cachedSearchData && cachedSearchData.length === 0 ? (
        <SavedItemsEmptyMessage
          imgUrl={emptyImgUrl}
          primaryText="Save your custom addresses for use in searches."
          secondaryText="Your Address Book is empty."
          buttonText="New Address"
          buttonUrl={urls.addAddress}
        />
      ) : (
        <>
          <header>
            {!isMobile && <h2 className="lh-base m-0 fw-black">{countLabel} {headingTitle}{countLabel !== 1 && 'es'}</h2>}
            <div className={classNames('d-flex', 'mb-1', 'align-items-baseline', isMobile ? 'justify-content-between' : 'justify-content-end')}>
              {isMobile && (
                <h4 className="lh-base m-0 fw-black">
                  {countLabel} {headingTitle}{countLabel !== 1 && 'es'}
                </h4>
              )}
              <DropdownButton
                ref={orderingMenuRef}
                text={orderingButtonLabel}
                appendIcon={isMobile ? <Icon i={['faz', 'sort']} /> : undefined}
                buttonClassName={classNames('clear', 'subtle-focus', 'borderless', isMobile && 'circle')}
                menuAnchor="right"
              >
                <div className="link-list py-3" style={{ width: 160 }}>
                  <a
                    href="#edited"
                    className={classNames('link-list-item', 'px-3', ordering === ORDER_UPDATED && 'color-primary')}
                    onClick={evt => handleOrderChange(evt, ORDER_UPDATED)}
                  >
                    <Icon i={['far', 'check']} style={{ visibility: ordering === ORDER_UPDATED ? 'visible' : 'hidden' }} />
                    <span>Last edited</span>
                  </a>

                  <a
                    href="#added"
                    className={classNames('link-list-item', 'px-3', ordering === ORDER_CREATED && 'color-primary')}
                    onClick={evt => handleOrderChange(evt, ORDER_CREATED)}
                  >
                    <Icon i={['far', 'check']} style={{ visibility: ordering === ORDER_CREATED ? 'visible' : 'hidden' }} />
                    <span>Last added</span>
                  </a>
                </div>
              </DropdownButton>
            </div>

            {!!error && (
              <div className="alert alert-danger mb-3" role="alert">
                Failed to fetch results. Please try your request again.
              </div>
            )}

            {!!errorMessage && (
              <div className="alert alert-danger mb-3" role="alert">
                {errorMessage}
              </div>
            )}
          </header>

          {(cachedSearchData || []).map(savedSearch => (
            <div key={savedSearch.id} className="mb-3">
              <AddressBookCard
                {...savedSearch}
                useCompactStyle={isMobile}
                metaLabel={ordering === ORDER_CREATED ? 'created' : 'updated'}
                onDeleteFailure={handleDeleteFailure}
                onDeleteSuccess={() => handleDeleteSuccess(savedSearch.id)}
                onRenameFailure={handleRenameFailure}
                onRenameSuccess={name => handleRenameSuccess(savedSearch.id, name)}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

AddressBook.propTypes = {
  emptyImgUrl: PropTypes.string,
  distanceUnits: PropTypes.oneOf([DISTANCE_UNITS_MI, DISTANCE_UNITS_KM]),
};

export default AddressBook;

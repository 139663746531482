import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { hasSameContents } from 'utils';
import { useGetAPI } from 'hooks';
import { useFilterContext } from 'context';
import { typeIcons } from 'app-constants';
import CategoryList from 'components/CategoryList';
import Checkbox from 'components/Checkbox';
import DropdownButton from 'components/DropdownButton';

const CategoryFilter = ({ useMobileStyle = false, value, onChange }) => {
  const { data: categoriesData, isFetching, error } = useGetAPI('event_types');

  value = value || [];

  const options = useMemo(() => {
    return (categoriesData || []).map(({ id, name }) => ({
      value: id,
      label: name,
      iconStyle: typeIcons[name] || typeIcons.default,
    }));
  }, [categoriesData]);

  useEffect(() => {
    if (error) console.error(error);
    // TODO - logging
  }, [error]);

  const menuRef = useRef();
  const [internalValue, setInternalValue] = useState(value);

  const valueAsString = value.reduce((result, item) => `${result}${item}`, '');
  useEffect(() => {
    if (!hasSameContents(value, internalValue)) {
      setInternalValue(value);
    }
  }, [valueAsString]);

  const handleMenuClose = () => onChange(internalValue);
  const handleClearClick = () => setInternalValue([]);
  const handleApplyClick = () => menuRef.current.close();
  const handleSelectAllChange = checked => setInternalValue(checked ? options.map(({ value }) => value) : []);

  let activeOptionLabel = 'Any Category';
  if (value.length > 0) {
    const matchedOption = options.find(({ value: val }) => val === value[0]);
    if (matchedOption) {
      activeOptionLabel = matchedOption.label;
      if (value.length > 1) activeOptionLabel += ` +${value.length - 1}`;
    }
  }

  const { updateFilterContext } = useFilterContext();
  const activeCategoryOptions = options.filter(opt => value.includes(opt.value));
  const activeCategoryIds = activeCategoryOptions.map(opt => opt.value);
  const activeCategoryNames = activeCategoryOptions.map(opt => opt.label);
  useEffect(() => {
    updateFilterContext({ categoryNames: activeCategoryNames, categoryIds: activeCategoryIds });
  }, [JSON.stringify(activeCategoryIds)]);

  return useMobileStyle ? (
    <CategoryList
      options={options}
      value={value}
      onChange={onChange}
    />
  ) : (
    <DropdownButton
      ref={menuRef}
      className="me-2"
      text={activeOptionLabel || ''}
      isDisabled={isFetching}
      isHighlighted={value.length > 0}
      closeTimeout={0}
      onClose={handleMenuClose}
    >
      <div className="px-5 py-4" style={{ width: 1000 }}>
        <CategoryList options={options} value={internalValue} onChange={setInternalValue} />
      </div>
      <div className="px-5 py-4 border-top d-flex align-items-center justify-content-between">
        <div>
          <Checkbox
            labelText="Select All"
            checked={internalValue.length === options.length}
            onChange={handleSelectAllChange}
          />
        </div>
        <div>
          <button type="button" className="btn-z subtle me-2" onClick={handleClearClick}>Clear</button>
          <button type="button" className="btn-z primary" onClick={handleApplyClick}>Apply</button>
        </div>
      </div>
    </DropdownButton>
  );
};

CategoryFilter.propTypes = {
  useMobileStyle: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default CategoryFilter;

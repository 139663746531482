import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import striptags from 'striptags';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useSwipeable } from 'react-swipeable';
import { supportsTouch } from 'utils';
import { useBootstrapBreakpoint } from 'hooks';
import DotsPagination from 'components/DotsPagination';
import AddToCalendar from 'components/AddToCalendar';

const EventDatesList = ({
  dates = [],
  poiTimezone,
  usePoiTimezone = true,
  pageSize = 3,
  eventData,
  poiAddress,
}) => {
  const datesParsed = useMemo(() => dates.map(({ start, end }) => ({
    start: usePoiTimezone ? utcToZonedTime(parseISO(start), poiTimezone) : parseISO(start),
    end: end && (usePoiTimezone ? utcToZonedTime(parseISO(end), poiTimezone) : parseISO(end)),
  })), [JSON.stringify(dates), usePoiTimezone, poiTimezone]);

  const datesByPage = datesParsed.reduce((result, d, idx) => {
    if (idx % pageSize === 0) result.push([]);
    result[result.length - 1].push(d);
    return result;
  }, []);
  const numPages = datesByPage.length;

  const formatOpts = usePoiTimezone ? { timeZone: poiTimezone } : null;
  const formatDate = start => format(start, 'E, MMMM d', formatOpts);
  const formatTime = (start, end) => {
    let text = format(start, 'p', formatOpts);
    if (end) text += ' – ' + format(end, 'p', formatOpts);
    text += ' ' + format(start, 'z', formatOpts);
    return text;
  };

  const [activePage, setActivePage] = useState(1);

  const handleNext = () => {
    if (activePage < numPages) {
      setActivePage(activePage + 1);
    }
  };
  const handlePrev = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };
  const touchHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
  });

  const isMobile = useBootstrapBreakpoint('down-sm');
  const leftOffset = isMobile
    ? `calc(${(activePage - 1) * 100 * -1}% - ${(activePage - 1) * -30}px)`
    : `${(activePage - 1) * 100 * -1}%`;

  let { name, description, absoluteUrl, pointOfInterest } = camelize(eventData);
  const url = `${window.location.protocol}//${window.location.host}${absoluteUrl}`;
  description = striptags(description).trim();
  if (description.length > 500) {
    description = description.substring(0, 500) + '…';
  }
  description = [description, url].filter(s => !!s).join('\n');

  return (
    <div>
      <div className="event-dates-list-pages-wrap mb-3" {...touchHandlers}>
        <div className="event-dates-list-pages-container" style={{ left: leftOffset }}>
          {datesByPage.map((dates, idx) => (
            <div key={idx} className="event-dates-list-page">
              {dates.map(({ start, end }) => (
                <div key={start} className="mb-2 px-3 py-2 bg-white rounded">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <div className="small fw-bold">{formatDate(start)}</div>
                      <div className="small fw-bold">{formatTime(start, end)}</div>
                    </div>
                    <AddToCalendar
                      title={name}
                      description={description}
                      location={`${pointOfInterest.name}, ${poiAddress}`}
                      startDate={start}
                      endDate={end}
                      eventId={eventData.id}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {numPages > 1 && (
        <DotsPagination
          showNextPrevButtons={!supportsTouch()}
          curPage={activePage}
          numPages={numPages}
          onChange={setActivePage}
        />
      )}
    </div>
  );
};

EventDatesList.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.shape({
    /** ISO date string */
    start: PropTypes.string,
    /** ISO date string */
    end: PropTypes.string,
  })),
  /** IANA Time Zone ID */
  poiTimezone: PropTypes.string,
  /** If true, display times in the POI's timezone, rather than the client's */
  usePoiTimezone: PropTypes.bool,
  /** Number of items per page */
  pageSize: PropTypes.number,
  /** All event data - needed by AddToCalendar component */
  eventData: PropTypes.object,
  poiAddress: PropTypes.string,
};

export default EventDatesList;

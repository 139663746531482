import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import colors from 'themeColors';
import { format as formatDate, isAfter, isBefore, isValid, isSameDay, parse as parseDate } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import { useClickOutside, usePrevious, useToggle } from 'hooks';
import HelpPopup from 'components/HelpPopup';
import useErrorOrHelpText from './useErrorOrHelpText';
import ClearButton from './ClearButton';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const parseDateFieldValue = (val, date = new Date()) => parseDate(val, DATE_FORMAT, date);
export const formatDateFieldValue = val => formatDate(val, DATE_FORMAT);
export const reformatISO = val => {
  // Reformat ISO date string, ignoring timezone
  const [, y, m, d] = val.match(/^(\d{4})-(\d{2})-(\d{2})/);
  return `${m}/${d}/${y}`;
};

const DateField = ({
  name,
  label,
  helpText,
  helpPopupContent,
  required = false,
  disabled = false,
  minDate,
  maxDate,
  validate = () => true,
  registerOpts = {},
  formMethods,
}) => {
  const { register, watch, setValue, resetField } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const val = watch(name) || '';
  const setVal = (value, opts) => setValue(name, value, opts);
  const prevVal = usePrevious(val);
  let dateVal = parseDateFieldValue(val);
  if (!isValid(dateVal)) dateVal = null;

  useEffect(() => {
    if ([2, 5].includes(val.length) && !prevVal.endsWith('/')) {
      setVal(val + '/');
    }

    if (val.endsWith('//')) {
      setVal(val.slice(0, -1));
    }
  }, [val]);

  const autoCorrect = value => {
    const parts = value.split('/');
    if (parts.length !== 3) {
      return 'Enter a valid date.';
    }
    let [m, d, y] = parts;
    if (m.length === 1) m = '0' + m;
    if (d.length === 1) d = '0' + d;
    if (y.length === 2) y = (new Date().getFullYear()).toString().substring(0, 2) + y;
    const validString = [m, d, y].join('/');
    if (validString !== value) {
      setVal(validString, { shouldValidate: true });
    }
  };

  const doValidate = value => {
    if (value) {
      if (value.length !== 10) {
        return 'Enter a valid date in MM/DD/YYYY format.';
      }

      const today = new Date();
      const dateVal = parseDateFieldValue(value);
      if (!isValid(dateVal)) {
        return 'Enter a valid date.';
      }
      if (minDate && isBefore(dateVal, minDate)) {
        const txt = isSameDay(today, minDate) ? 'today' : formatDateFieldValue(minDate);
        return `Date must not be earlier than ${txt}.`;
      }
      if (maxDate && isAfter(dateVal, maxDate)) {
        const txt = isSameDay(today, maxDate) ? 'today' : formatDateFieldValue(maxDate);
        return `Date must not be later than ${txt}.`;
      }
    }

    return validate(value);
  };

  const { onBlur, ...inputProps } = register(name, {
    required: { value: required, message: 'This field is required.' },
    disabled,
    validate: doValidate,
    ...registerOpts,
  });

  const containerRef = useRef();
  const [calendarVisible, toggleCalendarVisible] = useToggle(false);
  const handleClickOutside = evt => {
    if (calendarVisible) toggleCalendarVisible();
  };
  useClickOutside(containerRef, handleClickOutside);

  const handleCalendarChange = date => {
    setVal(formatDateFieldValue(date), { shouldValidate: true });
    setTimeout(toggleCalendarVisible, 300);
  };


  const [isFocused, toggleFocused] = useToggle(false);
  const [blurEvent, setBlurEvent] = useState(null);
  const blurTimeout = useRef();
  const handleFocus = () => {
    clearTimeout(blurTimeout.current);
    toggleFocused(true);
    toggleCalendarVisible(true);
  };
  const handleBlur = evt => {
    setBlurEvent(evt);
    autoCorrect(val);
  };

  useEffect(() => {
    if (!calendarVisible && blurEvent) {
      blurTimeout.current = setTimeout(() => {
        toggleFocused(false);
        onBlur(blurEvent);
      }, 300);
    }
  }, [calendarVisible]);

  const handleClearClick = () => resetField(name);

  const wrapperClasses = classNames({
    'z-form-input-wrap': true,
    focused: isFocused,
    required,
    'has-val': !!val,
    invalid: hasError,
  });

  return (
    <div className={wrapperClasses} ref={containerRef}>
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          placeholder={isFocused ? DATE_FORMAT.toUpperCase() : ''}
          className={classNames('z-form-input', disabled && 'z-form-disabled')}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...inputProps}
        />
        {label && (
          <label className={classNames('z-form-input-label', disabled && 'z-form-disabled')}>
            {label}
          </label>
        )}
        {!!val && <ClearButton onClick={handleClearClick} />}
        {!!helpPopupContent && <HelpPopup>{helpPopupContent}</HelpPopup>}
        <div
          className="z-form-calendar-wrap"
          style={{ display: calendarVisible ? 'block' : 'none' }}
        >
          <Calendar
            color={colors.primary}
            date={dateVal}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleCalendarChange}
          />
        </div>
      </div>
      <div className="z-form-hint-container">
        {errorOrHelpText}
      </div>
    </div>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  helpPopupContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default DateField;

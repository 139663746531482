import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import { getCsrfToken, messages } from 'utils';
import { useForm, FormProvider } from 'react-hook-form';
import { AddressField, TextField } from 'components/formFields';
import PillButton from 'components/PillButton';
import LoadingOverlay from 'components/LoadingOverlay';

const AddressForm = ({ addressId, mapboxToken }) => {
  const defaultValues = {
    address_name: '',
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'US',
    lat: '',
    lon: '',
  };

  const [isFetching, setIsFetching] = useState(false);
  const [addressData, setAddressData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [initialDataLoaded, setInitialDataLoaded] = useState(!addressId);

  const fetchData = () => {
    if (!addressId) return null;
    setIsFetching(true);
    const url = `${urls.savedAddressBase}${addressId}/`;
    fetch(url, { credentials: 'include' })
      .then(response => {
        setIsFetching(false);
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(setAddressData)
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  useEffect(() => {
    if (addressId) fetchData();
  }, [addressId]);

  useEffect(() => {
    formMethods.reset(addressData);
    setTimeout(() => setInitialDataLoaded(true), 1000);
  }, [addressData]);

  const saveData = payload => {
    let url = urls.savedAddressBase;
    if (addressId) url += `${addressId}/`;

    fetch(url, {
      credentials: 'include',
      method: addressId ? 'PUT' : 'POST',
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        setIsFetching(false);

        if (response.status === 400) {
          // validation errors
          response.json().then(setFieldErrors);
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            const msg = addressId
              ? `Successfully updated "${data.address_name}".`
              : `Successfully saved "${data.address_name}" to your Address Book.`;

            messages.add(messages.success, msg, () => window.location.href = urls.savedAddresses);
          });
        }
        return response;
      })
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  const handleDeleteClick = () => {
    if (!addressId) return null;
    setIsFetching(true);
    const url = `${urls.savedAddressBase}${addressId}/`;
    fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'X-CSRFToken': getCsrfToken() },
    })
      .then(response => {
        setIsFetching(false);
        if (!response.ok) throw new Error(response.statusText);
        const name = addressData.address_name && `"${addressData.address_name}"`;
        const msg = `${name || 'Your Address Book entry'} was deleted successfully.`;
        messages.add(messages.warning, msg, () => window.location.href = urls.savedAddresses);
      })
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues,
  });

  const onSubmit = data => {
    setFieldErrors({});
    saveData(data);
  };

  const errorLines = Object.entries(fieldErrors).reduce((result, [fieldName, errMsg]) => {
    result.push(<div><strong>{fieldName}:</strong> {errMsg}</div>);
    return result;
  }, []);

  return (
    <FormProvider {...formMethods}>
      {errorLines.length > 0 && (
        <div className="alert alert-danger mb-4">
          {errorLines}
        </div>
      )}
      <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ position: 'relative' }}>
        <LoadingOverlay show={isFetching} align="top" className="bg-lt" />
        <div className="row mb-2">
          <div className="col-7">
            <TextField
              name="address_name"
              label="Name"
              required
              maxLength={24}
            />
          </div>
        </div>
        <div className="mb-5">
          <AddressField
            mapboxToken={mapboxToken}
            debugInfo={addressId && `Address: ${addressId}`}
            initialDataLoaded={initialDataLoaded}
            initialCoords={[addressData.lon, addressData.lat]}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <button type="submit" className="btn-z primary" disabled={isFetching}>Save</button>
            <a href={urls.savedAddresses} className="btn-z outline ms-2">Cancel</a>
          </div>
          {!!addressId && <PillButton className="danger" prependIcon="trash" text="Delete" onClick={handleDeleteClick} />}
        </div>
      </form>
    </FormProvider>
  );
};

AddressForm.propTypes = {
  addressId: PropTypes.number,
  mapboxToken: PropTypes.string,
};

export default AddressForm;

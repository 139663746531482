import React from 'react';
import { typeIcons } from 'app-constants';
import Icon from 'components/Icon';

const TypeIconsDemo = () => (
  <table className="table table-bordered">
    <tbody>
      {Object.entries(typeIcons).map(([label, iconType]) => (
        <tr key={label}>
          <td style={{ width: 30 }}>
            <Icon i={iconType} />
          </td>
          <td className="me-2"><strong className="ms-2">{label}</strong></td>
          <td className="lh-sm">
            HTML: <code>&lt;i data-component="Icon" data-i='{JSON.stringify(iconType)}'&gt;&lt;/i&gt;</code><br />
            React: <code>&lt;Icon i=&#123;{JSON.stringify(iconType).replace(/"/g, '\'')}&#125; /&gt;</code>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default TypeIconsDemo;

export const validateUrl = value => {
  if (!value) return true;

  try {
    const u = new URL(value);
    if (!['http:', 'https:'].includes(u.protocol)) {
      return 'URL must begin with "http:" or "https:"';
    }
    return true;
  } catch (err) {
    return 'Enter a valid URL.';
  }
};

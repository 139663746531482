import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useToggle, useClickOutside, usePrevious } from 'hooks';
import Icon from 'components/Icon';
import PillButton from 'components/PillButton';

const MENU_ANCHOR_LEFT = 'left';
const MENU_ANCHOR_RIGHT = 'right';
const MENU_ANCHOR_BOTTOM_LEFT = 'bottomLeft';
const MENU_ANCHOR_BOTTOM_RIGHT = 'bottomRight';
export const MENU_ANCHOR_OPTS = [
  MENU_ANCHOR_LEFT,
  MENU_ANCHOR_RIGHT,
  MENU_ANCHOR_BOTTOM_LEFT,
  MENU_ANCHOR_BOTTOM_RIGHT,
];

const DropdownButton = forwardRef(({
  text,
  className = '',
  buttonClassName = '',
  buttonStyle,
  maxHeight,
  prependIcon,
  appendIcon,
  isOpen = false,
  isHighlighted = false,
  isDisabled = false,
  menuAnchor = MENU_ANCHOR_LEFT,
  usePortal = false,
  closeOnChanged,
  closeTimeout = 200,
  onClose = () => {},
  children,
}, ref) => {
  const containerRef = useRef();
  const [menuVisible, toggleMenuVisible] = useToggle(isOpen);

  const [containerRect, setContainerRect] = useState({});

  useEffect(() => toggleMenuVisible(isOpen), [isOpen]);

  const handleClickOutside = () => toggleMenuVisible(false);
  useClickOutside(containerRef, handleClickOutside);

  const timeoutRef = useRef();
  const close = () => {
    timeoutRef.current = setTimeout(() => toggleMenuVisible(false), closeTimeout);
  };

  useEffect(() => {
    close();
    return () => clearTimeout(timeoutRef.current);
  }, [closeOnChanged]);

  const prevVisible = usePrevious(menuVisible);
  useEffect(() => {
    if (menuVisible) {
      const { bottom, left, width } = containerRef.current.getBoundingClientRect();
      setContainerRect({ bottom, left, width });
    } else if (prevVisible) {
      onClose();
    }
  }, [menuVisible]);

  useImperativeHandle(ref, () => ({ close }));

  const positionerStyle = {
    height: 1,
    left: containerRect.left,
    position: 'absolute',
    top: (containerRect.bottom || 0) + (window.pageYOffset || 0),
    width: containerRect.width,
  };

  const menuStyle = {
    display: menuVisible ? 'block' : 'none',
    position: 'absolute',
    maxHeight,
    overflow: 'auto',
    zIndex: 20,
  };
  switch (menuAnchor) {
    case MENU_ANCHOR_LEFT:
      menuStyle.left = 0;
      menuStyle.top = 'calc(100% + 10px)';
      break;
    case MENU_ANCHOR_RIGHT:
      menuStyle.right = 0;
      menuStyle.top = 'calc(100% + 10px)';
      break;
    case MENU_ANCHOR_BOTTOM_LEFT:
      menuStyle.left = 0;
      menuStyle.bottom = 'calc(100% + 10px)';
      break;
    case MENU_ANCHOR_BOTTOM_RIGHT:
      menuStyle.right = 0;
      menuStyle.bottom = 'calc(100% + 10px)';
      break;
    default:
      break;
  }

  const defaultIcon = <Icon i="chevron-down" style={{ fontSize: 12 }} />;

  if (typeof appendIcon === 'undefined') {
    appendIcon = defaultIcon;
  }

  const menu = <div className="shadow bg-body rounded-3" style={menuStyle}>{children}</div>;

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      className={`dropdown-button-wrap ${className}`}
      ref={containerRef}
    >
      <PillButton
        className={buttonClassName}
        style={buttonStyle}
        text={text}
        isActive={menuVisible}
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
        prependIcon={prependIcon}
        appendIcon={appendIcon}
        onClick={toggleMenuVisible}
      />

      {usePortal ? createPortal(<div style={positionerStyle}>{menu}</div>, document.body) : menu}
    </div>
  );
});

DropdownButton.displayName = 'DropdownButton';

DropdownButton.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonStyle: PropTypes.object,
  maxHeight: PropTypes.number,
  prependIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  appendIcon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  isOpen: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menuAnchor: PropTypes.oneOf(MENU_ANCHOR_OPTS),
  usePortal: PropTypes.bool,
  closeOnChanged: PropTypes.any, // Menu will close whenever this value changes.
  closeTimeout: PropTypes.number,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default DropdownButton;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { format } from 'date-fns';
import { urls } from 'app-constants';
import { useHistory } from 'hooks';
import { DateFilter } from 'components/filters';
import LocationSelect from 'components/LocationSelect';

const LandingPageForm = ({ className }) => {
  const { location: { queryParams } } = useHistory();

  const [dateVal, setDateVal] = useState(null);
  const handleDateChange = ([start, end]) => {
    setDateVal(start && end ? [start, end] : null);
  };

  const handleSubmit = () => {
    const queryObj = { ...queryParams };
    if (dateVal) {
      const [start, end] = dateVal;
      queryObj.date_start = start && format(start, 'yyyy-MM-dd');
      queryObj.date_end = end && format(end, 'yyyy-MM-dd');
    }
    const url = `${urls.events}?${queryString.stringify(queryObj)}`;
    window.location.href = url;
  };

  return (
    <div className={classNames('d-sm-flex', className)}>
      <div className="mb-3">
        <LocationSelect className="me-3" maxHeight={400} />
      </div>
      <div className="mb-3">
        <DateFilter prependIcon="clock" value={dateVal} onChange={handleDateChange} />
      </div>
      <div className="mb-3">
        <button type="button" className="btn-z primary" onClick={handleSubmit}>Find an Event</button>
      </div>
    </div>
  );
};

LandingPageForm.propTypes = {
  className: PropTypes.string,
};

export default LandingPageForm;

import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const FilterContext = createContext();

export const FilterContextProvider = ({ children }) => {
  const [contextVal, setContextVal] = useState({});
  const updateFilterContext = val => setContextVal(prevState => ({ ...prevState, ...val }));
  const value = {
    ...contextVal,
    setFilterContext: setContextVal,
    updateFilterContext,
  };
  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

FilterContextProvider.propTypes = {
  children: PropTypes.any, // It's an array of portals.
};

export function useFilterContext () {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error('useFilterContext must be used within a FilterContextProvider');
  }
  return context;
}

export function withFilterContext (WrappedComponent) {
  const Component = props => (
    <FilterContextProvider>
      <WrappedComponent {...props} />
    </FilterContextProvider>
  );

  Component.displayName = `withFilterContext(${WrappedComponent.displayName || WrappedComponent.name})`;
  return Component;
}

import React from 'react';
import { addDays, isSameDay, isSunday, nextSunday, subDays } from 'date-fns';
import { dateRangeLabels } from 'app-constants';
import { useFilterContext } from 'context';
import DateRangePicker from 'components/DateRangePicker';

const DateFilter = props => {
  const now = new Date();
  const nowPlus1 = addDays(now, 1);
  const nowPlus2 = addDays(now, 2);
  const nowPlus6 = addDays(now, 6);
  const nowPlus29 = addDays(now, 29);
  const sunday = isSunday(now) ? now : nextSunday(now);
  const friday = subDays(sunday, 2);
  // const fmt = 'MMM d';

  const optionsData = {
    today: { dateRange: [now, now] },
    tomorrow: { dateRange: [nowPlus1, nowPlus1] },
    'this-weekend': { dateRange: [friday, sunday] },
    'next-3': { dateRange: [now, nowPlus2] },
    'next-7': { dateRange: [now, nowPlus6] },
    'next-30': { dateRange: [now, nowPlus29] },
  };

  Object.entries(optionsData).forEach(([key, value]) => value.label = value.label || dateRangeLabels[key] || key);

  // Remove the "next 3 days" option if it is identical to "this weekend"
  if (isSameDay(now, friday) && isSameDay(nowPlus2, sunday)) {
    delete optionsData['next-3'];
  }

  const { updateFilterContext } = useFilterContext();

  return (
    <DateRangePicker
      {...props}
      presetOptions={optionsData}
      defaultOption="today"
      onMetadataChange={updateFilterContext}
    />
  );
};

DateFilter.propTypes = {
  ...DateRangePicker.propTypes,
};

export default DateFilter;

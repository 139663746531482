import React from 'react';
import SelectField from '../SelectField';
import STATES from './stateData';

const stateChoices = Object.entries(STATES);

const StateField = props => {
  const { validate = () => true, ...rest } = props;
  const doValidate = value => validate(value) && (Object.keys(STATES).includes(value) || 'Enter a valid U.S. state.');

  return <SelectField {...rest} choices={stateChoices} validate={doValidate} />;
};

StateField.propTypes = SelectField.propTypes;

export default StateField;

import React from 'react';
import PropTypes from 'prop-types';
import { useBootstrapBreakpoint } from 'hooks';
import FilterBar from 'components/FilterBar';
import FilterBarMobile from 'components/FilterBarMobile';
import { DISTANCE_UNITS_MI, DISTANCE_UNITS_KM } from 'components/filters';

const FilterWrapper = ({ objectType = 'event', distanceUnits = DISTANCE_UNITS_MI }) => {
  const isMobile = useBootstrapBreakpoint('down-md');
  const Component = isMobile ? FilterBarMobile : FilterBar;
  return <Component objectType={objectType} distanceUnits={distanceUnits} />;
};

FilterWrapper.propTypes = {
  objectType: PropTypes.oneOf(['event', 'poi']),
  distanceUnits: PropTypes.oneOf([DISTANCE_UNITS_MI, DISTANCE_UNITS_KM]),
};

export default FilterWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { isValid, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import colors from 'themeColors';
import Icon from 'components/Icon';

const EventDateSummary = ({ start, end, timeZone }) => {
  const formatOpts = timeZone ? { timeZone } : null;
  if (!isValid(start)) start = parseISO(start);
  if (end && !isValid(end)) end = parseISO(end);

  if (timeZone) {
    start = utcToZonedTime(start, timeZone);
    end = end && utcToZonedTime(end, timeZone);
  }

  let formattedTime = format(start, 'E, MMM d, p', formatOpts);
  if (end) formattedTime += ' – ' + format(end, 'p', formatOpts);
  formattedTime += ' ' + format(start, 'z', formatOpts);

  return (
    <div className="lh-sm d-flex align-items-center">
      <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: colors.gray300 }}>
        <Icon i="clock" size="sm" />
      </div>
      <div>{formattedTime}</div>
    </div>
  );
};

EventDateSummary.propTypes = {
  start: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  end: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  /**
   * IANA Time Zone ID - if provided, times will be converted to the specified timezone. Omit to display times in
   * client browser's local timezone.
   * */
  timeZone: PropTypes.string,
};

export default EventDateSummary;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import { useUserContext } from 'context';
import { useAuthRequired } from 'hooks';
import Icon from 'components/Icon';
import PillButton from 'components/PillButton';
import LoadingBubbles from 'components/LoadingBubbles';

const TYPE_EVENT = 'event';
const TYPE_POI = 'poi';

const BookmarkToggleButton = ({
  className,
  objectType = TYPE_EVENT,
  objectId,
  iconOnly = false,
  buttonText,
}) => {
  if (![TYPE_EVENT, TYPE_POI].includes(objectType)) {
    throw new Error('Invalid value for objectType prop.');
  }

  const { savedEventIds, savedPoiIds, toggleBookmark } = useUserContext();

  const baseUrl = objectType === TYPE_POI ? urls.toggleSavedPoiBase : urls.toggleSavedEventBase;
  const apiUrl = `${baseUrl}${objectId}/`;

  const getOrToggleSavedStatus = (toggle = false) => {
    setIsLoading(true);
    if (iconOnly && toggle) {
      // Give immediate UI feedback
      toggleBookmark(objectType, objectId);
    }

    const opts = toggle ? {
      credentials: 'include',
      method: 'POST',
      headers: { 'X-CSRFToken': getCsrfToken() },
    } : {};

    fetch(apiUrl, opts)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        // Update the value in shared context
        if (toggle) toggleBookmark(objectType, objectId, data.saved);
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const savedIds = objectType === TYPE_POI ? savedPoiIds : savedEventIds;
  const isSaved = savedIds && savedIds.includes(objectId);

  const handleClick = useAuthRequired(() => getOrToggleSavedStatus(true));

  if (typeof buttonText === 'undefined') {
    buttonText = isSaved ? 'Saved' : 'Save';
  }

  const dataAttrs = {
    'data-ref': 'bookmark-toggle',
    'data-action': isSaved ? 'unsave' : 'save',
    'data-object-type': objectType,
    'data-object-id': objectId,
  };

  return iconOnly ? (
    <Icon
      className={classNames('bookmark-link', isSaved && 'active', className)}
      attrs={dataAttrs}
      i={isSaved ? 'bookmark' : ['far', 'bookmark']}
      onClick={handleClick}
    />
  ) : (
    <PillButton
      className={className}
      attrs={dataAttrs}
      prependIcon={isSaved ? 'bookmark' : ['far', 'bookmark']}
      text={isLoading ? <LoadingBubbles /> : buttonText}
      onClick={handleClick}
    />
  );
};

BookmarkToggleButton.propTypes = {
  className: PropTypes.string,
  objectType: PropTypes.oneOf([TYPE_EVENT, TYPE_POI]),
  objectId: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default BookmarkToggleButton;

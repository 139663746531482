import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  isBefore,
  isValid,
  parse as parseDate,
  startOfDay,
} from 'date-fns';
import PillButton from 'components/PillButton';
import {
  DateField,
  SelectField,
  TIME_FORMAT,
  TimeField,
} from 'components/formFields';
import useFrequencyOptions, { endOptionDefaults, customDefaults } from './useFrequencyOptions';
import Exceptions from './Exceptions';

export const rowDefaults = {
  date: '',
  startTime: '',
  endTime: '',
  frequency: 'once',
  exceptions: [],
  ...endOptionDefaults,
  ...customDefaults,
};

const EventDatesRow = ({ prefix, formMethods, index, allowAnyDate = false, onDelete }) => {
  const { watch, setValue, trigger, control } = formMethods;

  const today = useMemo(() => startOfDay(new Date()));

  const frequencyVal = watch(`${prefix}.frequency`);
  const isRecurring = frequencyVal !== 'once';
  useEffect(() => {
    if (!isRecurring) {
      setValue(`${prefix}.exceptions`, []);
    }
  }, [isRecurring]);

  const startTimeString = watch(`${prefix}.startTime`);
  const endTimeString = watch(`${prefix}.endTime`);

  const startTimeVal = useMemo(() => parseDate(startTimeString, TIME_FORMAT, today), [startTimeString]);
  const endTimeVal = useMemo(() => parseDate(endTimeString, TIME_FORMAT, today), [endTimeString]);

  const validateEndTime = useCallback(value => {
    const valueAsDate = parseDate(value, TIME_FORMAT, today);

    if (isValid(startTimeVal) && isValid(valueAsDate) && isBefore(valueAsDate, startTimeVal)) {
      return 'End time must be after start time.';
    }
    return true;
  }, [startTimeVal]);

  useEffect(() => {
    if (isValid(startTimeVal) && isValid(endTimeVal)) {
      trigger(`${prefix}.endTime`);
    }
  }, [startTimeVal]);

  const { options: frequencyOptions, controls, extraControls } = useFrequencyOptions(formMethods, prefix);

  return (
    <>
      <div className="row mb-3">
        <div className="col-5">
          <DateField
            name={`${prefix}.date`}
            label={isRecurring ? 'Start Date' : 'Date'}
            required
            minDate={allowAnyDate ? undefined : today}
            formMethods={formMethods}
          />
        </div>
        <div className="col-3">
          <TimeField
            name={`${prefix}.startTime`}
            label="Start Time"
            required
            formMethods={formMethods}
          />
        </div>
        <div className="col-3">
          <TimeField
            name={`${prefix}.endTime`}
            label="End Time"
            validate={validateEndTime}
            formMethods={formMethods}
          />
        </div>
        <div className="col-1 d-flex justify-content-end">
          {!!onDelete && (
            <div className="d-flex align-items-center" style={{ height: 56 }}>
              <PillButton
                attrs={{ title: 'Remove' }}
                prependIcon={['far', 'minus']}
                className="danger sm"
                style={{ marginBottom: 4 }}
                onClick={onDelete}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-5">
          <SelectField
            name={`${prefix}.frequency`}
            label="Frequency"
            choices={frequencyOptions}
            required
            formMethods={formMethods}
          />
        </div>
        {controls}
      </div>
      {extraControls}

      {frequencyVal !== 'once' && (
        <Controller
          name={`${prefix}.exceptions`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Exceptions value={value} onChange={onChange} />
          )}
        />
      )}
    </>
  );
};

EventDatesRow.propTypes = {
  prefix: PropTypes.string,
  formMethods: PropTypes.object,
  index: PropTypes.number,
  allowAnyDate: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default EventDatesRow;

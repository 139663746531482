import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { useForm, FormProvider } from 'react-hook-form';
import { urls } from 'app-constants';
import { useUserContext } from 'context';
import { getCsrfToken } from 'utils';
import Modal from 'components/Modal';
import { SelectField, TextField } from 'components/formFields';

const CalendarCreateOrUpdate = ({ calendar: { id, title = '', search_id = null }, isOpen, onRequestClose, onSave }) => {
  const { savedSearches } = useUserContext();
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultValues = { title, search_id };

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues,
  });
  const { formState: { isValid } } = formMethods;

  useEffect(() => {
    formMethods.reset(defaultValues)
  }, [JSON.stringify(defaultValues), isOpen]);

  useEffect(() => {
    setErrorMessage('');
  }, [isOpen]);

  const onSubmit = payload => {
    const url = id ? urlJoin(urls.apiWidgetBase, `${id}/`) : urls.apiWidgetList;
    const method = id ? 'PUT' : 'POST';

    setIsFetching(true);

    fetch(url, {
      credentials: 'include',
      method,
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        setIsFetching(false);

        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            onSave(data);
            onRequestClose();
          });
        }
        return response;
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="p-3 p-sm-6">
        <h4 className="mb-4">
          {id ? 'Edit' : 'Create New'} Calendar{!!title && `: ${title}`}
        </h4>
        {!!errorMessage && (
          <div className="alert alert-danger mb-3" role="alert">
            {errorMessage}
          </div>
        )}

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className="mb-2">
              <TextField
                name="title"
                label="Title"
                required
                maxLength={256}
              />
            </div>

            <div className="mb-4">
              <SelectField
                name="search_id"
                label="Saved Search"
                required
                choices={(savedSearches || []).map(({ id, searchName }) => [id, searchName])}
              />
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <a href="#cancel" className="small reset-link text-tertiary me-3" onClick={onRequestClose}>Cancel</a>
              <input
                type="submit"
                value="Save"
                className="btn-z primary"
                disabled={isFetching || !isValid}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

CalendarCreateOrUpdate.propTypes = {
  calendar: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    search_id: PropTypes.number,
  }),
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default CalendarCreateOrUpdate;

import { parseISO, isSameDay } from 'date-fns';
import { useUserContext, useFilterContext } from 'context';

export default function useSavedSearchMatch () {
  const { id: userId, savedSearches } = useUserContext();
  const {
    locationId,
    radius,
    keyword,
    venueType,
    ageGroups,
    categoryIds,
    dateSlug,
    dateRange,
  } = useFilterContext();

  if (!userId || !savedSearches || !savedSearches.length) return null;

  const test = savedSearch => {
    // Simple comparisons
    const searchLocId = savedSearch.address ? savedSearch.address.id : savedSearch.defaultLocId;
    if ((locationId || null) !== (searchLocId || null)) return false;
    if ((radius || null) !== (savedSearch.radius || null)) return false;
    if ((keyword || null) !== (savedSearch.keyword || null)) return false;
    if ((venueType || null) !== (savedSearch.eventSetting || null)) return false;

    // Array comparisons
    const areSame = (a1, a2) => a1.every(n => a2.includes(n)) && a2.every(n => a1.includes(n));
    if (!areSame(ageGroups || [], savedSearch.ageGroup || [])) return false;
    if (!areSame(categoryIds || [], savedSearch.eventTypes || [])) return false;

    // Date ranges
    if (dateSlug === 'custom') {
      const [fStart, fEnd] = dateRange;
      const sStart = parseISO(savedSearch.dateStart);
      const sEnd = parseISO(savedSearch.dateEnd);
      if (!isSameDay(fStart, sStart) || !isSameDay(fEnd, sEnd)) {
        return false;
      }
    } else if ((dateSlug || null) !== (savedSearch.namedDate.replaceAll('_', '-') || null)) {
      return false;
    }

    return true;
  };

  const match = savedSearches.find(test);
  return match ? match.searchName : null;
}

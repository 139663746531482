import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { addDays, format, formatISO } from 'date-fns';
import { urls } from 'app-constants';
import { useHistory } from 'hooks';
import { useFilterContext } from 'context';
import Icon from 'components/Icon';

const KeywordSearch = ({ className }) => {
  const inputRef = useRef();
  const [inputVal, setInputVal] = useState('');
  const trimmedVal = inputVal.trim();

  const { location: { queryParams, pathname }, updateQueryParams } = useHistory();
  const { updateFilterContext } = useFilterContext();

  const handleFormSubmit = evt => {
    evt.preventDefault();
    if ([urls.events, urls.venues].includes(pathname)) {
      updateQueryParams({ keyword: trimmedVal });
      inputRef.current.blur();
    } else {
      const now = new Date();
      const nowPlus29 = addDays(now, 29);
      const params = {
        date_start: formatISO(now),
        date_end: format(nowPlus29, 'yyyy-MM-dd'),
        keyword: trimmedVal,
      };
      const url = `${urls.events}?${queryString.stringify(params)}`;
      window.location.href = url;
    }
  };

  const handleInputChange = evt => setInputVal(evt.target.value);

  useEffect(() => {
    setInputVal((queryParams && queryParams.keyword) || '');
    updateFilterContext({ keyword: (queryParams && queryParams.keyword) || null });
  }, [queryParams && queryParams.keyword]);

  const iconStyle = {
    fontSize: 14,
    position: 'absolute',
    left: 20,
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <form
      className={`keyword-search ${className}`}
      onSubmit={handleFormSubmit}
      data-keyword-value={trimmedVal}
    >
      <input
        ref={inputRef}
        className="btn-z subtle-focus"
        name="keyword"
        type="text"
        placeholder={`Search ${pathname === urls.venues ? 'Venues' : 'Events'}`}
        value={inputVal}
        onChange={handleInputChange}
      />
      <Icon i="search" style={iconStyle} />
    </form>
  );
};

KeywordSearch.propTypes = {
  className: PropTypes.string,
};

export default KeywordSearch;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';

const SocialLoginButton = ({
  className,
  style = {},
  icon,
  provider,
  connect = false,
  children,
}) => {
  let url = urls.loginSocial[provider];
  if (connect) {
    url += `?process=connect&next=${encodeURIComponent(urls.account)}`;
  }

  return (
    <form method="post" action={url}>
      <input type="hidden" name="csrfmiddlewaretoken" value={getCsrfToken()} />
      <button
        type="submit"
        className={classNames('btn-z outline lg w-100 justify-content-center position-relative bg-white', className)}
        style={{ minWidth: 280, ...style }}
      >
        <div
          style={{
            position: 'absolute',
            height: '100%',
            left: 22,
            width: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon}
        </div>
        <span>{children}</span>
      </button>
    </form>
  );
};

SocialLoginButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.node,
  provider: PropTypes.string,
  connect: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default SocialLoginButton;

import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { urls } from 'app-constants';
import ErrorBoundary from 'components/ErrorBoundary';

const UserContext = createContext();

const TYPE_EVENT = 'event';
const TYPE_POI = 'poi';

export const UserContextProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  const fetchUserContext = () => {
    setIsFetching(true);
    fetch(urls.userData)
      .then(response => {
        if (response.status === 403) {
          setData({ authenticated: false });
          setIsFetching(false);
          return null;
        } else if (!response.ok) {
          throw new Error(response.statusText);
        }

        response.json().then(resData => {
          setData({ authenticated: true, ...camelize(resData) });
          setIsFetching(false);
        });
      })
      .catch(err => {
        console.error(err);
        setData({});
        setIsFetching(false);
      });
  };

  useEffect(fetchUserContext, []);

  const [authRequested, setAuthRequested] = useState(null);
  useEffect(() => {
    if (data.authenticated && authRequested) {
      setAuthRequested(null);
    }
  }, [data.authenticated]);

  const toggleBookmark = (objectType, objectId, state) => {
    if (![TYPE_EVENT, TYPE_POI].includes(objectType)) {
      throw new Error('Invalid value for objectType.');
    }
    const key = objectType === TYPE_POI ? 'savedPoiIds' : 'savedEventIds';
    setData(oldState => {
      const wasSaved = oldState[key].includes(objectId);
      const val = oldState[key].filter(id => id !== objectId);
      if (state === true || (typeof state === 'undefined' && !wasSaved)) {
        val.push(objectId);
      }
      return {
        ...oldState,
        [key]: val,
      };
    });
  };

  const value = {
    ...data,
    fetchUserContext,
    isFetching,
    toggleBookmark,
    authRequested,
    setAuthRequested,
  };
  return (
    <UserContext.Provider value={value}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function useUserContext () {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
}

import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { urls } from 'app-constants';
import { LiveSearchSelectField } from 'components/formFields';

const PoiSearchForm = () => {
  const formMethods = useForm();
  const val = formMethods.watch('venue');

  const openPoiPage = id => {
    fetch(`${urls.poiLivesearchBase}?id=${id}`)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        const [{ absolute_url }] = data;
        window.location.href = absolute_url;
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (val) openPoiPage(val);
  }, [val]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <LiveSearchSelectField
          name="venue"
          objectType="poi"
          label="Find a Venue"
        />
      </form>
    </FormProvider>
  );
};

export default PoiSearchForm;

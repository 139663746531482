import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { imageSpecs } from 'app-constants';
import { usePrevious } from 'hooks';
import { useDropzone } from 'react-dropzone';
import Icon from 'components/Icon';
import LoadingOverlay from 'components/LoadingOverlay';
import Cropper from './Cropper';

const ImageFieldWidget = ({
  imageFieldName = 'image',
  cropDataFieldName = 'image_crop_data',
  label = 'Image',
  imageUrl,
  cropData: cropDataInitial = {},
  specs = imageSpecs,
  maxSize = 10,
  hasError = false,
  onChange = () => {},
}) => {
  const [imageClear, setImageClear] = useState(false);
  const [cropData, setCropData] = useState(cropDataInitial);
  const [previewUrl, setPreviewUrl] = useState();
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const readerRef = useRef();

  useEffect(() => {
    const val = { cropData };
    if (previewUrl !== imageUrl) {
      val.image = previewUrl;
    }
    onChange(val);
  }, [previewUrl, cropData]);

  useEffect(() => {
    if (imageUrl) setPreviewUrl(imageUrl);
  }, [imageUrl]);

  const previousPreviewUrl = usePrevious(previewUrl);
  useEffect(() => {
    if (previousPreviewUrl && !previewUrl) setCropData({});
  }, [previewUrl]);

  const loadPreviewFromFile = fileObj => {
    setPreviewIsLoading(true);
    readerRef.current = new FileReader();
    readerRef.current.readAsDataURL(fileObj);
    readerRef.current.onload = () => {
      setPreviewUrl(readerRef.current.result);
      setImageClear(false);
      setPreviewIsLoading(false);
    };
    readerRef.current.onerror = () => {};
  };

  useEffect(() => {
    return () => readerRef.current && readerRef.current.abort();
  }, []);

  const [fileErrors, setFileErrors] = useState([]);
  const handleFileDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      const [file] = acceptedFiles;
      setFileErrors([]);
      loadPreviewFromFile(file);
    } else if (rejectedFiles.length > 0) {
      const [file] = rejectedFiles;
      setFileErrors(file.errors.map(e => e.message));
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop: handleFileDrop,
    multiple: false,
    accept: 'image/jpeg,image/png,.jpg,.jpeg,.png',
    maxSize: maxSize * 1000000, // bytes
  });

  const handleRemoveClick = evt => {
    evt.preventDefault();
    setPreviewUrl(null);
    setImageClear(true);
  };

  const handleCropChange = (id, crop) => {
    const { unit, ...rest } = crop;
    const vals = Object.entries(rest).reduce((result, [key, value]) => {
      result[key] = Math.round(value);
      return result;
    }, {});

    setCropData(oldState => ({
      ...oldState,
      [id]: vals,
    }));
  };

  const dropzoneClasses = classNames({
    'image-field-widget-dropzone': true,
    active: isDragActive,
    reject: isDragReject,
  });

  const isInitial = !!imageUrl && previewUrl === imageUrl;

  return (
    <>
      <div className="d-flex align-items-baseline mb-2">
        <label className="form-label m-0">{label}</label>
        <div className="flex-spacer" />
        {previewUrl && <button type="button" className="btn-z sm" onClick={handleRemoveClick}>Remove</button>}
      </div>

      {(previewUrl || previewIsLoading) ? (
        <div className={classNames('image-field-widget', (hasError || fileErrors.length > 0) && 'error')}>
          {imageSpecs.map(({ id, label, aspectRatio }) => {
            let initialCrop;
            if (isInitial && cropDataInitial && cropDataInitial[id]) {
              initialCrop = { unit: '%', ...cropDataInitial[id] };
            }

            return (
              <section
                key={id}
                className="image-field-widget-image-container"
                style={{ flex: `0 0 ${100 / imageSpecs.length}%` }}
              >
                <header>{label}</header>
                <div className="image-field-widget-image">
                  <Cropper
                    src={previewUrl}
                    initialCrop={initialCrop}
                    aspectRatio={aspectRatio}
                    onChange={crop => handleCropChange(id, crop)}
                  />
                </div>
              </section>
            );
          })}
          <LoadingOverlay show={previewIsLoading} className="bg-lt" />
        </div>
      ) : (
        <div className={dropzoneClasses} {...getRootProps()}>
          <Icon i={['faz', 'image-upload']} style={{ fontSize: 42 }} />
          <h6 className="mb-3">Drag & drop or click here to upload image</h6>
          <div className="meta">.jpg or .png files, {maxSize} MB maximum.</div>
          {fileErrors.map((errorText, idx) => <div key={idx} className="text-danger mt-1">{errorText}</div>)}
          <input {...getInputProps()} />
        </div>
      )}

      {previewUrl && previewUrl !== imageUrl && (
        <input type="hidden" name={imageFieldName} value={previewUrl} />
      )}
      {imageClear && <input type="hidden" name={`${imageFieldName}_clear`} value={1} />}
      <input type="hidden" name={cropDataFieldName} value={JSON.stringify(cropData)} />
    </>
  );
};

ImageFieldWidget.propTypes = {
  imageFieldName: PropTypes.string,
  cropDataFieldName: PropTypes.string,
  label: PropTypes.string,
  imageUrl: PropTypes.string,
  cropData: PropTypes.object,
  specs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    aspectRatio: PropTypes.number,
  })),
  maxSize: PropTypes.number, // MB
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ImageFieldWidget;

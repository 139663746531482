import React from 'react';
import PropTypes from 'prop-types';
import { typeBadgeColors, badgeDefaultColorClass } from 'app-constants';
import Badge from 'components/Badge';

const EventTypeBadge = ({ eventTypeName, ...rest }) => (
  <Badge
    {...rest}
    text={eventTypeName}
    type="Category"
    colorClass={typeBadgeColors[eventTypeName] || badgeDefaultColorClass}
  />
);

EventTypeBadge.propTypes = {
  eventTypeName: PropTypes.oneOf(Object.keys(typeBadgeColors)),
};

export default EventTypeBadge;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LoadingOverlay = ({ show = true, align = 'middle', className }) => {
  const classes = classNames({
    'loading-overlay': true,
    'py-5': true,
    hide: !show,
    'align-items-start': align === 'top',
    [className]: !!className,
  });

  return (
    <div className={classes}>
      <div className="loading-indicator" />
    </div>
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
  align: PropTypes.oneOf(['top', 'middle']),
  className: PropTypes.string,
};

export default LoadingOverlay;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { useResizeDetector } from 'hooks';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import MapMarker from 'components/MapMarker';

const ListingsMap = ({
  mapStyle = 'mapbox://styles/mapbox/light-v10',
  mapboxToken,
  markers = [],
  focusedMarkerId,
  defaultViewport = {
    latitude: 0,
    longitude: 0,
    zoom: 1,
  },
}) => {
  const mapRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(0);
  const [viewport, setViewport] = useState(defaultViewport);
  const { latitude, longitude, zoom } = viewport;

  // `focusedMarkerId` has its icon highlighted
  // `activeMarkerId` has its icon highlighted and info popup visible
  const [activeMarkerId, setActiveMarkerId] = useState(null);

  // When a marker is focused (hightlighted on event card hover)
  // or activated (clicked), move it to the top of the stack
  const cachedMarkers = useMemo(() => {
    const topMarkerId = focusedMarkerId || activeMarkerId;
    const topMarker = markers.find(m => m.id === topMarkerId);
    if (topMarker) {
      return [
        ...markers.filter(m => m.id !== topMarkerId),
        topMarker,
      ];
    } else {
      return markers;
    }
  }, [markers, focusedMarkerId, activeMarkerId]);

  // Close info popup when user hovers on a different event card
  useEffect(() => {
    if (focusedMarkerId !== activeMarkerId) {
      setActiveMarkerId(null);
    }
  }, [focusedMarkerId]);

  let { width, height, ref } = useResizeDetector();
  width = width && Math.round(width);
  height = height && Math.round(height);

  const handleViewportChange = (viewport, transitionDuration = 0) => {
    setTransitionDuration(transitionDuration);
    setViewport(viewport);
  };

  const inMapBounds = (longitude, latitude) => {
    const bounds = mapRef.current.getMap().getBounds();
    const lngInside = (longitude - bounds.getNorthEast().lng) * (longitude - bounds.getSouthWest().lng) < 0;
    const latInside = (latitude - bounds.getNorthEast().lat) * (latitude - bounds.getSouthWest().lat) < 0;
    return lngInside && latInside;
  };

  // Set the map bounds to ensure all markers are visible
  const fitToMarkers = markers => {
    setTransitionDuration(500);
    if (!markers.length) {
      setViewport({ ...defaultViewport });
    } else if (markers.length === 1) {
      const { longitude, latitude } = markers[0];
      setViewport({ longitude, latitude, zoom: 14 });
    } else {
      const bounds = new mapboxgl.LngLatBounds();
      const viewport = new WebMercatorViewport({ width, height });
      markers.forEach(m => bounds.extend([m.longitude, m.latitude]));
      const { latitude, longitude, zoom } = viewport.fitBounds(bounds.toArray(), { padding: 60 });
      setViewport({ latitude, longitude, zoom });
    }
  };

  useEffect(() => {
    if (loaded) fitToMarkers(markers);
  }, [JSON.stringify(markers.map(m => m.id)), loaded]);

  // If the focused marker is outside of the current map bounds, pan to it.
  useEffect(() => {
    if (loaded && focusedMarkerId) {
      const focusedMarker = cachedMarkers.find(m => m.id === focusedMarkerId);
      const { longitude, latitude } = focusedMarker;
      if (longitude && latitude && !inMapBounds(longitude, latitude)) {
        setTransitionDuration(500);
        setViewport({ ...viewport, longitude, latitude });
      }
    }
  }, [focusedMarkerId, loaded]);

  const renderedMarkers = useMemo(() => cachedMarkers.map(m => (
    <MapMarker
      key={m.id}
      label={m.label}
      latitude={m.latitude}
      longitude={m.longitude}
      active={[focusedMarkerId, activeMarkerId].includes(m.id)}
      iconComponent={m.iconComponent}
      iconProps={m.iconProps}
      showPopup={activeMarkerId === m.id}
      popupWidth={m.popupWidth}
      popupContent={m.popupContent}
      mapContainerEl={ref.current}
      onClick={() => setActiveMarkerId(m.id)}
      onRequestClose={() => activeMarkerId === m.id && setActiveMarkerId(null)}
    />
  )), [cachedMarkers, focusedMarkerId, activeMarkerId]);

  const showMap = !!(width && height);

  const containerStyle = {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  };

  return (
    <div ref={ref} style={containerStyle}>
      {showMap && (
        <ReactMapGL
          ref={mapRef}
          mapboxApiAccessToken={mapboxToken}
          mapStyle={mapStyle}
          width={width}
          height={height}
          latitude={latitude}
          longitude={longitude}
          zoom={zoom}
          maxZoom={18}
          transitionDuration={transitionDuration}
          transitionInterpolator={mapRef.flyTo}
          onViewportChange={viewport => handleViewportChange(viewport)}
          onLoad={() => setLoaded(true)}
          onTransitionEnd={() => setTransitionDuration(0)}
        >
          <NavigationControl
            style={{ position: 'absolute', top: 20, right: 20 }}
            showCompass={false}
            onViewportChange={viewport => handleViewportChange(viewport, 150)}
          />
          {renderedMarkers}
        </ReactMapGL>
      )}
    </div>
  );
};

ListingsMap.propTypes = {
  mapStyle: PropTypes.string,
  mapboxToken: PropTypes.string.isRequired,
  markers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    popupContent: PropTypes.node,
  })),
  focusedMarkerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultViewport: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    zoom: PropTypes.number,
  }),
};

export default ListingsMap;

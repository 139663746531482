import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { SelectField } from 'components/formFields';

const PoiSelector = ({ choices, onChange = () => {} }) => {
  const formMethods = useForm({ defaultValues: { activeVenue: choices[0][0] } });
  const activeId = formMethods.watch('activeVenue');
  useEffect(() => {
    onChange(activeId);
  }, [activeId]);

  return (
    <FormProvider {...formMethods}>
      <SelectField
        name="activeVenue"
        label="Select a venue"
        required
        choices={choices}
      />
    </FormProvider>
  );
};

PoiSelector.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.array),
  onChange: PropTypes.func,
};

export default PoiSelector;

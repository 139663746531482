import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';


class LocalErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError () {
    return { hasError: true };
  }

  componentDidCatch (error, info) {
    const { onError } = this.props;
    console.error(error);
    onError && onError(error, info);
  }

  render () {
    const { fallback, children = null } = this.props;
    const { hasError } = this.state;
    return hasError ? (fallback || null) : children;
  }
}

LocalErrorBoundary.propTypes = {
  fallback: PropTypes.node,
  onError: PropTypes.func,
  children: PropTypes.node,
};

const ErrorBoundary = ({ children, ...rest }) => {
  const Comp = (process.env.NODE_ENV !== 'development' || window.SENTRY_CONF.debug) ? SentryErrorBoundary : LocalErrorBoundary;
  return <Comp {...rest}>{children || null}</Comp>;
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;

import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'components/LoadingOverlay';

const MetricTable = ({ className, style = {}, config, data, isFetching = false }) => {
  const rows = (data || []).map(record => config.map(({ dataKey, align = 'left' }) => ({
    value: record[dataKey],
    align,
  })));
  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay show={isFetching} className="bg-lt" align="top" />
      <table className={`table ${className}`} style={style}>
        <thead>
          <tr>
            {config.map(({ label, align }) => <th key={label} scope="col" style={{ textAlign: align }}>{label}</th>)}
          </tr>
        </thead>
        <tbody>
          {rows.map((vals, idx) => (
            <tr key={idx}>
              {vals.map(({ value, align }) => <td key={`${idx}-${value}`} style={{ textAlign: align }}>{value.toLocaleString()}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

MetricTable.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    dataKey: PropTypes.any,
    sortable: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'right']),
  })),
  data: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
};

export default MetricTable;

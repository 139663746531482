import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import PillButton from 'components/PillButton';
import EventDatesRow, { rowDefaults } from './EventDatesRow';

const EventDatesField = ({ name, formMethods, allowAnyDate = false }) => {
  formMethods = formMethods || useFormContext();
  const { control } = formMethods;
  const { fields: rowFields, append, remove } = useFieldArray({ control, name });

  const addRow = () => append({ ...rowDefaults }, { shouldFocus: false });

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <>
          {rowFields.map((field, idx) => (
            <Fragment key={field.id}>
              <EventDatesRow
                prefix={`${name}.${idx}`}
                formMethods={formMethods}
                index={idx}
                allowAnyDate={allowAnyDate}
                onDelete={rowFields.length > 1 ? () => remove(idx) : undefined}
              />
              {idx + 1 < rowFields.length && <div className="row mb-3"><div className="col"><hr className="m-0" /></div></div>}
            </Fragment>
          ))}
          <div className="d-flex">
            <PillButton text="Add Date/Time" onClick={addRow} prependIcon={['far', 'plus']} />
          </div>
        </>
      )}
    />
  );
};

EventDatesField.propTypes = {
  name: PropTypes.string,
  formMethods: PropTypes.object,
  allowAnyDate: PropTypes.bool,
};

export default EventDatesField;

import React from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';

const TruncateText = ({ text, numChars, forceBreak, truncateStart }) => {
  text = striptags(text || '');
  const clipped = truncateStart
    ? '…' + text.substring(text.length - numChars, text.length)
    : text.substring(0, numChars) + '…';

  return (
    <span style={{ wordBreak: forceBreak ? 'break-all' : 'normal' }}>
      {text.length <= numChars ? text : clipped}
    </span>
  );
};

TruncateText.propTypes = {
  text: PropTypes.string,
  numChars: PropTypes.number.isRequired,
  forceBreak: PropTypes.bool, // useful if the text might contain URLs or other long words
  truncateStart: PropTypes.bool, // truncate the beginning of the string
};

export default TruncateText;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import useErrorOrHelpText from './useErrorOrHelpText';

const CheckboxMultiField = ({
  name,
  label,
  choices = [],
  helpText,
  required = false,
  disabled = false,
  validate,
  registerOpts = {},
  formMethods,
  horizontal = false,
}) => {
  const { register, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const val = watch(name) || '';

  return (
    <div className={classNames('z-form-multi-container', required && 'required')}>
      {label && <h6 className="z-form-multi-label mb-3">{label}</h6>}
      <div className={classNames('z-form-multi', horizontal && 'horizontal')}>
        {choices.map(choice => {
          const inputProps = register(name, {
            required: { value: required, message: 'This field is required.' },
            validate,
            ...registerOpts,
          });

          const [value, label] = Array.isArray(choice) ? choice : [choice, choice];

          const labelClasses = classNames({
            'z-form-checkbox': true,
            checked: val.includes(value),
            'z-form-disabled': !!disabled,
            invalid: hasError,
          });

          return (
            <div key={value} className="z-form-multi-item">
              <label className={labelClasses}>
                <input type="checkbox" {...inputProps} value={value} disabled={disabled} />
                {label}
              </label>
            </div>
          );
        })}
      </div>
      <div className="z-form-hint-container mt-3" style={{ padding: 0 }}>
        {errorOrHelpText}
      </div>
    </div>
  );
};

CheckboxMultiField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  choices: PropTypes.array,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
  horizontal: PropTypes.bool,
};

export default CheckboxMultiField;

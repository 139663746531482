import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const CheckboxList = ({ className, listId, options, value, onChange }) => {
  const handleOptionClick = val => {
    let newVal;
    if (val === null) {
      newVal = [];
    } else if (value.includes(val)) {
      newVal = value.filter(v => v !== val);
    } else {
      newVal = [...value, val];
    }
    onChange(newVal);
  };

  return (
    <ul className={`checkbox-list ${className || ''}`}>
      {options.map(([optVal, optLabel]) => {
        let isSelected = value.includes(optVal);
        if (optVal === null) {
          isSelected = value.length === 0;
        }

        const handleClick = () => handleOptionClick(optVal);
        const classes = classNames('checkbox-list-item', isSelected && 'selected');

        return (
          <li
            key={optVal}
            className={classes}
            onClick={handleClick}
            data-list-id={listId}
            data-option-value={optVal}
            data-option-label={optLabel}
            data-is-selected={isSelected}
          >
            <Icon i={isSelected ? 'check-square' : ['far', 'square']} />
            <span>{optLabel}</span>
          </li>
        );
      })}
    </ul>
  );
};

CheckboxList.propTypes = {
  className: PropTypes.string,
  listId: PropTypes.string,
  // each item in the options array is a 2-item array containing [value, label]
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])),
  onChange: PropTypes.func.isRequired,
};

export default CheckboxList;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useToggle } from 'hooks';
import ReactQuill from 'react-quill';
import useErrorOrHelpText from './useErrorOrHelpText';
import HelpPopup from 'components/HelpPopup';
import 'react-quill/dist/quill.snow.css';

const QUILL_MODULES = {
  toolbar: [
    ['bold', 'italic'],
    [{ list: 'bullet' }, { list: 'ordered' }],
    ['link', 'clean'],
  ],
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: () => true,
      },
    },
  },
};

const QUILL_FORMATS = [
  'bold', 'italic',
  'bullet', 'list', 'indent',
  'link',
];

const RichTextField = ({
  height = 250,
  name,
  label,
  helpText,
  helpPopupContent,
  required = false,
  disabled = false,
  minLength,
  maxLength,
  validate,
  registerOpts = {},
  formMethods,
}) => {
  const containerRef = useRef();
  const { control, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const val = watch(name) || '';

  const lengthMessage = (minLength && maxLength)
    ? `This field must be between ${minLength} and ${maxLength} characters.`
    : null;

  const [isFocused, toggleFocused] = useToggle(false);
  const handleFocus = () => toggleFocused(true);

  const wrapperClasses = classNames({
    'z-form-richtext-wrap': true,
    focused: isFocused,
    required,
    'has-val': !!val,
    invalid: hasError,
    'z-form-disabled': disabled,
  });

  const [containerEl, setContainerEl] = useState(null);
  useEffect(() => setContainerEl(containerRef.current), []);

  return (
    <div className={wrapperClasses} ref={containerRef}>
      {!!containerEl && (
        <>
          <Controller
            name={name}
            control={control}
            rules={{
              required: { value: required, message: 'This field is required.' },
              disabled,
              minLength: { value: minLength, message: lengthMessage || `This field must be at least ${minLength} character${minLength === 1 ? '' : 's'}.` },
              maxLength: { value: maxLength, message: lengthMessage || `This field is limited to ${maxLength} characters.` },
              validate,
              ...registerOpts,
            }}
            render={({
              field: { onChange, onBlur, value, ref },
            }) => {
              const handleBlur = (previousRange, source, editor) => {
                toggleFocused(false);
                onBlur();
              };
              const handleChange = (content, delta, source, editor) => {
                // Quill leaves a single newline character when the editor is empty.
                onChange(editor.getLength() > 1 ? content : '');
              };
              return (
                <ReactQuill
                  ref={ref}
                  className="z-form-quill-container"
                  style={{ height }}
                  modules={QUILL_MODULES}
                  formats={QUILL_FORMATS}
                  bounds={containerEl}
                  readOnly={disabled}
                  value={value}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              );
            }}
          />

          {label && (
            <label className="z-form-richtext-label">
              {label}
            </label>
          )}
          {!!helpPopupContent && <HelpPopup>{helpPopupContent}</HelpPopup>}
          <div className="z-form-hint-container">
            {errorOrHelpText}
            {maxLength && <div className={classNames('z-form-char-count', val.length > maxLength && 'text-danger')}>{val.length} / {maxLength}</div>}
          </div>
        </>
      )}
    </div>
  );
};

RichTextField.propTypes = {
  height: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  helpPopupContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default RichTextField;

import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { formatISO, isValid } from 'date-fns';
import { urls } from 'app-constants';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';

const AddToCalendar = ({
  showIcon = false,
  buttonClassName,
  buttonText = 'Add to Calendar',
  title,
  description,
  location,
  startDate,
  endDate,
  eventId,
}) => {
  if (!isValid(endDate)) {
    endDate = startDate;
  }

  const icsUrl = `${urls.eventIcalendarBase}${eventId}/?dtstart=${formatISO(startDate)}&dtend=${formatISO(endDate)}`;

  const googleUrl = 'https://calendar.google.com/calendar/render?' + queryString.stringify({
    action: 'TEMPLATE',
    location,
    text: title,
    details: description,
    dates: `${formatISO(startDate, { format: 'basic' })}/${formatISO(endDate, { format: 'basic' })}`,
  });

  const outlookQs = queryString.stringify({
    path: '/calendar/action/compose',
    rru: 'addevent',
    location,
    subject: title,
    body: description,
    startdt: formatISO(startDate),
    enddt: formatISO(endDate),
  });
  const outlookDotComUrl = 'https://outlook.live.com/calendar/0/deeplink/compose?' + outlookQs;
  const office365Url = 'https://outlook.office.com/calendar/0/deeplink/compose?' + outlookQs;

  const yahooUrl = 'https://calendar.yahoo.com/?' + queryString.stringify({
    v: '60',
    in_loc: location,
    title: title,
    desc: description,
    st: formatISO(startDate, { format: 'basic' }),
    et: formatISO(endDate, { format: 'basic' }),
  });

  return (
    <DropdownButton
      buttonClassName={buttonClassName}
      text={buttonText}
      menuAnchor="right"
      prependIcon={showIcon ? <Icon i={['far', 'calendar']} /> : null}
      appendIcon={null}
      usePortal
    >
      <div className="link-list py-3" style={{ width: 250 }}>
        <a
          href={googleUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="link-list-item px-3"
          data-calendar="google"
          data-event-name={`${eventId} - ${title}`}
        >
          <Icon i={['fab', 'google']} />
          <span>Google Calendar</span>
        </a>

        <a
          href={icsUrl}
          className="link-list-item px-3"
          data-calendar="apple"
          data-event-name={`${eventId} - ${title}`}
        >
          <Icon i={['fab', 'apple']} />
          <span>Apple Calendar</span>
        </a>

        <a
          href={icsUrl}
          className="link-list-item px-3"
          data-calendar="outlook"
          data-event-name={`${eventId} - ${title}`}
        >
          <Icon i={['faz', 'outlook']} />
          <span>Outlook</span>
        </a>

        <a
          href={outlookDotComUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="link-list-item px-3"
          data-calendar="outlook-com"
          data-event-name={`${eventId} - ${title}`}
        >
          <Icon i={['faz', 'outlook']} />
          <span>Outlook.com</span>
        </a>

        <a
          href={office365Url}
          target="_blank"
          rel="noopener noreferrer"
          className="link-list-item px-3"
          data-calendar="office-365"
          data-event-name={`${eventId} - ${title}`}
        >
          <Icon i={['faz', 'office365']} />
          <span>Office365</span>
        </a>

        <a
          href={yahooUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="link-list-item px-3"
          data-calendar="yahoo"
          data-event-name={`${eventId} - ${title}`}
        >
          <Icon i={['fab', 'yahoo']} />
          <span>Yahoo Calendar</span>
        </a>
      </div>
    </DropdownButton>
  );
};

AddToCalendar.propTypes = {
  showIcon: PropTypes.bool,
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  eventId: PropTypes.number,
  eventUrl: PropTypes.string,
};

export default AddToCalendar;

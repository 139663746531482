import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/Icon';

const PoiMapPopup = ({
  className,
  style,
  id,
  name,
  shortAddress,
  absoluteUrl,
}) => {
  const handleCardClick = () => window.location.href = absoluteUrl;

  return (
    <div
      className={classNames('listings-card', className)}
      style={style}
      onClick={handleCardClick}
      data-ref="map-popup"
      data-label={`${id} - ${name}`}
    >
      <section className="lc-top">
        <div className="lc-main p-0">
          <div>
            <h6><a href={absoluteUrl}>{name}</a></h6>

            <div className="mb-2 lh-sm d-flex align-items-center">
              <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                <Icon i="map-marker-alt" size="sm" />
              </div>
              <div>{shortAddress}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

PoiMapPopup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.number,
  name: PropTypes.string,
  absoluteUrl: PropTypes.string,
  shortAddress: PropTypes.string,
};

export default PoiMapPopup;

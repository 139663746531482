import React from 'react';
import PropTypes from 'prop-types';
import { format, parse, isBefore } from 'date-fns';

const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const PoiHoursToday = ({ className, style = {}, openTimes }) => {
  const now = new Date();
  const todayRows = openTimes.filter(({ dayOfWeek }) => {
    const day = DAYS_OF_WEEK[dayOfWeek];
    return day === format(now, 'E');
  });

  let isOpenNow = false;
  const formattedTimes = todayRows.map(({ closed, timeStart, timeEnd }) => {
    const [timeStartAsDate, timeEndAsDate] = [timeStart, timeEnd].map(t => t && parse(t, 'HH:mm:ss', now));
    if (isBefore(timeStartAsDate, now) && isBefore(now, timeEndAsDate)) {
      isOpenNow = true;
    }
    const openTimesString = timeStartAsDate && timeEndAsDate && `${format(timeStartAsDate, 'p')} – ${format(timeEndAsDate, 'p')}`.replace(/:00/g, '');
    return closed ? 'Closed' : openTimesString;
  }).join(', ');

  return (
    <div className={className} style={style}>
      <span>{formattedTimes}</span>
      {isOpenNow && <strong className="text-success ms-2">Open Now!</strong>}
    </div>
  );
};

PoiHoursToday.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  openTimes: PropTypes.arrayOf(PropTypes.shape({
    dayOfWeek: PropTypes.number,
    closed: PropTypes.bool,
    /** Time formatted as HH:MM:SS (24-hour clock) */
    timeStart: PropTypes.string,
    /** Time formatted as HH:MM:SS (24-hour clock) */
    timeEnd: PropTypes.string,
  })),
};

export default PoiHoursToday;

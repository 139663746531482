import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const AdSenseCard = ({ client, layoutKey, slot, format = 'fluid' }) => {
  client = client || window.ADSENSE_CLIENT_ID;
  const isTest = document.location.host !== 'zeitcaster.com';

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="listings-card">
      <section className="lc-top">
        <div className="lc-main">
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-format={format}
            data-ad-layout-key={layoutKey}
            data-ad-client={client}
            data-ad-slot={slot}
            data-adtest={isTest ? 'on' : 'off'}
          />
        </div>
      </section>
    </div>
  );
};

AdSenseCard.propTypes = {
  client: PropTypes.string,
  layoutKey: PropTypes.string,
  slot: PropTypes.number,
  format: PropTypes.string,
};

export default AdSenseCard;

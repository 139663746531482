import React from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import striptags from 'striptags';
import { isValid, parseISO } from 'date-fns';
import { useWidgetId } from 'hooks';
import StickyHeader from 'components/StickyHeader';
import ShareModal from 'components/ShareModal';
import AddToCalendar from 'components/AddToCalendar';
import BookmarkToggleButton from 'components/BookmarkToggleButton';
import LivestreamButton from 'components/LivestreamButton';

const EventStickyHeader = ({
  eventData,
  poiAddress,
  urlEmail,
  urlFacebook,
  urlTwitter,
  startDate,
  endDate,
}) => {
  let { id, name, description, absoluteUrl, pointOfInterest, onlineUrl } = camelize(eventData);
  const url = `${window.location.protocol}//${window.location.host}${absoluteUrl}`;
  description = striptags(description).trim();
  if (description.length > 500) {
    description = description.substring(0, 500) + '…';
  }
  description = [description, url].filter(s => !!s).join('\n');
  const isWidget = !!useWidgetId();

  if (!isValid(startDate)) startDate = parseISO(startDate);
  if (endDate && !isValid(endDate)) endDate = parseISO(endDate);

  return (
    <StickyHeader>
      <div className="container h-100 d-flex align-items-center justify-content-between">
        <h6 className="fw-black m-0">{name}</h6>
        <div className="d-flex align-items-center">
          {onlineUrl && (
            <LivestreamButton
              url={onlineUrl}
              start={startDate}
              endDate={endDate}
              showCountdown
            />
          )}
          <ShareModal
            className="ms-3"
            buttonText=""
            urlEmail={urlEmail}
            urlFacebook={urlFacebook}
            urlTwitter={urlTwitter}
            url={url}
          />

          {!isWidget && (
            <BookmarkToggleButton
              className="ms-3"
              objectType="event"
              objectId={id}
              buttonText=""
            />
          )}

          <AddToCalendar
            buttonClassName="ms-3"
            showIcon
            title={name}
            description={description}
            location={`${pointOfInterest.name}, ${poiAddress}`}
            startDate={startDate}
            endDate={endDate}
            eventId={eventData.id}
          />
        </div>
      </div>
    </StickyHeader>
  );
};

EventStickyHeader.propTypes = {
  eventData: PropTypes.object.isRequired,
  poiAddress: PropTypes.string,
  urlFacebook: PropTypes.string,
  urlTwitter: PropTypes.string,
  urlEmail: PropTypes.string,
  url: PropTypes.string,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]).isRequired,
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
};

export default EventStickyHeader;

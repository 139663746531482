import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBootstrapBreakpoint } from 'hooks';

const SavedItemsEmptyMessage = ({
  imgUrl,
  primaryText,
  secondaryText,
  buttonText,
  buttonUrl,
  buttonOnClick,
}) => {
  const isDesktop = useBootstrapBreakpoint('up-md');
  const handleButtonClick = evt => {
    if (buttonOnClick) {
      evt.preventDefault();
      buttonOnClick();
    }
  };
  const buttonProps = buttonOnClick ? {
    onClick: handleButtonClick,
    href: buttonUrl || '#',
  } : {
    href: buttonUrl,
  };
  const showButton = !!buttonText && (!!buttonUrl || !!buttonOnClick);

  return (
    <div className="saved-items-empty-message">
      {!!imgUrl && <img className="saved-items-empty-message-image" src={imgUrl} />}
      <div className="saved-items-empty-message-text-container">
        {!!secondaryText && <div className="saved-items-empty-message-text-secondary mb-3">{secondaryText}</div>}
        {!!primaryText && <h5 className="saved-items-empty-message-text-primary">{primaryText}</h5>}
        {showButton && (
          <a
            className={classNames('btn-z', 'primary', isDesktop && 'lg')}
            {...buttonProps}
          >
            {buttonText}
          </a>
        )}
      </div>
    </div>
  );
};

SavedItemsEmptyMessage.propTypes = {
  imgUrl: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

export default SavedItemsEmptyMessage;

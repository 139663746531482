import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'hooks';
import { Line } from 'react-chartjs-2';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  isAfter,
  isBefore,
} from 'date-fns';
import {
  QUERY_PARAM_FMT,
  DAY_LABEL_FMT,
  MONTH_LABEL_FMT,
  TOOLTIP_FMT,
  CHART_METRIC_CHOICES,
  CHART_UNIT_CHOICES,
} from './constants';

const Chart = ({ startDate, endDate, chartMetric, chartUnit, data }) => {
  let { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 500,
    refreshOptions: {
      leading: true,
      trailing: true,
    },
  });
  width = width && Math.round(width);

  const memoizedData = useMemo(() => data, [JSON.stringify(data)]);

  if (!startDate || !endDate || !memoizedData) {
    return <div ref={ref} className="chart-container" />;
  }

  const { day, month } = CHART_UNIT_CHOICES;

  let labels = [];
  let tooltipLabels = [];
  let values = [];

  if (chartUnit === day) {
    const dates = eachDayOfInterval({ start: startDate, end: endDate });
    labels = dates.map(d => format(d, DAY_LABEL_FMT));
    tooltipLabels = dates.map(d => format(d, TOOLTIP_FMT));
    values = dates.map(d => {
      const dateString = format(d, QUERY_PARAM_FMT);
      return memoizedData[chartUnit][dateString] ? memoizedData[chartUnit][dateString][chartMetric] : 0;
    });
  } else if (chartUnit === month) {
    const dates = eachMonthOfInterval({ start: startDate, end: endDate });
    labels = dates.map(d => format(d, MONTH_LABEL_FMT));
    tooltipLabels = dates.map(d => {
      let sd = d;
      let ed = endOfMonth(d);
      if (isAfter(startDate, d)) {
        sd = startDate;
      } else if (isBefore(endDate, ed)) {
        ed = endDate;
      }
      return `${format(sd, TOOLTIP_FMT)} – ${format(ed, TOOLTIP_FMT)}`;
    });
    values = dates.map(d => {
      const dateString = format(d, 'yyyy-MM');
      return memoizedData[chartUnit][dateString] ? memoizedData[chartUnit][dateString][chartMetric] : 0;
    });
  }

  const chartData = {
    labels,
    datasets: [{
      backgroundColor: '#1E3DFE',
      borderColor: '#1E3DFE',
      label: '',
      lineTension: 0,
      pointRadius: 4,
      pointHoverRadius: 6,
      fill: false,
      data: values,
    }],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          title: tooltipItem => tooltipLabels[tooltipItem[0].dataIndex],
        },
      },
    },
    scales: {
      xAxis: {
        grid: {
          drawOnChartArea: false,
          offset: true,
          tickLength: 8,
        },
      },
      yAxis: {
        beginAtZero: true,
        grid: { tickLength: 8 },
        suggestedMax: 50,
      },
    },
  };

  return (
    <div ref={ref} className="chart-container">
      {width && <Line data={chartData} options={chartOptions} width={width} height={250} />}
    </div>
  );
};

Chart.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  chartMetric: PropTypes.oneOf(Object.values(CHART_METRIC_CHOICES)),
  chartUnit: PropTypes.oneOf(Object.values(CHART_UNIT_CHOICES)),
  data: PropTypes.object,
};

export default Chart;

import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { useBootstrapBreakpoint, useGetAPI, useHistory, useToggle } from 'hooks';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import LoadingOverlay from 'components/LoadingOverlay';
import CalendarCard from 'components/CalendarCard';
import SavedItemsEmptyMessage from 'components/SavedItemsEmptyMessage';
import CalendarCreateOrUpdate from 'components/CalendarCreateOrUpdate';

const ORDER_UPDATED = '-updated';
const ORDER_CREATED = '-created';
const DEFAULT_ORDER = ORDER_UPDATED;

const CalendarList = ({ emptyImgUrl }) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();
  const { ordering = DEFAULT_ORDER } = queryParams || {};

  const [editCounter, setEditCounter] = useState(0);
  const incrementEditCounter = () => setEditCounter(oldState => oldState + 1);

  // Call API
  let path;
  if (typeof queryParams !== 'undefined') path = urls.apiWidgetList;
  const apiParams = { ordering };
  const { data, isFetching, error } = useGetAPI(path, {
    queryObject: apiParams,
    fullPath: true,
    extraDeps: [editCounter],
  });

  // Data cached in local state so we can update the UI immediately when an item is deleted.
  const [cachedData, setCachedData] = useState();
  useEffect(() => setCachedData(data), [data]);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleDeleteFailure = errorText => setErrorMessage(`Failed to delete item: ${errorText || 'unknown error'}`);
  const handleDeleteSuccess = objectId => setCachedData(oldState => oldState.filter(({ id }) => id !== objectId));

  // Editing
  const [showEditModal, toggleEditModal] = useToggle(false);
  const [editingObject, setEditingObject] = useState({});

  const editWidget = widget => {
    setEditingObject({
      id: widget.id,
      title: widget.title,
      search_id: widget.search.id,
    })
    toggleEditModal(true);
  };

  useEffect(() => {
    if (!showEditModal) {
      setEditingObject({});
    }
  }, [showEditModal]);

  const editModal = (
    <CalendarCreateOrUpdate
      calendar={editingObject}
      isOpen={showEditModal}
      onRequestClose={toggleEditModal}
      onSave={incrementEditCounter}
    />
  );

  const handleAddClick = () => {
    setEditingObject({});
    toggleEditModal(true);
  }

  const createButton = createPortal(
    <button type="button" className="btn-z primary" onClick={handleAddClick}>Add Calendar</button>,
    document.getElementById('add-calendar-btn'),
  )

  const orderingMenuRef = useRef();
  const handleOrderChange = (evt, ordering) => {
    evt.preventDefault();
    updateQueryParams({ ordering });
    orderingMenuRef.current.close();
  };

  // Reset scroll position when fetching new data
  useEffect(() => window.scrollTo(0, 0), [isFetching]);

  const isMobile = useBootstrapBreakpoint('down-md');

  const countLabel = !cachedData ? 'My' : cachedData.length;

  const orderingButtonLabel = isMobile ? null : (
    <>
      <strong className="text-light-alt">Sort by: </strong>
      <strong>{ordering === ORDER_UPDATED ? 'Last edited' : 'Last added'}</strong>
    </>
  );

  return (
    <div className="listings-container">
      {createButton}
      <LoadingOverlay show={isFetching} align="top" className="bg-lt" />
      {cachedData && cachedData.length === 0 ? (
        <SavedItemsEmptyMessage
          imgUrl={emptyImgUrl}
          primaryText="Create custom calendar widgets to showcase events on your own website."
          secondaryText="No calendars."
          buttonText="Add Calendar"
          buttonOnClick={handleAddClick}
        />
      ) : (
        <>
          <header>
            {!isMobile && <h2 className="lh-base m-0 fw-black">{countLabel} Calendar{(cachedData || []).length !== 1 && 's'}</h2>}
            <div className={classNames('d-flex', 'mb-1', 'align-items-baseline', isMobile ? 'justify-content-between' : 'justify-content-end')}>
              {isMobile && (
                <h4 className="lh-base m-0 fw-black">
                  {countLabel} Calendar{countLabel !== 1 && 's'}
                </h4>
              )}
              <DropdownButton
                ref={orderingMenuRef}
                text={orderingButtonLabel}
                appendIcon={isMobile ? <Icon i={['faz', 'sort']} /> : undefined}
                buttonClassName={classNames('clear', 'subtle-focus', 'borderless', isMobile && 'circle')}
                menuAnchor="right"
              >
                <div className="link-list py-3" style={{ width: 160 }}>
                  <a
                    href="#edited"
                    className={classNames('link-list-item', 'px-3', ordering === ORDER_UPDATED && 'color-primary')}
                    onClick={evt => handleOrderChange(evt, ORDER_UPDATED)}
                  >
                    <Icon i={['far', 'check']} style={{ visibility: ordering === ORDER_UPDATED ? 'visible' : 'hidden' }} />
                    <span>Last edited</span>
                  </a>

                  <a
                    href="#added"
                    className={classNames('link-list-item', 'px-3', ordering === ORDER_CREATED && 'color-primary')}
                    onClick={evt => handleOrderChange(evt, ORDER_CREATED)}
                  >
                    <Icon i={['far', 'check']} style={{ visibility: ordering === ORDER_CREATED ? 'visible' : 'hidden' }} />
                    <span>Last added</span>
                  </a>
                </div>
              </DropdownButton>
            </div>

            {!!error && (
              <div className="alert alert-danger mb-3" role="alert">
                Failed to fetch results. Please try your request again.
              </div>
            )}

            {!!errorMessage && (
              <div className="alert alert-danger mb-3" role="alert">
                {errorMessage}
              </div>
            )}
          </header>

          {(cachedData || []).map(widget => (
            <div key={widget.id} className="mb-3">
              <CalendarCard
                {...widget}
                useCompactStyle={isMobile}
                metaLabel={ordering === ORDER_CREATED ? 'created' : 'updated'}
                onEditClick={() => editWidget(widget)}
                onDeleteFailure={handleDeleteFailure}
                onDeleteSuccess={() => handleDeleteSuccess(widget.id)}
              />
            </div>
          ))}
        </>
      )}

      {editModal}
    </div>
  );
};

CalendarList.propTypes = {
  emptyImgUrl: PropTypes.string,
};

export default CalendarList;

import camelize from 'camelize';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { format as formatDate, getDay, parse as parseDate } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { nthOfMonth } from 'utils';
import { formatDateFieldValue } from '../DateField';
import { parseTimeFieldValue, formatTimeFieldValue } from '../TimeField';
import {
  FREQ_ONCE,
  FREQ_DAILY,
  FREQ_WEEKLY,
  FREQ_MONTHLY_DATE,
  FREQ_MONTHLY_SET_POS,
  FREQ_YEARLY,
  FREQ_CUSTOM,
} from './useFrequencyOptions';
import {
  CUSTOM_FREQ_DAILY,
  CUSTOM_FREQ_WEEKLY,
  CUSTOM_FREQ_MONTHLY,
  CUSTOM_MONTHLY_TYPE_DATE,
  CUSTOM_MONTHLY_TYPE_SET_POS,
} from './useCustomRecurrenceControls';
import {
  END_NONE,
  END_DATE,
  END_COUNT,
} from './EndOption';

// weekdays ordered according to date-fns `getDay()`
const weekdayMap = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];

const stripTimes = text => text.replaceAll(/(\d{8})T\d{6}Z/g, '$1');

export const hydrateValue = (values, dateStartStr) => values.reduce((result, value) => {
  // Format value from API for form
  const { id, dateRecur, timeStart, timeEnd } = camelize(value);
  const asUTC = date => utcToZonedTime(date, 'UTC');

  const startTime = formatTimeFieldValue(parseDate(timeStart, 'HH:mm:ss', new Date()));
  const endTime = timeEnd && formatTimeFieldValue(parseDate(timeEnd, 'HH:mm:ss', new Date()));

  const [, y, m, d] = dateStartStr.match(/^(\d{4})-(\d{2})-(\d{2})/).map(v => parseInt(v, 10));
  const defaultDtStart = datetime(y, m, d);
  const rs = rrulestr(dateRecur, { forceset: true, dtstart: datetime(y, m, d) });
  // TODO dtstart default option is not working here
  // https://github.com/jkbrzt/rrule/issues/612

  const newItems = [];

  rs.rrules().forEach(r => {
    // Fix for bug noted above
    r = new RRule({ ...r.origOptions, dtstart: r.origOptions.dtstart || defaultDtStart });

    let date = r.options.dtstart;
    try {
      // adjust date to date of first occurrence
      date = asUTC(r.all()[0]);
    } catch (err) {
      console.error(err);
    }
    const dateFormatted = formatDateFieldValue(date);

    const vals = { date: dateFormatted, startTime, endTime, exceptions: [] };

    const { freq, interval = 1, count, until, byweekday, bymonthday, bysetpos } = r.options;

    if (count) {
      vals.endType = END_COUNT;
      vals.endCount = count;
    } else if (until) {
      vals.endType = END_DATE;
      vals.endDate = formatDateFieldValue(asUTC(until));
    } else {
      vals.endType = END_NONE;
    }

    if (freq === RRule.DAILY && interval === 1) {
      vals.frequency = FREQ_DAILY;
    } else if (freq === RRule.WEEKLY && interval === 1 && byweekday.length === 1) {
      vals.frequency = FREQ_WEEKLY;
    } else if (freq === RRule.MONTHLY && interval === 1 && bymonthday.length === 1) {
      vals.frequency = FREQ_MONTHLY_DATE;
    } else if (freq === RRule.MONTHLY && interval === 1 && (bysetpos || []).length === 1) {
      vals.frequency = FREQ_MONTHLY_SET_POS;
    } else if (freq === RRule.YEARLY) {
      vals.frequency = FREQ_YEARLY;
    } else {
      vals.frequency = FREQ_CUSTOM;
      vals.customInterval = interval;
      vals.customFrequency = {
        [RRule.DAILY]: CUSTOM_FREQ_DAILY,
        [RRule.WEEKLY]: CUSTOM_FREQ_WEEKLY,
        [RRule.MONTHLY]: CUSTOM_FREQ_MONTHLY,
      }[freq];

      if (freq === RRule.WEEKLY) {
        vals.customWeeklyDays = byweekday.map(v => weekdayMap.findIndex(({ weekday }) => weekday === v));
      } else if (freq === RRule.MONTHLY) {
        vals.customMonthlyType = bymonthday.length ? CUSTOM_MONTHLY_TYPE_DATE : CUSTOM_MONTHLY_TYPE_SET_POS;
      }
    }

    vals.exceptions = rs.exdates().map(d => formatDateFieldValue(asUTC(d)));

    newItems.push(vals);
  });

  rs.rdates().forEach(d => {
    const date = formatDateFieldValue(asUTC(d));
    newItems.push({ date, startTime, endTime });
  });

  if (newItems.length > 0) {
    newItems[0].id = id;
  }
  return [...result, ...newItems];
}, []);

export const dehydrateValue = values => values.map(value => {
  // Format value from form for API

  const splitDateStr = dateStr => dateStr.split('/').map(v => parseInt(v));

  // Parse date and times
  const [month, day, year] = splitDateStr(value.date);
  const [startTime, endTime] = [value.startTime, value.endTime].map(v => (
    v ? formatDate(parseTimeFieldValue(v), 'HH:mm') : null
  ));
  const [hour, minute] = startTime.split(':').map(v => parseInt(v));
  const startDate = datetime(year, month, day, hour, minute);

  const result = {
    id: value.id,
    time_start: startTime,
    time_end: endTime,
  };

  // No recurrence - exit early
  if (value.frequency === FREQ_ONCE) {
    result.date_recur = `RDATE:${formatDate(startDate, 'yyyyMMdd')}`;
    return result;
  }

  const ruleOpts = {
    dtstart: startDate,
    wkst: RRule.SU,
  };

  // End options
  if (value.endType === END_DATE) {
    const [endMonth, endDay, endYear] = value.endDate.split('/').map(v => parseInt(v));
    ruleOpts.until = datetime(endYear, endMonth, endDay, 23, 59);
  } else if (value.endType === END_COUNT) {
    ruleOpts.count = parseInt(value.endCount);
  }

  // Interval - custom recurrence only
  if (value.frequency === FREQ_CUSTOM) {
    ruleOpts.interval = value.customInterval;
  }

  // Determine frequency type
  const fr = value.frequency;
  const cfr = fr === FREQ_CUSTOM && value.customFrequency;
  const isDaily = fr === FREQ_DAILY || cfr === CUSTOM_FREQ_DAILY;
  const isWeekly = fr === FREQ_WEEKLY || cfr === CUSTOM_FREQ_WEEKLY;
  const isMonthlyDate = fr === FREQ_MONTHLY_DATE || (cfr === CUSTOM_FREQ_MONTHLY && value.customMonthlyType === CUSTOM_MONTHLY_TYPE_DATE);
  const isMonthlySetPos = fr === FREQ_MONTHLY_SET_POS || (cfr === CUSTOM_FREQ_MONTHLY && value.customMonthlyType === CUSTOM_MONTHLY_TYPE_SET_POS);
  const isYearly = fr === FREQ_YEARLY;

  // Daily frequency
  if (isDaily) {
    ruleOpts.freq = RRule.DAILY;

  // Weekly frequency
  } else if (isWeekly) {
    ruleOpts.freq = RRule.WEEKLY;
    const days = [weekdayMap[getDay(startDate)]];
    if (cfr) {
      value.customWeeklyDays.map(v => weekdayMap[v]).forEach(val => {
        if (!days.includes(val)) days.push(val);
      });
    }
    ruleOpts.byweekday = days;

  // Monthly frequency - by date
  } else if (isMonthlyDate) {
    ruleOpts.freq = RRule.MONTHLY;
    ruleOpts.bymonthday = day;

  // Monthly frequency - by set position
  } else if (isMonthlySetPos) {
    ruleOpts.freq = RRule.MONTHLY;
    const [n, isLast] = nthOfMonth(startDate);
    ruleOpts.byweekday = weekdayMap[getDay(startDate)];
    ruleOpts.bysetpos = isLast ? -1 : n;

  // Yearly frequency
  } else if (isYearly) {
    ruleOpts.freq = RRule.YEARLY;
    ruleOpts.bymonth = month;
    ruleOpts.bymonthday = day;
  }

  // Create ruleset
  const rruleSet = new RRuleSet();
  rruleSet.rrule(new RRule({ ...ruleOpts }));

  // Exceptions
  value.exceptions.forEach(dateStr => {
    const [month, day, year] = splitDateStr(dateStr);
    rruleSet.exdate(datetime(year, month, day));
  });

  // Stringify
  result.date_recur = stripTimes(rruleSet.toString());

  return result;
});

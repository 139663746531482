import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { distanceOptions, defaultDistance } from 'app-constants';
import { useFilterContext } from 'context';
import RadioList from 'components/RadioList';
import DropdownButton from 'components/DropdownButton';

export const DISTANCE_UNITS_MI = 'mi';
export const DISTANCE_UNITS_KM = 'km';
export const DISTANCE_UNITS_LABELS = {
  [DISTANCE_UNITS_MI]: 'miles',
  [DISTANCE_UNITS_KM]: 'kilometers',
};

const GLOBAL_DISTANCE = {
  km: 25000,
  mi: 15000,
};

const DistanceFilter = ({
  useMobileStyle = false,
  distanceUnits = DISTANCE_UNITS_MI,
  defaultValue = defaultDistance.event,
  value,
  onChange,
}) => {
  const options = useMemo(() => [
    ...distanceOptions.map(val => [val, `${val} ${DISTANCE_UNITS_LABELS[distanceUnits]}`]),
    [GLOBAL_DISTANCE[distanceUnits], 'Global'],
  ], [distanceUnits]);

  defaultValue = defaultValue || options[0][0];
  value = value || defaultValue;

  const handleChange = val => onChange(val === defaultValue ? null : val);

  const isGlobal = Object.values(GLOBAL_DISTANCE).includes(value);
  const activeOptionLabel = isGlobal ? 'Global' : `${value} ${distanceUnits}`;

  const { updateFilterContext } = useFilterContext();
  useEffect(() => {
    const radiusLabel = isGlobal ? null : `${value} ${DISTANCE_UNITS_LABELS[distanceUnits]}`;
    updateFilterContext({ radiusLabel, radius: value });
  }, [value, distanceUnits]);

  const optionList = (
    <RadioList
      listId="filter-radius"
      className={useMobileStyle ? 'invert stretch' : ''}
      options={options}
      value={value}
      onChange={handleChange}
    />
  );

  return useMobileStyle ? optionList : (
    <DropdownButton
      className="me-2"
      text={activeOptionLabel}
      isHighlighted={value !== defaultValue}
      closeOnChanged={value}
    >
      <div className="px-5 py-4" style={{ width: 250 }}>
        {optionList}
      </div>
    </DropdownButton>
  );
};

DistanceFilter.propTypes = {
  useMobileStyle: PropTypes.bool,
  distanceUnits: PropTypes.oneOf([DISTANCE_UNITS_MI, DISTANCE_UNITS_KM]),
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default DistanceFilter;

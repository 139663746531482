import React, { useEffect, useRef, createRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  addMinutes,
  format as formatDate,
  isBefore,
  isAfter,
  isEqual,
  isSameDay,
  isValid,
  parse as parseDate,
} from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useClickOutside, useToggle } from 'hooks';
import useErrorOrHelpText from './useErrorOrHelpText';
import ClearButton from './ClearButton';
import HelpPopup from 'components/HelpPopup';
import SelectHandle from './SelectHandle';

export const TIME_FORMAT = 'p';
export const parseTimeFieldValue = (val, date = new Date()) => parseDate(val, TIME_FORMAT, date);
export const formatTimeFieldValue = val => formatDate(val, TIME_FORMAT);

const TimeField = ({
  name,
  label,
  helpText,
  helpPopupContent,
  required = false,
  disabled = false,
  defaultScrollVal = '9:00 AM',
  validate = () => true,
  registerOpts = {},
  formMethods,
}) => {
  const { register, watch, setValue, setFocus, resetField } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const val = watch(name) || '';
  const setVal = (value, opts) => setValue(name, value, opts);

  const timeOptionRefs = useRef({});
  const timeOptions = useMemo(() => {
    const options = [];
    const start = new Date(2020, 0, 1, 0);
    let cur = start;
    while (isSameDay(cur, start)) {
      const value = formatTimeFieldValue(cur);
      const ref = createRef();
      timeOptionRefs.current[value] = ref;
      options.push([value, ref]);
      cur = addMinutes(cur, 30);
    }
    return options;
  }, []);

  const autoCorrect = value => {
    const match = (value || '').trim().toUpperCase().match(/^(\d{1,2})(?::?(\d{2}))?(?: ?([aApP])[mM]?)?$/);
    if (!match) return value;

    let [, h, m, p] = match;
    h = parseInt(h);
    m = parseInt(m || 0);
    if (h > 12) {
      h = h - 12;
      p = 'PM';
    } else if (!p) {
      p = 'AM';
    }
    if (p.length === 1) p += 'M';

    const dateVal = parseTimeFieldValue(`${h}:${m} ${p}`);
    if (isValid(dateVal)) {
      return formatTimeFieldValue(dateVal);
    }
    return value;
  };

  const doValidate = value => {
    const dateVal = parseTimeFieldValue(value);
    if (value !== '' && !isValid(dateVal)) {
      return 'Enter a valid time.';
    }

    return validate(value);
  };

  const inputRef = useRef();
  const { ref, onBlur, onChange, ...inputProps } = register(name, {
    required: { value: required, message: 'This field is required.' },
    disabled,
    validate: doValidate,
    ...registerOpts,
  });

  const containerRef = useRef();
  const [menuVisible, toggleMenuVisible] = useToggle(false);
  const handleClickOutside = evt => {
    if (menuVisible) toggleMenuVisible();
  };
  useClickOutside(containerRef, handleClickOutside);

  const findOption = (prev = false) => (val, matchExact = false) => {
    if (!val) return null;
    const d = new Date();
    const parse = s => parseDate(s, TIME_FORMAT, d);
    const targetDate = parse(autoCorrect(val));
    if (isValid(targetDate)) {
      if (matchExact) {
        const exact = timeOptions.find(([opt]) => isEqual(parse(opt), targetDate));
        if (exact) return exact;
      }

      return prev
        ? timeOptions.filter(([opt]) => isBefore(parse(opt), targetDate)).slice(-1)[0]
        : timeOptions.find(([opt]) => isAfter(parse(opt), targetDate));
    }
  };

  const findNextOption = findOption();
  const findPrevOption = findOption(true);

  const scrollToVal = val => {
    const targetVal = val || defaultScrollVal;
    const matchingOpt = timeOptions.find(([opt]) => opt === targetVal) || findNextOption(targetVal);

    if (matchingOpt) {
      const firstOptRef = timeOptions[0][1];
      const [, optRef] = matchingOpt;
      const offset = optRef.current.offsetTop - firstOptRef.current.offsetTop;
      optRef.current.parentNode.scrollTop = offset;
    }
  };

  useEffect(() => {
    if (menuVisible) {
      scrollToVal(val);
    }
  }, [menuVisible]);

  const handleKeyDown = evt => {
    let match;
    if (evt.key === 'ArrowDown') {
      match = val ? findNextOption(val) : findNextOption(defaultScrollVal, true);
    } else if (evt.key === 'ArrowUp') {
      match = findPrevOption(val);
    } else if (evt.key === 'Escape') {
      inputRef.current.blur();
    }

    if (match) {
      setVal(match[0]);
      scrollToVal(match[0]);
    }
  };

  const handleOptionSelect = val => {
    setVal(val, { shouldValidate: true });
    setTimeout(toggleMenuVisible, 100);
  };

  const handleChange = evt => {
    onChange(evt);
    // adjust menu scroll position dynamically as input value is changed
    const { value } = evt.target;
    if (value) {
      const [matchingOpt] = timeOptions.find(([opt]) => opt.startsWith(value)) || [];
      if (matchingOpt) {
        scrollToVal(matchingOpt);
      }
    }
  };

  const [isFocused, toggleFocused] = useToggle(false);
  const handleFocus = () => {
    toggleFocused(true);
    toggleMenuVisible(true);
  };
  const handleBlur = evt => {
    const correctedVal = autoCorrect(val);
    if (correctedVal !== val) {
      setVal(correctedVal, { shouldValidate: true });
    }
    setTimeout(() => {
      toggleFocused(false);
      toggleMenuVisible(false);
      onBlur(evt);
    }, 100);
  };

  const handleSelectHandleClick = () => {
    if (isFocused) {
      toggleMenuVisible(false);
    } else {
      setFocus(name);
    }
  };

  const handleClearClick = () => resetField(name);

  const wrapperClasses = classNames({
    'z-form-input-wrap': true,
    'z-form-select': true,
    focused: isFocused,
    required,
    'has-val': !!val,
    invalid: hasError,
  });

  return (
    <div className={wrapperClasses} ref={containerRef}>
      <div style={{ position: 'relative' }}>
        <input
          ref={el => {
            ref(el);
            inputRef.current = el;
          }}
          type="text"
          className={classNames('z-form-input', disabled && 'z-form-disabled')}
          maxLength={8}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          {...inputProps}
        />
        {label && (
          <label className={classNames('z-form-input-label', disabled && 'z-form-disabled')}>
            {label}
          </label>
        )}
        {!!val && <ClearButton onClick={handleClearClick} />}
        {!!helpPopupContent && <HelpPopup>{helpPopupContent}</HelpPopup>}
        <SelectHandle onClick={handleSelectHandleClick} />
        <div className="z-form-select-menu" tabIndex="-1" style={{ display: menuVisible ? 'block' : 'none', minHeight: 100 }}>
          {timeOptions.map(([opt, ref]) => (
            <div
              key={opt}
              ref={ref}
              className={classNames('z-form-select-option', val === opt && 'selected')}
              onPointerDown={() => handleOptionSelect(opt)}
            >
              {opt}
            </div>
          ))}
        </div>
      </div>
      <div className="z-form-hint-container">
        {errorOrHelpText}
      </div>
    </div>
  );
};

TimeField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  helpPopupContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultScrollVal: PropTypes.string,
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default TimeField;

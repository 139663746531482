import React from 'react';
import PropTypes from 'prop-types';

const ClearButton = ({
  size = 12,
  style = {},
  onClick,
}) => (
  <div
    className="z-form-clear-button"
    style={{ height: size + 6, width: size + 6, ...style }}
    onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
    >
      <path d="M10.708 2.707 7.415 6l3.293 3.293a1 1 0 1 1-1.414 1.414L6 7.414l-3.293 3.293a1 1 0 1 1-1.414-1.414L4.587 6 1.294 2.707a1 1 0 1 1 1.414-1.414L6 4.586l3.293-3.293a1 1 0 1 1 1.414 1.414Z" />
    </svg>
  </div>
);

ClearButton.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default ClearButton;

import themeColors from 'styles/themeColors.module.scss';

export default themeColors;

// The purpose of this file is to allow the SASS color variables exported in
// themeColors.module.scss to be used in JavaScript files, like so:
//
// import colors from 'themeColors';
// ...
// <div style={{ backgroundColor: colors.primary }}>foo</div>

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { parseISO, isValid } from 'date-fns';
import EventDateSummary from 'components/EventDateSummary';
import Icon from 'components/Icon';

const EventMapPopup = ({
  className,
  style,
  id,
  name,
  nextEventOccurrence,
  occurences = [],
  absoluteUrl,
  isOnline,
  price,
  pointOfInterest,
  searchParams,
}) => {
  const searchString = searchParams && encodeURIComponent(queryString.stringify(searchParams, {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  }));
  const destUrl = searchParams ? `${absoluteUrl}?search=${searchString}` : absoluteUrl;
  const handleCardClick = () => window.location.href = destUrl;

  let startTimestamp;
  let endTimestamp;

  if (nextEventOccurrence) {
    [startTimestamp, endTimestamp] = nextEventOccurrence.timestamps[0];
  } else {
    ({ timestampStart: startTimestamp, timestampEnd: endTimestamp } = occurences[0] || {});
  }

  const startTime = parseISO(startTimestamp);
  let endTime = parseISO(endTimestamp);
  if (!isValid(startTime)) return null; // short circuit if event has no date.
  if (!isValid(endTime)) endTime = null;

  return (
    <div
      className={classNames('listings-card', className)}
      style={style}
      onClick={handleCardClick}
      data-ref="map-popup"
      data-label={`${id} - ${name}`}
    >
      <section className="lc-top">
        <div className="lc-main p-0">
          <div>
            <h6><a href={destUrl}>{name}</a></h6>

            <div className="mb-2 lh-sm d-flex align-items-center">
              <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                <Icon i="map-marker-alt" size="sm" />
              </div>
              <div>{pointOfInterest.name}</div>
            </div>

            <EventDateSummary
              start={startTime}
              end={endTime}
              timeZone={isOnline ? null : pointOfInterest.timeZone}
            />

            {price && price.value && (
              <div className="mt-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i={['faz', 'price']} size="sm" />
                </div>
                <div>{price.label}</div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

EventMapPopup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.number,
  name: PropTypes.string,
  nextEventOccurrence: PropTypes.shape({
    eventDate: PropTypes.string,
    times: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    timestamps: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  occurences: PropTypes.arrayOf(PropTypes.shape({
    timestampStart: PropTypes.string,
    timestampEnd: PropTypes.string,
  })),
  absoluteUrl: PropTypes.string,
  isOnline: PropTypes.bool,
  price: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  pointOfInterest: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    absoluteUrl: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    countryCode: PropTypes.string,
    timeZone: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    shortAddress: PropTypes.string,
  }),
  searchParams: PropTypes.object,
};

export default EventMapPopup;

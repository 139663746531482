import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const { debug, options, user } = window.SENTRY_CONF;

if (process.env.NODE_ENV !== 'development' || debug) {
  Sentry.init({
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ...options,
  });

  if (user) {
    Sentry.configureScope(scope => scope.setUser(user));
  }
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { getCsrfToken } from 'utils';
import { useResizeDetector, useTrackImpression } from 'hooks';
import ZeitcasterLogo from 'components/ZeitcasterLogo';


const SearchAd = ({
  id,
  title,
  description,
  image,
  url,
  buttonText,
  useCompactStyle = false,
  sendPing = true,
}) => {
  const { width, ref } = useResizeDetector({ handleHeight: false });

  useEffect(() => {
    if (sendPing) {
      fetch(urls.searchAdPing, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'X-CSRFToken': getCsrfToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
    }
  }, []);

  const trackerRef = useTrackImpression('search_ad_impression', {
    adTitle: title,
    adId: id,
    adUrl: url,
  });

  const handleCardClick = () => window.location.href = url;

  const fullWidthImage = (width && width < 500) || false;
  const iconContainerStyle = {
    backgroundImage: image ? `url('${image}')` : 'unset',
  };
  if (fullWidthImage) {
    iconContainerStyle.height = Math.round(width / 2);
  }
  const iconContainerClasses = classNames({
    'lc-icon': true,
    sm: useCompactStyle,
    'full-width': fullWidthImage,
  });

  return (
    <div
      ref={ref}
      className="listings-card search-promo"
      onClick={handleCardClick}
      data-id={id}
      data-title={title}
      data-url={url}
    >
      <section ref={trackerRef} className="lc-top">
        <div className={iconContainerClasses} style={iconContainerStyle}>
          <a href={url} />
        </div>

        <div className="lc-main">
          <div>
            <h5 className="search-promo-title">{title}</h5>
            <div>{description}</div>
          </div>
        </div>
      </section>
      <footer>
        <a className="btn-z primary" href={url}>{buttonText}</a>
        <div className="logo-container"><ZeitcasterLogo height={20} /></div>
      </footer>
    </div>
  );
};

SearchAd.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  buttonText: PropTypes.string,
  useCompactStyle: PropTypes.bool,
  sendPing: PropTypes.bool,
};

export default SearchAd;

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import ReactModal from 'react-modal';


const Modal = ({
  contentClassName = '',
  overlayClassName = 'z-modal-overlay',
  interiorClassName = 'z-modal-interior',
  isOpen = false,
  onRequestClose = () => {},
  children,
}) => {
  const classes = {
    base: `z-modal-content ${contentClassName}`,
    afterOpen: 'z-modal-content--after-open',
    beforeClose: 'z-modal-content--before-close',
  };
  const overlayClasses = {
    base: overlayClassName,
    afterOpen: 'z-modal-overlay--after-open',
    beforeClose: 'z-modal-overlay--before-close',
  };

  const handleCloseClick = evt => {
    evt.preventDefault();
    onRequestClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={classes}
      overlayClassName={overlayClasses}
      ariaHideApp={false}
    >
      <div className={interiorClassName}>
        {children}
      </div>

      <a className="z-modal-close-button text-light" href="#close" onClick={handleCloseClick}>
        <Icon i={['far', 'times']} style={{ fontSize: 26 }} />
      </a>
    </ReactModal>
  );
};

export default Modal;

Modal.propTypes = {
  contentClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  interiorClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
};

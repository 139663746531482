import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const WeekdaySelector = ({ selectedDays = [], lockedDays = [], onChange = () => {} }) => {
  const days = [
    [0, 'S'],
    [1, 'M'],
    [2, 'T'],
    [3, 'W'],
    [4, 'T'],
    [5, 'F'],
    [6, 'S'],
  ];

  return (
    <div className="z-form-weekday-selector">
      {days.map(([val, label]) => {
        const isSelected = selectedDays.includes(val);
        const isLocked = lockedDays.includes(val);

        const classes = classNames({
          'z-form-weekday-selector-item': true,
          selected: isSelected || isLocked,
          locked: isLocked,
        });

        const handleClick = () => {
          if (!isLocked) {
            onChange(isSelected
              ? selectedDays.filter(d => d !== val)
              : [...selectedDays, val].sort());
          }
        };

        return (
          <div key={val} className={classes} onClick={handleClick}>
            {label}
            {isLocked && (
              <div className="lock-icon">
                <Icon i="lock" style={{ fontSize: 16 }} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

WeekdaySelector.propTypes = {
  selectedDays: PropTypes.arrayOf(PropTypes.number),
  lockedDays: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export default WeekdaySelector;

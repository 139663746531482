import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { useToggle } from 'hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LoadingOverlay from 'components/LoadingOverlay';
import Modal from 'components/Modal';

const COUNT_OPTIONS = [10, 20, 50];
const DEFAULT_COUNT = 10;

const SavedSearchTextExport = ({ searchId, calendarId, utmCampaign, utmSource = 'search-export', isOpen, onRequestClose }) => {
  const [copied, toggleCopied] = useToggle(false);
  const [isFetching, setIsFetching] = useState(false);
  const [eventData, setEventData] = useState(null);

  const [count, setCount] = useState(DEFAULT_COUNT);

  // Reset "copied" state when modal is closed.
  useEffect(() => {
    if (isOpen) {
      fetchData();
    } else {
      toggleCopied(false);
      setEventData(null);
    }
  }, [isOpen, count]);

  const fetchData = () => {
    setIsFetching(true);

    fetch(`${urls.savedSearchBase}${searchId}/results/?count=${count}`, {
      credentials: 'include',
    })
      .then(response => {
        setIsFetching(false);
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then(data => setEventData(data))
      .catch(err => {
        console.error(err);
        setEventData(null);
      });
  };

  const resultText = (eventData || []).map(event => (
    [
      event.name,
      event.point_of_interest.name,
      event.next_event_occurrence_formatted,
      `${window.location.origin}${event.absolute_url}?utm_source=${utmSource}&utm_campaign=${utmCampaign || searchId}`,
    ].join('\n')
  )).join('\n\n');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentClassName="p-3"
      interiorClassName="z-modal-interior wide"
      ariaHideApp={false}
    >
      <h4 className="mb-3">Export Saved Search Results</h4>

      {!!eventData && eventData.length === 0 && (
        <div className="alert alert-warning my-3" role="alert">
          No results found for this search.
        </div>
      )}

      <div style={{ position: 'relative' }}>
        <LoadingOverlay show={isFetching} />
        <div className="mb-4">
          <textarea
            className="form-control"
            style={{ fontSize: 13 }}
            rows={15}
            value={resultText}
            readOnly
          />
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span>Item limit:</span>
            {COUNT_OPTIONS.map(val => (
              <button
                type="button"
                className={classNames('btn-z circle', count === val ? 'primary' : 'outline')}
                key={val}
                style={{ marginLeft: 10 }}
                onClick={() => setCount(val)}
              >
                {val}
              </button>
            ))}
          </div>
          <CopyToClipboard
            onCopy={toggleCopied}
            text={resultText}
          >
            <button
              className="btn-z border secondary text-dark"
              type="button"
              data-action="text-export-copy"
              data-object-type={calendarId ? 'calendar' : 'search'}
              data-object-id={calendarId || searchId}
            >
              {copied ? <span className="text-success">Text Copied!</span> : <span>Copy Text</span>}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
};

export default SavedSearchTextExport;

SavedSearchTextExport.propTypes = {
  searchId: PropTypes.number,
  calendarId: PropTypes.number,
  utmSource: PropTypes.string,
  utmCampaign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

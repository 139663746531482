import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { round, formatDuration } from 'utils';
import PoiSelector from 'components/PoiSelector';
import useReportData from './useReportData';
import DateSelector from './DateSelector';
import MetricCards from './MetricCards';
import MetricTable from './MetricTable';

const StatsDashboard = ({ poiChoices }) => {
  const [activePoiId, setActivePoiId] = useState(null);
  useEffect(() => {
    if (poiChoices.length && !activePoiId) {
      setActivePoiId(poiChoices[0][0]);
    }
  }, [poiChoices]);

  const [dateRange, setDateRange] = useState([]);
  const [startDate, endDate] = dateRange;

  const handleDateChange = range => {
    setDateRange(range);
  }

  const formatInteger = val => parseInt(val, 10).toLocaleString();

  const {
    data: zcSummaryData,
    isFetching: zcSummaryFetching,
  } = useReportData('summaryStats', startDate, endDate);

  const {
    data: zcSummaryCustomEventData,
    isFetching: zcSummaryCustomEventFetching,
  } = useReportData('summaryCustomEvents', startDate, endDate);

  let zcCardData;
  if (zcSummaryData && zcSummaryCustomEventData) {
    let {
      activeUsers,
      averageSessionDuration,
      screenPageViews,
      screenPageViewsPerSession
    } = zcSummaryData.rows[0];
    activeUsers = formatInteger(activeUsers);
    averageSessionDuration = formatDuration(round(averageSessionDuration));
    screenPageViews = formatInteger(screenPageViews);
    screenPageViewsPerSession = round(screenPageViewsPerSession, 1).toLocaleString();

    let {
      'Event Card Impression': eventCardImpressions,
      'Event Detail Impression': eventDetailImpressions,
      'POI Card Impression': poiCardImpressions,
      'POI Detail Impression': poiDetailImpressions,
    } = zcSummaryCustomEventData.data;
    eventCardImpressions = formatInteger(eventCardImpressions || 0);
    eventDetailImpressions = formatInteger(eventDetailImpressions || 0);
    poiCardImpressions = formatInteger(poiCardImpressions || 0);
    poiDetailImpressions = formatInteger(poiDetailImpressions || 0);

    zcCardData = [
      ['Active Users', activeUsers],
      ['Avg. Session Duration', averageSessionDuration],
      ['Pageviews', screenPageViews],
      ['Pages / Session', screenPageViewsPerSession],
      ['Venue Card Views', poiCardImpressions],
      ['Venue Detail Views', poiDetailImpressions],
      ['Event Card Views', eventCardImpressions],
      ['Event Detail Views', eventDetailImpressions],
    ];
  }

  let poiCardData;
  let poiTableData;

  const poiTableConfig = [
    { label: 'Event', dataKey: 'name', sortable: true },
    { label: 'Card Views', dataKey: 'card', sortable: true, align: 'right' },
    { label: 'Detail Views', dataKey: 'detail', sortable: true, align: 'right' },
  ];

  const {
    data: poiData,
    isFetching: poiFetching,
  } = useReportData(!!activePoiId && 'poiCombined', startDate, endDate, activePoiId);

  if (poiData) {
    const {
      poiCardImpressions,
      poiDetailImpressions,
      eventCardImpressions,
      eventDetailImpressions,
    } = poiData.summary;

    poiCardData = [
      ['Venue Card Views', formatInteger(poiCardImpressions)],
      ['Venue Detail Views', formatInteger(poiDetailImpressions)],
      ['Event Card Views', formatInteger(eventCardImpressions)],
      ['Event Detail Views', formatInteger(eventDetailImpressions)],
    ];

    poiTableData = poiData.byEvent;
  }



  return (
    <div className="stats-dashboard-wrap">
      <div className="d-flex mb-4 justify-content-between align-items-center">
        <h2 className="mb-0 lh-base fw-black">Zeitcaster Data</h2>
        <DateSelector onChange={handleDateChange} />
      </div>
      <MetricCards data={zcCardData} isFetching={zcSummaryFetching || zcSummaryCustomEventFetching} />

      <hr className="my-4" />

      <div className="d-flex mb-4 flex-wrap justify-content-between align-items-center">
        <h2 className="mb-2 lh-base fw-black">My Venue Data</h2>
        {poiChoices.length > 1 && <PoiSelector choices={poiChoices} onChange={setActivePoiId} />}
      </div>
      <MetricCards data={poiCardData} isFetching={poiFetching} />
      {!!poiData && poiTableData.length > 0 && (
        <MetricTable
          className="table-bordered table-striped mt-5"
          style={{ borderRadius: 8 }}
          config={poiTableConfig}
          data={poiTableData}
          isFetching={poiFetching}
        />
      )}
    </div>
  );
};

StatsDashboard.propTypes = {
  poiChoices: PropTypes.arrayOf(PropTypes.array),
};

export default StatsDashboard;

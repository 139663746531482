import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGetAPI } from 'hooks';
import EventCard from 'components/EventCard';
import LoadingOverlay from 'components/LoadingOverlay';
import PillButton from 'components/PillButton';

/**
 * Renders event listings associated with the specified poi.
 */
const PoiEventList = ({ poiId, titleContent, defaultDisplayCount = 4, excludeEventIds = [], bgClassName = 'bg-white' }) => {
  // Call API
  const path = `poi/${poiId}/events/`;
  const { data: eventData, isFetching, error } = useGetAPI(path);

  const [showAll, setShowAll] = useState(false);

  const allEvents = (eventData || []).filter(event => !excludeEventIds.includes(event.id));
  const visibleEvents = showAll ? allEvents : allEvents.slice(0, defaultDisplayCount);

  const includeShowMoreButton = allEvents.length > defaultDisplayCount && !isFetching && !showAll;

  return (
    <div className="position-relative" style={{ minHeight: 200 }}>
      <LoadingOverlay show={isFetching} align="top" className={bgClassName} />

      {!!error && (
        <div className="alert alert-danger mb-3" role="alert">
          Failed to fetch event listings. Please try your request again.
        </div>
      )}

      {titleContent && visibleEvents.length > 0 && <div dangerouslySetInnerHTML={{ __html: titleContent }} />}

      {visibleEvents.map(event => (
        <div key={event.id} className="mb-3 col-sm-fullwidth">
          <EventCard className="border" useCompactStyle hidePoiInfo {...event} />
        </div>
      ))}

      {includeShowMoreButton && (
        <PillButton
          className="subtle-nobg mt-3"
          text="Show More"
          onClick={() => setShowAll(true)}
        />
      )}
    </div>
  );
};

PoiEventList.propTypes = {
  poiId: PropTypes.number.isRequired,
  /** Optional HTML to display before event listings */
  titleContent: PropTypes.string,
  /** Initial number of events to display */
  defaultDisplayCount: PropTypes.number,
  /** IDs of events to exclude from display */
  excludeEventIds: PropTypes.arrayOf(PropTypes.number),
  bgClassName: PropTypes.string,
};

export default PoiEventList;

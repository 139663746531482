import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useScrollPosition } from 'hooks';

const StickyHeader = ({ children }) => {
  const containerRef = useRef();
  const offsetTop = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const [windowScrollY, setWindowScrollY] = useState(window.scrollY);

  const handleScroll = ({ y }) => setWindowScrollY(y);
  useScrollPosition(handleScroll);

  useLayoutEffect(() => {
    offsetTop.current = window.scrollY + containerRef.current.getBoundingClientRect().top;
  }, []);

  useEffect(() => {
    if (!isVisible && windowScrollY >= offsetTop.current) {
      setIsVisible(true);
    } else if (isVisible && windowScrollY < offsetTop.current) {
      setIsVisible(false);
    }
  }, [windowScrollY]);

  const headerStyle = isVisible ? undefined : { height: 0 };

  return (
    <div className="sticky-header-container" ref={containerRef}>
      <header className="sticky-header" style={headerStyle}>
        <div className="bg-white h-100">
          {children}
        </div>
      </header>
    </div>
  );
};

StickyHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default StickyHeader;

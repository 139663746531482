import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'hooks';
import HelpPopup from 'components/HelpPopup';
import useErrorOrHelpText from './useErrorOrHelpText';

const NumberField = ({
  name,
  label,
  helpText,
  helpPopupContent,
  required = false,
  disabled = false,
  min,
  max,
  step = 'any',
  validate,
  registerOpts = {},
  formMethods,
}) => {
  const { register, watch } = formMethods || useFormContext();
  const { errorOrHelpText, hasError } = useErrorOrHelpText(name, helpText, formMethods);

  const boundsMessage = (min && max)
    ? `Value must be between ${min} and ${max}.`
    : null;
  let { onBlur, ...inputProps } = register(name, {
    required: { value: required, message: 'This field is required.' },
    disabled,
    min: { value: min, message: boundsMessage || `The minimum value is ${min}.` },
    max: { value: max, message: boundsMessage || `The maximum value is ${max}.` },
    validate,
    valueAsNumber: true,
    ...registerOpts,
  });
  const val = watch(name) || '';

  const [isFocused, toggleFocused] = useToggle(false);
  const handleFocus = () => toggleFocused(true);
  const handleBlur = evt => {
    toggleFocused(false);
    onBlur(evt);
  };

  const wrapperClasses = classNames({
    'z-form-input-wrap': true,
    focused: isFocused,
    required,
    'has-val': !!val,
    invalid: hasError,
  });

  inputProps = {
    className: classNames({
      'z-form-input': true,
      'z-form-disabled': disabled,
    }),
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...inputProps,
  };

  return (
    <div className={wrapperClasses}>
      <div style={{ position: 'relative' }}>
        <input type="number" min={min} max={max} step={step} {...inputProps} />
        {label && (
          <label className={classNames('z-form-input-label', !!disabled && 'z-form-disabled')}>
            {label}
          </label>
        )}
        {!!helpPopupContent && <HelpPopup>{helpPopupContent}</HelpPopup>}
      </div>
      <div className="z-form-hint-container">
        {errorOrHelpText}
      </div>
    </div>
  );
};

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  helpPopupContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['any'])]),
  validate: PropTypes.func,
  // Pass through additional options to react-hook-form `register` method:
  // https://react-hook-form.com/api/useform/register/
  registerOpts: PropTypes.object,
  formMethods: PropTypes.object,
};

export default NumberField;

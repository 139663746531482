import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const CategoryList = ({ options, value, onChange }) => {
  const handleOptionClick = val => {
    let newVal;
    if (val === null) {
      newVal = [];
    } else if (value.includes(val)) {
      newVal = value.filter(v => v !== val);
    } else {
      newVal = [...value, val];
    }
    onChange(newVal);
  };

  return (
    <div className="category-list">
      {options.map(({ value: optVal, label: optLabel, iconStyle }) => {
        let isSelected = value.includes(optVal);
        if (optVal === null) {
          isSelected = value.length === 0;
        }

        const handleClick = () => handleOptionClick(optVal);
        const classes = classNames('category-list-item', isSelected && 'selected');
        const attrs = {
          'data-list-id': 'filter-category',
          'data-option-value': optVal,
          'data-option-label': optLabel,
          'data-is-selected': isSelected,
        };

        return (
          <div key={optVal} className={classes} onClick={handleClick} {...attrs}>
            {!!iconStyle && <Icon i={iconStyle} className="fa-fw me-2" />}
            <span className="category-list-item-label">{optLabel}</span>
            <span className="category-list-item-check ms-3">
              <Icon i={['far', 'check']} />
            </span>
          </div>
        );
      })}
    </div>
  );
};

CategoryList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    label: PropTypes.string.isRequired,
    iconStyle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])),
  onChange: PropTypes.func.isRequired,
};

export default CategoryList;

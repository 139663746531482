import { useState, useEffect, useDebugValue } from 'react';
import camelize from 'camelize';
import queryString from 'query-string';
import snakeize from 'snakeize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';

function parseLinkHeader (headerContent) {
  // Parse the contents of API response "Link" header into an object with "rel" attributes
  // as keys and URLs as values.
  return headerContent && headerContent.split(',').reduce((result, str) => {
    let [link, rel] = str.split(';').map(s => s.trim());
    link = link.slice(1, -1);
    rel = rel.replace(/rel="(\w+)"/, '$1');
    result[rel] = link;
    return result;
  }, {});
}

function parsePaginationInfo (headerContent) {
  // Parse pagination details from the contents of API response "Link" header.
  const linkHeaderObj = parseLinkHeader(headerContent) || {};

  return Object.entries(linkHeaderObj).reduce((result, [rel, url]) => {
    const { query: queryObj } = queryString.parseUrl(url, { parseNumbers: true });
    switch (rel) {
      case 'next':
        result.nextPage = queryObj.page;
        break;
      case 'prev':
        result.prevPage = queryObj.page;
        break;
      case 'last':
        result.totalPages = queryObj.page;
        break;
      default:
        break;
    }
    return result;
  }, {});
}

export function useGetAPI (path, options = {}) {
  const {
    queryObject = {},
    appendData = false,
    fullPath = false,
    extraDeps = [],
    onSuccess = () => {},
    onError = () => {},
  } = options;

  const qs = queryString.stringify(snakeize(queryObject), {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  });
  const base = fullPath ? '/' : urls.apiBase;
  const url = (typeof path !== 'undefined') && urlJoin(base, path, '/', `?${qs}`);

  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    if (url) fetchData(appendData);
  }, [url, ...extraDeps]);

  const fetchData = append => {
    setIsFetching(true);
    setError(null);
    fetch(url)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);

        const updatedPaginationInfo = parsePaginationInfo(response.headers.get('Link')) || {};
        const count = response.headers.get('x-total-count');
        const totalItems = count && parseInt(count, 10);
        let { totalPages } = updatedPaginationInfo;
        if (!totalPages) {
          totalPages = queryObject.page_size ? Math.ceil(totalItems / queryObject.page_size) : 1;
        }

        setPagination({
          ...pagination,
          ...updatedPaginationInfo,
          totalItems,
          totalPages,
          curPage: queryObject.page || 1,
        });

        response.json().then(newData => {
          setData(append ? [...data, ...camelize(newData)] : camelize(newData));
          setIsFetching(false);
          onSuccess(data);
        });
      })
      .catch(err => {
        console.error(err);
        setError(err);
        setIsFetching(false);
        onError(err);
      });
  };

  const result = {
    data,
    isFetching,
    setIsFetching,
    error,
    pagination,
  };
  useDebugValue(result);
  return result;
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Renders a horizontal row of tiny navigation dots, commonly used with
 * responsive/mobile image galleries. A colored dot represents the active
 * item. Although the dots are technically clickable, they are tiny, so this
 * is intended mostly as a visual indicator, for use alongside another form of
 * navigation.
 */
const DotsNav = ({ style, count, activeIndex, onClick }) => {
  const arr = Array.from({ length: count });

  return (
    <nav className="dots-nav-container" style={style}>
      {arr.map((item, idx) => {
        const classes = classNames('dots-nav-item', idx === activeIndex && 'active');
        const handleClick = () => onClick && onClick(idx);
        return <div key={idx} className={classes} onClick={handleClick} />;
      })}
    </nav>
  );
};

DotsNav.propTypes = {
  style: PropTypes.object,
  /** number of dots */
  count: PropTypes.number.isRequired,
  /** index of the active dot (zero-based) */
  activeIndex: PropTypes.number,
  /** triggered when a dot is clicked, passes index */
  onClick: PropTypes.func,
};

export default DotsNav;

import React from 'react';
import PropTypes from 'prop-types';
import { format, formatISO, isToday } from 'date-fns';
import { defaultDistance } from 'app-constants';
import { useHistory } from 'hooks';
import { hasOwnProperty } from 'utils';
import PillButton from 'components/PillButton';
import SaveOrUpdateSearch from 'components/SaveOrUpdateSearch';
import {
  AgeGroupFilter,
  PriceFilter,
  CategoryFilter,
  DateFilter,
  DistanceFilter,
  IN_PERSON,
  ONLINE,
  OnlineFilter,
  parseFilterParams,
  DISTANCE_UNITS_MI,
  DISTANCE_UNITS_KM,
  VALID_FILTER_PARAMS,
} from 'components/filters';

const FilterBar = ({ objectType = 'event', distanceUnits = DISTANCE_UNITS_MI }) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();

  const isEvent = objectType === 'event';

  const handleDateChange = ([start, end]) => updateQueryParams({
    date_start: start && (isToday(start) ? formatISO(new Date()) : format(start, 'yyyy-MM-dd')),
    date_end: end && format(end, 'yyyy-MM-dd'),
    page: null,
  });
  const handleDistanceChange = val => updateQueryParams({ radius: val, page: null });
  const handleCategoryChange = val => updateQueryParams({ event_types: val, page: null });
  const handleAgeGroupChange = val => updateQueryParams({ age_groups: val, page: null });
  const handlePriceChange = val => updateQueryParams({ prices: val, page: null });
  const handleOnlineChange = val => updateQueryParams({
    is_online: val === ONLINE ? 't' : null,
    is_in_person: val === IN_PERSON ? 't' : null,
    page: null,
  });

  const handleResetClick = () => {
    const params = {
      ...VALID_FILTER_PARAMS.reduce((result, param) => ({ ...result, [param]: null }), {}),
      page: null,
    };
    updateQueryParams(params);
  };

  if (typeof queryParams === 'undefined') return null;
  const filterValues = parseFilterParams(queryParams);

  const filtersActive = VALID_FILTER_PARAMS.some(val => hasOwnProperty(queryParams, val));

  return (
    <div className="filter-bar">
      {isEvent && <DateFilter value={filterValues.date} onChange={handleDateChange} />}

      <DistanceFilter
        distanceUnits={distanceUnits}
        defaultValue={defaultDistance[objectType]}
        value={filterValues.distance}
        onChange={handleDistanceChange}
      />

      {isEvent && (
        <>
          <CategoryFilter value={filterValues.category} onChange={handleCategoryChange} />
          <PriceFilter value={filterValues.price} onChange={handlePriceChange} />
          <AgeGroupFilter value={filterValues.ageGroup} onChange={handleAgeGroupChange} />
          <OnlineFilter value={filterValues.online} onChange={handleOnlineChange} />
        </>
      )}

      {filtersActive && (
        <PillButton
          text="Reset"
          className="clear"
          attrs={{ 'data-action': 'filter-reset' }}
          onClick={handleResetClick}
        />
      )}

      <div style={{ flex: 1 }} />

      {isEvent && <SaveOrUpdateSearch />}
    </div>
  );
};

FilterBar.propTypes = {
  objectType: PropTypes.oneOf(['event', 'poi']),
  distanceUnits: PropTypes.oneOf([DISTANCE_UNITS_MI, DISTANCE_UNITS_KM]),
};

export default FilterBar;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  areIntervalsOverlapping,
  isValid,
  parse as parseDate,
} from 'date-fns';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import PillButton from 'components/PillButton';
import { TIME_FORMAT } from '../TimeField';
import OpenTimesRow, { rowDefaults } from './OpenTimesRow';

const OpenTimesField = ({ name, formMethods }) => {
  formMethods = formMethods || useFormContext();
  const { control, trigger, formState: { errors } } = formMethods;

  const validate = rows => {
    let error;
    const now = new Date();
    const normalizedRows = rows.reduce((result, row) => {
      const start = parseDate(row.startTime, TIME_FORMAT, now);
      const end = parseDate(row.endTime, TIME_FORMAT, now);
      if (row.days.length > 0 && isValid(start) && isValid(end)) {
        result.push({ ...row, start, end });
      }
      return result;
    }, []);

    normalizedRows.find((row, idx) => {
      const otherRows = normalizedRows.filter((r, i) => i !== idx);
      const conflictRow = otherRows.find(({ start, end, days }) => {
        const intervalsOverlap = areIntervalsOverlapping({ start, end }, { start: row.start, end: row.end });
        const daysOverlap = days.some(d => row.days.includes(d));
        return intervalsOverlap && daysOverlap;
      });

      if (conflictRow) {
        error = 'Schedule contains overlapping intervals.';
        return true;
      }
      return false;
    });

    return error || true;
  };

  const { fields: rowFields, append, remove } = useFieldArray({ control, name, rules: { validate } });

  const addRow = () => append({ ...rowDefaults }, { shouldFocus: false });

  const rootErr = errors[name] && errors[name].root && errors[name].root.message;

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <>
          {!!rootErr && (
            <div className="z-form-hint-container my-2" style={{ padding: 0 }}>
              <div className="z-form-hint-text text-danger">{rootErr}</div>
            </div>
          )}
          {rowFields.map((field, idx) => (
            <Fragment key={field.id}>
              <OpenTimesRow
                prefix={`${name}.${idx}`}
                formMethods={formMethods}
                onChange={() => trigger(name)}
                onDelete={rowFields.length > 1 ? () => remove(idx) : undefined}
              />
              {idx + 1 < rowFields.length && <div className="row mb-3"><div className="col"><hr className="m-0" /></div></div>}
            </Fragment>
          ))}
          <div className="d-flex">
            <PillButton text="Add Times" onClick={addRow} prependIcon={['far', 'plus']} />
          </div>
        </>
      )}
    />
  );
};

OpenTimesField.propTypes = {
  name: PropTypes.string,
  formMethods: PropTypes.object,
};

export default OpenTimesField;

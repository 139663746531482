import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFilterContext } from 'context';
import RadioList from 'components/RadioList';
import DropdownButton from 'components/DropdownButton';

export const IN_PERSON = 'in_person';
export const ONLINE = 'online';

const OnlineFilter = ({ useMobileStyle = false, value, onChange }) => {
  const options = [
    [IN_PERSON, 'In Person'],
    [ONLINE, 'Online'],
    [null, 'In Person & Online'],
  ];
  const defaultValue = null;
  const activeOptionLabel = options.find(opt => opt[0] === value)[1];

  const { updateFilterContext } = useFilterContext();
  useEffect(() => {
    updateFilterContext({ venueTypeLabel: value && activeOptionLabel.toLowerCase(), venueType: value });
  }, [value, activeOptionLabel]);

  const optionList = (
    <RadioList
      listId="filter-online"
      className={useMobileStyle ? 'invert stretch' : ''}
      options={options}
      value={value}
      onChange={onChange}
    />
  );

  return useMobileStyle ? optionList : (
    <DropdownButton
      className="me-2"
      text={activeOptionLabel}
      isHighlighted={value !== defaultValue}
      closeOnChanged={value}
    >
      <div className="px-5 py-4" style={{ width: 300 }}>
        {optionList}
      </div>
    </DropdownButton>
  );
};

OnlineFilter.propTypes = {
  useMobileStyle: PropTypes.bool,
  value: PropTypes.oneOf([null, ONLINE, IN_PERSON]),
  onChange: PropTypes.func,
};

export default OnlineFilter;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import colors from 'themeColors';

const CurrentLocIcon = forwardRef(({
  style = {},
  active = false,
  size = 20,
  onClick,
}, ref) => {
  const strokeColor = '#ffffff';
  const fillColor = colors.primary;

  const radius = size / 2;
  const scale = active ? 1.25 : 1;

  const iconStyle = {
    cursor: onClick ? 'pointer' : 'auto',
    transform: `scale(${scale})`,
    ...style,
  };


  return (
    <svg
      ref={ref}
      preserveAspectRatio="xMidYMid meet"
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      height={size}
      style={iconStyle}
      onClick={onClick || (() => {})}
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius}
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={3}
      />
    </svg>
  );
});

CurrentLocIcon.displayName = 'CurrentLocIcon';

CurrentLocIcon.propTypes = {
  style: PropTypes.object,
  active: PropTypes.bool,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default CurrentLocIcon;

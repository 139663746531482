import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  isBefore,
  isValid,
  parse as parseDate,
} from 'date-fns';
import {
  DATE_FORMAT,
  DateField,
  SelectField,
  NumberField,
} from 'components/formFields';

export const END_NONE = 'none';
export const END_DATE = 'date';
export const END_COUNT = 'count';

const EndOption = ({ formMethods, prefix, startDate }) => {
  const { watch } = formMethods;
  const val = watch(`${prefix}.endType`);

  const validateDate = useCallback(value => {
    const dateVal = parseDate(value, DATE_FORMAT, new Date());
    if (isValid(dateVal) && isBefore(dateVal, startDate)) {
      return 'End date must be after start date.';
    }
    return true;
  }, [startDate]);

  return (
    <>
      <div className="col-3">
        <SelectField
          name={`${prefix}.endType`}
          label="Ending"
          choices={[
            [END_NONE, 'Never'],
            [END_DATE, 'On'],
            [END_COUNT, 'After'],
          ]}
          required
          formMethods={formMethods}
        />
      </div>

      {val === END_DATE && (
        <div className="col-3">
          <DateField
            name={`${prefix}.endDate`}
            label="End Date"
            required
            validate={validateDate}
            formMethods={formMethods}
          />
        </div>
      )}

      {val === END_COUNT && (
        <div className="col-3">
          <NumberField
            name={`${prefix}.endCount`}
            label="Occurrences"
            required
            min={1}
            formMethods={formMethods}
          />
        </div>
      )}
    </>
  );
};

EndOption.propTypes = {
  formMethods: PropTypes.object,
  prefix: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
};

export default EndOption;

// Date format config
// patterns are described here: https://date-fns.org/v2.23.0/docs/format
export const QUERY_PARAM_FMT = 'yyyy-MM-dd'; // for API query parameters
export const DAY_LABEL_FMT = 'M/d'; // for "day" labels on chart
export const MONTH_LABEL_FMT = 'MMM yyyy'; // for "month" labels on chart
export const TOOLTIP_FMT = 'MMM d, yyyy'; // for tooltips on chart
export const DISPLAY_FMT = 'MMMM d, yyyy'; // for date range display text

export const DATE_RANGE_CHOICES = {
  lastSeven: 'last-seven',
  lastThirty: 'last-thirty',
  lastNinety: 'last-ninety',
  lastYear: 'last-year',
  allTime: 'all-time',
  customRange: 'custom-range',
};

export const CHART_METRIC_CHOICES = {
  widgetImpressions: 'widget_hit_ct',
  eventImpressions: 'event_hit_ct',
};

export const CHART_UNIT_CHOICES = {
  day: 'by_day',
  month: 'by_month',
};

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { endOfMonth, format, parseISO, startOfMonth, subDays, subMonths } from 'date-fns';
import { useHistory } from 'hooks';
import DateRangePicker from 'components/DateRangePicker';

const DateSelector = ({ onChange }) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();

  const optionsData = useMemo(() => {
    const now = new Date();
    const nowMinus1 = subDays(now, 1);
    const nowMinus6 = subDays(now, 6);
    const nowMinus29 = subDays(now, 29);
    const thisMonthStart = startOfMonth(now);
    const lastMonthStart = subMonths(thisMonthStart, 1);
    const lastMonthEnd = endOfMonth(lastMonthStart);
    // const fmt = 'MMM d';

    return {
      today: { label: 'Today', dateRange: [now, now] },
      yesterday: { label: 'Yesterday', dateRange: [nowMinus1, nowMinus1] },
      'last-7': { label: 'Last 7 Days', dateRange: [nowMinus6, now] },
      'last-30': { label: 'Last 30 Days', dateRange: [nowMinus29, now] },
      'current-month': { label: 'This Month', dateRange: [thisMonthStart, now] },
      'last-month': { label: 'Last Month', dateRange: [lastMonthStart, lastMonthEnd] },
    };
  }, []);

  const defaultOptionKey = 'last-7';

  let dateRange;
  if (typeof queryParams !== 'undefined') {
    const { date_range, date_start, date_end } = queryParams;
    if (date_start && date_end) {
      dateRange = [
        parseISO(date_start),
        parseISO(date_end),
      ];
    } else {
      dateRange = optionsData[date_range || defaultOptionKey].dateRange;
    }
  }

  useEffect(() => {
    if (dateRange) {
      onChange(dateRange);
    }
  }, [dateRange && JSON.stringify(dateRange.map(d => format(d, 'yyyy-MM-dd')))]);

  const handleDateChange = ([start, end, slug]) => {
    const params = optionsData[slug] ? { date_range: slug } : {
      date_start: start && format(start, 'yyyy-MM-dd'),
      date_end: end && format(end, 'yyyy-MM-dd'),
    };
    updateQueryParams(params, { clear: true });
  };

  return !!dateRange && (
    <DateRangePicker
      menuAnchor="right"
      buttonClassName="subtle"
      limitDates="past"
      presetOptions={optionsData}
      defaultOption={defaultOptionKey}
      value={dateRange}
      onChange={handleDateChange}
    />
  );
};

DateSelector.propTypes = {
  onChange: PropTypes.func,
};

export default DateSelector;

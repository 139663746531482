import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'hooks';
import DropdownButton from 'components/DropdownButton';
import Icon from 'components/Icon';
import Modal from 'components/Modal';

const CardDeletionMenu = ({ deleteLinkAttrs = {}, extraItems = [], onDeleteClick = () => {} }) => {
  const actionMenuRef = useRef();

  const [modalIsOpen, toggleModal] = useToggle(false);
  const handleRequestClose = () => toggleModal(false);

  const handleDeleteClick = evt => {
    evt.preventDefault();
    actionMenuRef.current.close();
    toggleModal(true);
  };

  const handleDeleteConfirmClick = evt => {
    evt.preventDefault();
    onDeleteClick();
    toggleModal(false);
  }

  const extraItemsRendered = extraItems.map(({ label, attrs = {}, onClick }) => {
    const handleClick = evt => {
      evt.preventDefault();
      actionMenuRef.current.close();
      onClick(evt);
    };
    return (
      <a
        key={label}
        href={`#${label}`}
        className="link-list-item px-3"
        style={{ whiteSpace: 'nowrap' }}
        onClick={onClick ? handleClick : undefined}
        {...attrs}
      >
        <span>{label}</span>
      </a>
    );
  });

  return (
    <>
      <DropdownButton
        ref={actionMenuRef}
        buttonClassName="clear subtle-focus borderless circle"
        menuAnchor="right"
        prependIcon={<Icon i={['far', 'ellipsis-v']} />}
        appendIcon={null}
      >
        <div className="link-list py-3">
          {extraItemsRendered}
          <a
            href="#delete"
            className="link-list-item px-3"
            onClick={handleDeleteClick}
            {...deleteLinkAttrs}
          >
            <span className="text-danger">Delete</span>
          </a>
        </div>
      </DropdownButton>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleRequestClose}
        contentClassName="p-3 p-sm-6"
        ariaHideApp={false}
      >
        <h4 className="mb-3">Are you sure?</h4>
        <p>This object will be deleted permanently.</p>
        <div className="d-flex align-items-center justify-content-end">
          <a href="#cancel" className="small reset-link text-tertiary me-3" onClick={handleRequestClose}>Cancel</a>
          <button type="button" onClick={handleDeleteConfirmClick} className="btn-z danger">Delete</button>
        </div>
      </Modal>
    </>
  );
};

CardDeletionMenu.propTypes = {
  deleteLinkAttrs: PropTypes.object,
  extraItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  })),
  onDeleteClick: PropTypes.func,
};

export default CardDeletionMenu;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'hooks';
import colors from 'themeColors';
import ReactMapGL, { FlyToInterpolator, NavigationControl } from 'react-map-gl';
import MapMarker from 'components/MapMarker';
import PillButton from 'components/PillButton';

const SimplePointMap = ({
  containerStyle = { width: '100%', height: '100%' },
  mapboxToken,
  mapStyle = 'mapbox://styles/mapbox/light-v10',
  latitude: markerLat,
  longitude: markerLon,
  zoom: defaultZoom = 1,
}) => {
  let { width, height, ref } = useResizeDetector();
  width = width && Math.round(width);
  height = height && Math.round(height);

  const hasMarker = !!(markerLat && markerLon);

  const [transitionDuration, setTransitionDuration] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const handleTransitionStart = () => setIsTransitioning(true);
  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    setTransitionDuration(0);
  };

  const defaultViewport = hasMarker ? {
    latitude: markerLat,
    longitude: markerLon,
    zoom: defaultZoom,
  } : {
    latitude: 0,
    longitude: 0,
    zoom: 1,
  };

  const [viewport, setViewport] = useState(defaultViewport);
  const { latitude, longitude, zoom } = viewport;

  const handleViewportChange = (viewport, transitionDuration = 0) => {
    setTransitionDuration(transitionDuration);
    setViewport(viewport);
  };

  const resetViewport = () => {
    setTransitionDuration(500);
    setViewport({ ...defaultViewport });
  };

  useEffect(() => {
    resetViewport();
  }, [markerLat, markerLon, defaultZoom]);

  const isPanned = (
    hasMarker && !isTransitioning &&
    viewport.latitude.toFixed(3) !== markerLat.toFixed(3) &&
    viewport.longitude.toFixed(3) !== markerLon.toFixed(3)
  );
  const handleCenterClick = () => resetViewport();

  const mapRef = useRef();

  return (
    <div ref={ref} style={containerStyle}>
      <ReactMapGL
        ref={mapRef}
        mapboxApiAccessToken={mapboxToken}
        mapStyle={mapStyle}
        width={width}
        height={height}
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        maxZoom={18}
        transitionDuration={transitionDuration}
        transitionInterpolator={mapRef.flyTo}
        onViewportChange={viewport => handleViewportChange(viewport)}
        // onLoad={() => setLoaded(true)}
        onTransitionStart={handleTransitionStart}
        onTransitionEnd={handleTransitionEnd}
      >
        <NavigationControl
          style={{ position: 'absolute', top: 20, right: 20 }}
          showCompass={false}
        />

        {hasMarker && (
          <MapMarker
            iconProps={{
              strokeColor: '#fff',
              dotColor: '#fff',
              fillColor: colors.primary,
            }}
            latitude={markerLat}
            longitude={markerLon}
          />
        )}

        {isPanned && (
          <div style={{ position: 'absolute', top: 20, left: 20 }}>
            <PillButton text="Re-Center" className="sm secondary" onClick={handleCenterClick} />
          </div>
        )}
      </ReactMapGL>
    </div>
  );
};

SimplePointMap.propTypes = {
  containerStyle: PropTypes.object,
  mapboxToken: PropTypes.string,
  mapStyle: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
};

export default SimplePointMap;

import { format as formatDate, parse as parseDate } from 'date-fns';
import { parseTimeFieldValue, formatTimeFieldValue } from '../TimeField';

const WEEKDAY_MAP = {
  // fieldValue: apiValue
  0: 6,
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
};

export const hydrateValue = values => {
  // Format value from API for form
  const result = [];

  values.filter(v => !v.closed).forEach(v => {
    const startTime = formatTimeFieldValue(parseDate(v.time_start, 'HH:mm:ss', new Date()));
    const endTime = formatTimeFieldValue(parseDate(v.time_end, 'HH:mm:ss', new Date()));
    let fieldDay = Object.entries(WEEKDAY_MAP).find(([key, val]) => val === v.day_of_week)[0];
    fieldDay = parseInt(fieldDay, 10);

    const match = result.find(rec => rec.startTime === startTime && rec.endTime === endTime);
    if (match) {
      if (!match.days.includes(fieldDay)) {
        match.days.push(fieldDay);
      }
    } else {
      result.push({ days: [fieldDay], startTime, endTime });
    }
  });

  return result;
};

export const dehydrateValue = values => {
  // Format value from form for API
  const result = [];

  Object.entries(WEEKDAY_MAP).forEach(([fieldDay, apiDay]) => {
    fieldDay = parseInt(fieldDay, 10);
    const valsForDay = values.filter(v => v.days.includes(fieldDay));
    if (valsForDay.length > 0) {
      valsForDay.forEach(v => {
        result.push({
          day_of_week: apiDay,
          closed: false,
          time_start: formatDate(parseTimeFieldValue(v.startTime), 'HH:mm'),
          time_end: formatDate(parseTimeFieldValue(v.endTime), 'HH:mm'),
        });
      });
    } else {
      result.push({
        day_of_week: apiDay,
        closed: true,
      });
    }
  });

  return result;
};

import React from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import DropdownButton from 'components/DropdownButton';
import EventCard from 'components/EventCard';
import Icon from 'components/Icon';

const EditorEventCard = ({ eventData }) => {
  const renderActionButtons = () => (
    <DropdownButton
      buttonClassName="clear subtle-focus borderless circle"
      menuAnchor="right"
      prependIcon={<Icon i={['far', 'ellipsis-v']} />}
      appendIcon={null}
    >
      <div className="link-list py-3">
        <a
          href={eventData.absoluteUrl}
          className="link-list-item px-3"
        >
          View
        </a>
        <a
          href={`${urls.editEventBase}${eventData.id}/`}
          className="link-list-item px-3"
        >
          Edit
        </a>
        <a
          href={`${urls.deleteEventBase}${eventData.id}/`}
          className="link-list-item px-3"
        >
          <span className="text-danger">Delete</span>
        </a>
      </div>
    </DropdownButton>
  );

  return (
    <EventCard
      {...eventData}
      smallIcon
      hideFooter
      renderActionButtons={renderActionButtons}
    />
  );
};

EditorEventCard.propTypes = {
  eventData: PropTypes.shape(EventCard.propTypes),
};

export default EditorEventCard;

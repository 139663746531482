import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { format as formatDate, parse as parseDate } from 'date-fns';
import { Calendar } from 'react-date-range';
import colors from 'themeColors';
import { useToggle, useClickOutside } from 'hooks';
import ClearableTag from 'components/ClearableTag';
import PillButton from 'components/PillButton';
import { DATE_FORMAT } from 'components/formFields';


const Exceptions = ({ value = [], onChange }) => {
  const containerRef = useRef();
  const [calendarVisible, toggleCalendarVisible] = useToggle(false);

  const handleAddClick = () => toggleCalendarVisible(true);

  const handleItemAdd = date => {
    const dateString = formatDate(date, DATE_FORMAT);
    if (!value.includes(dateString)) {
      onChange(sortDateStrings([...value, dateString]));
    }
  };

  const handleCalendarChange = date => {
    handleItemAdd(date);
    setTimeout(toggleCalendarVisible, 300);
  };

  const handleClickOutside = evt => {
    if (calendarVisible) toggleCalendarVisible();
  };
  useClickOutside(containerRef, handleClickOutside);

  const sortDateStrings = arr => {
    const now = new Date();
    return arr.toSorted((a, b) => parseDate(a, DATE_FORMAT, now) - parseDate(b, DATE_FORMAT, now));
  };

  const handleItemRemove = dateString => {
    onChange(value.filter(v => v !== dateString));
  };

  return (
    <div ref={containerRef} className="row mt-n2 mb-3">
      <div className="d-flex align-items-center mx-n1">
        {value.length > 0 && (
          <>
            <div className="mx-1">Except on:</div>
            {value.map(d => <ClearableTag className="mx-1" key={d} label={d} onRemove={() => handleItemRemove(d)} />)}
          </>
        )}
        <div className="small mx-1 position-relative">
          <PillButton
            className="sm outline"
            text="Add Exception"
            onClick={handleAddClick}
            prependIcon={['far', 'plus']}
          />
          <div
            className="z-form-calendar-wrap"
            style={{ display: calendarVisible ? 'block' : 'none' }}
          >
            <Calendar
              color={colors.primary}
              onChange={handleCalendarChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Exceptions.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default Exceptions;

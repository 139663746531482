import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'date-fns';
import { urls, defaultLocations } from 'app-constants';
import { getCsrfToken } from 'utils';
import { useToggle, useAuthRequired } from 'hooks';
import { useUserContext, useFilterContext } from 'context';
import DropdownButton from 'components/DropdownButton';
import LoadingBubbles from 'components/LoadingBubbles';
import Modal from 'components/Modal';
import PillButton from 'components/PillButton';
import RadioList from 'components/RadioList';

const MODE_SAVE = 'save';
const MODE_UPDATE = 'update';

const SaveOrUpdateSearch = ({ buttonClassName }) => {
  const { savedSearches, fetchUserContext } = useUserContext();

  const staticLocIds = useMemo(() => [...defaultLocations.map(({ id }) => id), 'current'], []);

  const [modalVisible, toggleModalVisible] = useToggle(false);
  const [mode, setMode] = useState(MODE_SAVE);
  const handleRequestClose = () => toggleModalVisible(false);

  const handleModeLinkClick = evt => {
    evt.preventDefault();
    setMode(mode === MODE_UPDATE ? MODE_SAVE : MODE_UPDATE);
  };

  const [nameVal, setNameVal] = useState('');
  const handleNameInputChange = evt => setNameVal(evt.target.value);

  const [selectedSearchId, setSelectedSearchId] = useState(null);
  const savedSearchOptions = (savedSearches || []).map(({ id, searchName }) => [id, searchName]);
  const activeOptionLabel = savedSearches && (savedSearches.find(({ id }) => id === selectedSearchId) || {}).searchName;

  useEffect(() => {
    setNameVal('');
    setSelectedSearchId(null);
    setErrorMessage(null);
    setIsFetching(false);
    setMode(MODE_SAVE);
  }, [modalVisible]);

  useEffect(() => {
    if (mode === MODE_UPDATE) {
      setSelectedSearchId(savedSearches[0].id);
    }
  }, [mode]);

  const {
    ageGroups,
    prices,
    categoryIds,
    dateSlug,
    dateRange,
    locationId,
    radius,
    venueType,
    keyword,
  } = useFilterContext();

  const canSubmit = mode === MODE_UPDATE ? !!selectedSearchId : !!nameVal;

  const [errorMessage, setErrorMessage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const handleFormSubmit = evt => {
    evt.preventDefault();
    if (isFetching || !canSubmit) return null;

    setErrorMessage(null);
    setIsFetching(true);

    const isUpdate = mode === MODE_UPDATE;
    let url = urls.savedSearchBase;
    if (isUpdate) {
      url += `${selectedSearchId}/`;
    }

    const [start, end] = dateRange || [];

    const payload = {
      radius,
      pretty_date: null,
      named_date: dateSlug === 'custom' ? '' : dateSlug.replaceAll('-', '_'),
      date_start: start && format(start, 'yyyy-MM-dd'),
      date_end: end && format(end, 'yyyy-MM-dd'),
      keyword: keyword || '',
      event_setting: venueType || '',
      age_group: ageGroups || [],
      price: prices || [],
      event_types: categoryIds || [],
      is_active: true,
    };
    if (staticLocIds.includes(locationId)) {
      payload.default_loc_id = locationId;
      payload.address_id = null;
    } else {
      payload.address_id = locationId;
      payload.default_loc_id = '';
    }

    if (!isUpdate) {
      payload.search_name = nameVal;
    }

    fetch(url, {
      credentials: 'include',
      method: isUpdate ? 'PATCH' : 'POST',
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        setIsFetching(false);
        toggleModalVisible(false);
        // Refresh user data to include the newly-saved search
        fetchUserContext();
      })
      .catch(err => {
        console.error(err);
        setIsFetching(false);
        setErrorMessage('Failed to save search. Please try your request again.');
      });
  };

  const handleButtonClick = useAuthRequired(toggleModalVisible);

  const handleCancelClick = evt => {
    evt.preventDefault();
    toggleModalVisible(false);
  };

  const modeTexts = {
    [MODE_SAVE]: {
      heading: 'Save This Search',
      modeLink: 'update an existing search',
      submitButton: 'Save',
    },
    [MODE_UPDATE]: {
      heading: 'Update an Existing Search',
      modeLink: 'save a new search',
      submitButton: 'Update',
    },
  };
  const texts = modeTexts[mode];

  const buttonAttrs = {
    'data-search-name': mode === MODE_UPDATE ? `${selectedSearchId} - ${activeOptionLabel}` : nameVal,
    'data-action': mode,
  };

  return (
    <>
      <PillButton
        className={buttonClassName}
        text="Save Search"
        prependIcon="star"
        onClick={handleButtonClick}
      />
      <Modal isOpen={modalVisible} onRequestClose={handleRequestClose}>
        <div className="p-3 p-sm-6">
          <div className="text-center mb-4">
            <h6 className="fw-black mb-2">{texts.heading}</h6>
            {savedSearches && savedSearches.length > 0 && (
              <div className="text-tertiary small">
                Or <a href="#" className="text-decoration-none" onClick={handleModeLinkClick}>{texts.modeLink}</a>
              </div>
            )}
          </div>
          {!!errorMessage && (
            <div className="alert alert-danger mb-3" role="alert">
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              {mode === MODE_SAVE && (
                <input
                  autoFocus
                  type="text"
                  maxLength={65}
                  className="z-input d-block w-100"
                  placeholder="Name your saved search"
                  value={nameVal}
                  onChange={handleNameInputChange}
                />
              )}

              {mode === MODE_UPDATE && (
                <DropdownButton
                  className="w-100"
                  buttonClassName="w-100"
                  text={activeOptionLabel}
                  closeOnChanged={selectedSearchId}
                >
                  <div className="px-5 py-4" style={{ width: 300, overflow: 'auto', maxHeight: 'calc(50vh - 40px)' }}>
                    <RadioList
                      options={savedSearchOptions}
                      value={selectedSearchId}
                      onChange={setSelectedSearchId}
                    />
                  </div>
                </DropdownButton>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <a href="#cancel" className="small reset-link text-tertiary me-3" onClick={handleCancelClick}>Cancel</a>
              <button
                type="submit"
                className={classNames('btn-z', 'primary', !canSubmit && 'disabled')}
                {...buttonAttrs}
              >
                {isFetching ? <LoadingBubbles /> : texts.submitButton}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

SaveOrUpdateSearch.propTypes = {
  buttonClassName: PropTypes.string,
};

export default SaveOrUpdateSearch;

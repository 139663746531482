import { useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { trackEvent } from 'utils';

export default function useTrackImpression (eventName = 'impression', extraParams = {}, delay = 1) {
  const [hasFired, setHasFired] = useState(false);
  const delayTimeout = useRef();
  const handleInView = () => {
    trackEvent(eventName, extraParams);
    setHasFired(true);
  };

  const { ref } = useInView({
    threshold: 0.5,
    skip: hasFired,
    onChange: inView => {
      if (inView) {
        delayTimeout.current = setTimeout(handleInView, delay * 1000);
      } else {
        clearTimeout(delayTimeout.current);
      }
    },
  });

  return ref;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDistanceToNow, parseISO, parse as parseDate } from 'date-fns';
import { urls } from 'app-constants';
import { useToggle } from 'hooks';
import { getCsrfToken } from 'utils';
import CalendarEmbedCode from 'components/CalendarEmbedCode';
import CardDeletionMenu from 'components/CardDeletionMenu';
import SavedSearchTextExport from 'components/SavedSearchTextExport';
import Icon from 'components/Icon';

const CalendarCard = ({
  id,
  title,
  created,
  updated,
  embedUrl,
  search,
  className,
  useCompactStyle,
  metaLabel = 'updated',
  onEditClick = () => {},
  onDeleteSuccess = () => {},
  onDeleteFailure = () => {},
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [embedModalOpen, toggleEmbedModal] = useToggle(false);
  const [exportModalOpen, toggleExportModal] = useToggle(false);

  const dateCreated = parseISO(created);
  const dateUpdated = parseISO(updated);

  const handleCardClick = () => window.open(embedUrl, '_blank', 'width=600,height=800,top=0,left=1500,resizable');

  const handleDeleteClick = () => {
    setIsDeleting(true);

    fetch(`${urls.apiWidgetList}${id}/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'X-CSRFToken': getCsrfToken() },
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        onDeleteSuccess();
      })
      .catch(err => {
        console.error(err);
        setIsDeleting(false);
        onDeleteFailure(err);
      });
  };

  return (
    <>
      <div
        className={classNames('listings-card', 'saved-search-card', className)}
        style={{ opacity: isDeleting ? 0.5 : 1 }}
        onClick={handleCardClick}
      >
        <section className="lc-top">
          <div className={classNames('lc-main p-0')}>
            <div>
              <h5 className={classNames('mb-2', 'saved-search-card-title')}>
                <a className="color-primary">{title}</a>
              </h5>

              {!!search && (
                <div className="mb-2 lh-sm d-flex align-items-center">
                  <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                    <Icon i="search" size="sm" />
                  </div>
                  <div>
                    <strong>{search.searchName}</strong> <span className="text-sm text-tertiary">– {search.description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="lc-right">
            <div onClick={e => e.stopPropagation()}>
              <CardDeletionMenu
                onDeleteClick={handleDeleteClick}
                extraItems={[
                  { label: 'Edit', onClick: onEditClick },
                  { label: 'Embed', onClick: toggleEmbedModal },
                  {
                    label: 'Export as Text',
                    onClick: () => toggleExportModal(),
                    attrs: {
                      'data-action': 'text-export',
                      'data-object-type': 'calendar',
                      'data-object-id': id,
                    },
                  },
                ]}
                deleteLinkAttrs={{
                  'data-calendar-name': `${id} - ${title}`,
                  'data-action': 'delete',
                }}
              />
            </div>
          </div>
        </section>

        {!useCompactStyle && (
          <div className="saved-search-card-meta">
            {metaLabel === 'created' && `Created ${formatDistanceToNow(dateCreated)} ago`}
            {metaLabel === 'updated' && `Edited  ${formatDistanceToNow(dateUpdated)} ago`}
          </div>
        )}
      </div>
      <CalendarEmbedCode
        embedUrl={embedUrl}
        isOpen={embedModalOpen}
        onRequestClose={toggleEmbedModal}
      />
      {!!search && (
        <SavedSearchTextExport
          searchId={search.id}
          calendarId={id}
          utmSource="calendar-export"
          utmCampaign={id}
          isOpen={exportModalOpen}
          onRequestClose={toggleExportModal}
        />
      )}
    </>
  );
};

CalendarCard.propTypes = {
  className: PropTypes.string,
  useCompactStyle: PropTypes.bool,
  id: PropTypes.number,
  title: PropTypes.string,
  embedUrl: PropTypes.string,
  search: PropTypes.object,
  created: PropTypes.string,
  updated: PropTypes.string,
  metaLabel: PropTypes.oneOf(['updated', 'created']),
  onEditClick: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default CalendarCard;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format, formatISO, isToday } from 'date-fns';
import { useHistory, useToggle, useDisableScroll } from 'hooks';
import { hasOwnProperty } from 'utils';
import Icon from 'components/Icon';
import OrderingMenu from 'components/OrderingMenu';
import PillButton from 'components/PillButton';
import SaveOrUpdateSearch from 'components/SaveOrUpdateSearch';
import {
  AgeGroupFilter,
  PriceFilter,
  CategoryFilter,
  DateFilter,
  DistanceFilter,
  IN_PERSON,
  ONLINE,
  OnlineFilter,
  parseFilterParams,
  DISTANCE_UNITS_MI,
  DISTANCE_UNITS_KM,
  VALID_FILTER_PARAMS,
} from 'components/filters';

const FilterBarMobile = ({ objectType = 'event', distanceUnits = DISTANCE_UNITS_MI }) => {
  const { location: { queryParams }, updateQueryParams } = useHistory();

  const isEvent = objectType === 'event';

  const [modalVisible, toggleModalVisible] = useToggle(false);
  useDisableScroll(modalVisible); // disable scrolling on body when modal is open

  const paramsFromQuery = queryParams && VALID_FILTER_PARAMS.reduce((result, param) => ({ ...result, [param]: queryParams[param] }), {});
  const [filterParams, setFilterParams] = useState(paramsFromQuery || {});
  const updateFilterParams = attrs => setFilterParams(prevState => ({ ...prevState, ...attrs }));

  useEffect(() => setFilterParams(paramsFromQuery), [JSON.stringify(paramsFromQuery)]);

  const handleFilterClick = () => toggleModalVisible();

  const handleDateChange = ([start, end]) => updateFilterParams({
    date_start: start && (isToday(start) ? formatISO(new Date()) : format(start, 'yyyy-MM-dd')),
    date_end: end && format(end, 'yyyy-MM-dd'),
    page: null,
  });
  const handleDistanceChange = val => updateFilterParams({ radius: val, page: null });
  const handleCategoryChange = val => updateFilterParams({ event_types: val, page: null });
  const handleAgeGroupChange = val => updateFilterParams({ age_groups: val, page: null });
  const handlePriceChange = val => updateQueryParams({ prices: val, page: null });
  const handleOnlineChange = val => updateFilterParams({
    is_online: val === ONLINE ? 't' : null,
    is_in_person: val === IN_PERSON ? 't' : null,
    page: null,
  });

  const handleApplyClick = () => {
    updateQueryParams(filterParams);
    toggleModalVisible(false);
  };

  const handleResetClick = () => {
    const params = {
      ...VALID_FILTER_PARAMS.reduce((result, param) => ({ ...result, [param]: null }), {}),
      page: null,
    };
    updateQueryParams(params);
    toggleModalVisible(false);
  };

  const handleCloseClick = () => {
    updateFilterParams(paramsFromQuery);
    toggleModalVisible(false);
  };

  if (typeof filterParams === 'undefined' || typeof queryParams === 'undefined') return null;
  const filterValues = parseFilterParams(filterParams);

  const filtersActive = !!queryParams && VALID_FILTER_PARAMS.some(val => hasOwnProperty(queryParams, val));

  let activeFilterCount = 0;
  const queryKeys = Object.keys(queryParams);
  ['radius', 'event_types', 'age_groups', 'prices'].forEach(p => {
    if (queryKeys.includes(p)) {
      activeFilterCount += 1;
    }
  });
  if (['date_start', 'date_end'].some(p => queryKeys.includes(p))) {
    activeFilterCount += 1;
  }
  if (['is_online', 'is_in_person'].some(p => queryKeys.includes(p))) {
    activeFilterCount += 1;
  }

  const buttonText = activeFilterCount > 0 && <span className="filter-count-badge m-0">{activeFilterCount}</span>;

  return (
    <>
      <div className="filter-bar">
        <PillButton
          text={buttonText}
          className={buttonText ? 'narrow' : 'circle'}
          prependIcon={['faz', 'filter']}
          onClick={handleFilterClick}
        />
        <OrderingMenu objectType={objectType} className="ms-2" />
        {filtersActive && (
          <PillButton
            text="Reset"
            className="clear"
            attrs={{ 'data-action': 'filter-reset' }}
            onClick={handleResetClick}
          />
        )}

        <div style={{ flex: 1 }} />

        {isEvent && <SaveOrUpdateSearch />}
      </div>
      <div className={classNames('filter-bar-mobile-container', modalVisible && 'active')}>
        <header>
          <h3 className="m-0 fw-black">Filters</h3>
          <div onClick={handleCloseClick}>
            <Icon i={['far', 'times']} size="lg" />
          </div>
          <div className="shadow-clipped" style={{ top: '100%' }} />
        </header>

        <section className="filter-bar-mobile-content">
          {isEvent && (
            <>
              <h6 className="text-secondary text-center mb-4">Date</h6>
              <div className="mb-6">
                <DateFilter useMobileStyle value={filterValues.date} onChange={handleDateChange} />
              </div>

              <h6 className="text-secondary text-center mb-4">Category</h6>
              <div className="mb-6">
                <CategoryFilter useMobileStyle value={filterValues.category} onChange={handleCategoryChange} />
              </div>
            </>
          )}

          <h6 className="text-secondary text-center mb-4">Distance</h6>
          <div className="mb-6">
            <DistanceFilter useMobileStyle distanceUnits={distanceUnits} value={filterValues.distance} onChange={handleDistanceChange} />
          </div>

          {isEvent && (
            <>
              <h6 className="text-secondary text-center mb-4">Type</h6>
              <div className="mb-6">
                <OnlineFilter useMobileStyle value={filterValues.online} onChange={handleOnlineChange} />
              </div>

              <h6 className="text-secondary text-center mb-4">Age Group</h6>
              <div className="mb-6">
                <AgeGroupFilter useMobileStyle value={filterValues.ageGroup} onChange={handleAgeGroupChange} />
              </div>

              <h6 className="text-secondary text-center mb-4">Price</h6>
              <div className="mb-6">
                <PriceFilter useMobileStyle value={filterValues.price} onChange={handlePriceChange} />
              </div>
            </>
          )}
        </section>

        <footer className="d-grid gap-2" style={{ paddingTop: 8 }}>
          <div className="shadow-clipped" style={{ top: -20, transform: 'rotate(180deg)' }} />
          <div className="d-flex align-items-center justify-content-between">
            <a
              href="#reset"
              className="reset-link text-secondary small"
              onClick={e => { e.preventDefault(); handleResetClick(); }}
            >
              Reset
            </a>
          </div>
          <button type="button" className="btn btn-primary btn-lg" onClick={handleApplyClick}>Apply</button>
        </footer>
      </div>
    </>
  );
};

FilterBarMobile.propTypes = {
  objectType: PropTypes.oneOf(['event', 'poi']),
  distanceUnits: PropTypes.oneOf([DISTANCE_UNITS_MI, DISTANCE_UNITS_KM]),
};

export default FilterBarMobile;

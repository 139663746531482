import React from 'react';
import ResponsiveLocatorMap from './ResponsiveLocatorMap';

/**
 * A helper component that provides custom aspectRatio values to ResponsiveLocatorMap.
 */
const DefaultLocatorMap = props => {
  const getAspectRatio = width => width < 400 ? 2 : 2.78;
  return <ResponsiveLocatorMap {...props} aspectRatio={getAspectRatio} />;
};

DefaultLocatorMap.propTypes = {
  ...ResponsiveLocatorMap.propTypes,
};

export default DefaultLocatorMap;

import React from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { format, addDays } from 'date-fns';
import { urls } from 'app-constants';
import ZeitcasterLogo from 'components/ZeitcasterLogo';

const PromoWrapper = ({
  bulletPoints = [
    'Unlock full access to 100’s of events!',
    'Discover new events curated by Berkshire locals, updated daily!',
    'Plan your best day out using personalized search tools.',
    'Search results customized to your current location.',
  ],
  footerContent,
  onLoginClick,
  children,
}) => {
  const tomorrowDateString = format(addDays(new Date(), 1), 'yyyy-MM-dd');
  const todaySearchUrl = urls.events;
  const tomorrowSearchUrl = urlJoin(urls.events, `?date_start=${tomorrowDateString}&date_end=${tomorrowDateString}`);

  const handleLoginClick = evt => {
    evt.preventDefault();
    onLoginClick();
  };

  return (
    <div className="modal-register-cta">
      <div className="logo-container">
        <ZeitcasterLogo height={40} />
      </div>
      <div className="columns">
        <div>
          <div className="fw-bold mb-4">
            Please create a free account or <a className="td-underline-hover" href="#login" onClick={handleLoginClick}>log in</a> to access more of what Zeitcaster has to offer!
          </div>

          <div className="mb-4">
            {bulletPoints.map((text, idx) => (
              <div key={idx} className="bullet">
                <i>•</i>
                <div>{text}</div>
              </div>
            ))}
          </div>

          {footerContent || (
            <div>
              <span>Even if you don't sign up, you will always be able to view </span>
              <a href={todaySearchUrl} className="td-underline-hover">today</a>
              <span> and </span>
              <a href={tomorrowSearchUrl} className="td-underline-hover">tomorrow</a>
              <span> for free </span>
            </div>
          )}
        </div>
        <div className="divider" />
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

PromoWrapper.propTypes = {
  bulletPoints: PropTypes.arrayOf(PropTypes.string),
  footerContent: PropTypes.node,
  onLoginClick: PropTypes.func,
  children: PropTypes.node,
};

export default PromoWrapper;
